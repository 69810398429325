import styled, { css } from 'styled-components';
import { CSSTransitionAnimation } from 'powder-ui';
import animations from './animations';

type TargetProps = {
  transition: CSSTransitionAnimation;
  alignment?: 'center' | 'left';
};

// Defined a styled component implmementation
export const Target = styled.div<TargetProps>`
  display: flex;
  box-sizing: border-box;
  padding: 20px;
  height: 100vh;
  margin: ${({ alignment }) => (alignment === 'center' ? '0px auto' : '0px')};
  min-width: 360px;
  min-height: 500px;
  width: 100%;
  overflow-y: scroll;
  justify-content: ${({ alignment }) => (alignment === 'center' ? 'center' : 'flex-start')};
  opacity: 0;
  box-shadow: 0px;
  transition: background-color 0.2s ease-in-out;
  background-color: ${({ theme }) => theme.palette.background};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: auto;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 80px 20px;
  }

  ${({ transition }) => css`
    animation: ${transition.duration}ms ease-in-out ${transition.delay + 200}ms ${animations.bubble}
      forwards;
  `}
`;

import { TableDataColumn } from 'powder-ui';

export const columns: TableDataColumn[] = [
  {
    key: 'period',
    header: 'Period',
    sortable: false,
    width: 12,
    align: 'center'
  },
  {
    key: 'balance',
    header: 'Balance',
    width: 22,
    align: 'right'
  },
  {
    key: 'interest',
    header: 'Interest',
    width: 22,
    align: 'right'
  },
  {
    key: 'principal',
    header: 'Principal',
    width: 22,
    align: 'right'
  },
  {
    key: 'payment',
    header: 'Payment',
    width: 22,
    align: 'right'
  }
];

import React from 'react';
import { Skeleton, Table, Typography } from 'powder-ui';
import type { TableDataColumn } from 'powder-ui';
import { Theme, useTheme } from 'styled-components';
import { Projection } from '~/src/hooks/useStacksAPI';
import { columns as tableColumns } from './bin/config';
import { Numeric } from '../Numeric';

interface Props {
  projection: Projection;
  skeleton?: boolean;
}

function AmortizationTable({ projection, skeleton }: Props): JSX.Element {
  const theme: Theme = useTheme();
  if (!projection.analysis?.loan?.amortization?.schedule) {
    return <span />;
  }

  if (skeleton) return <Skeleton type="box" fluid height={500} />;

  const { schedule } = projection.analysis.loan.amortization;

  return (
    <Table.Table
      rows={schedule.map((p) => ({ ...p, id: p.period })) || []}
      columns={tableColumns}
      spacing={2}
    >
      {({ rows, columns, sortColumn, sortDirection, onSortColumn }) => (
        <>
          <Table.Header topBorder bottomBorder borderColor={theme.palette.neutral[3]}>
            <Table.Row>
              {columns.map((column: TableDataColumn) => (
                <Table.HeaderCell
                  key={column.key}
                  column={column}
                  align={column.align}
                  sortColumn={sortColumn !== null ? sortColumn : ''}
                  sortDirection={sortDirection}
                  onClick={() => onSortColumn(column.key)}
                />
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows.map((row) => (
              <Table.Row key={row.id}>
                <Table.BodyCell align="center">
                  <Typography.Mono size="12px" lineHeight="18px" condensed>
                    <Numeric number={row.period} precision={0} />
                  </Typography.Mono>
                </Table.BodyCell>
                <Table.BodyCell align="right">
                  <Typography.Mono size="12px" lineHeight="18px" condensed>
                    <Numeric number={row.balance} magnitude="M" currency />
                  </Typography.Mono>
                </Table.BodyCell>
                <Table.BodyCell align="right">
                  <Typography.Mono size="12px" lineHeight="18px" condensed>
                    <Numeric number={row.interest} currency />
                  </Typography.Mono>
                </Table.BodyCell>
                <Table.BodyCell align="right">
                  <Typography.Mono size="12px" lineHeight="18px" condensed>
                    <Numeric number={row.principal} currency />
                  </Typography.Mono>
                </Table.BodyCell>
                <Table.BodyCell align="right">
                  <Typography.Mono size="12px" lineHeight="18px" condensed>
                    <Numeric number={row.payment} currency />
                  </Typography.Mono>
                </Table.BodyCell>
              </Table.Row>
            ))}
          </Table.Body>
        </>
      )}
    </Table.Table>
  );
}

export default AmortizationTable;

import React from 'react';
import {
  Block,
  Flex,
  TextInput,
  Typography,
  Button,
  Divider,
  Scale,
  Spacer,
  Badge
} from 'powder-ui';
import { useTheme } from 'styled-components';
import { useStacksAPI } from 'src/hooks/useStacksAPI';
import { RiCheckLine as CheckIcon } from 'react-icons/ri';
import { DemoContainer } from './util';

function DemoForm() {
  const { palette } = useTheme();
  const [text, setText] = React.useState<string>('');
  const [touched, setTouched] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
  const [isSent, setSent] = React.useState<boolean>(false);

  const { client } = useStacksAPI();

  const sendDemoRequest = async () => {
    if (error) return;
    setSubmitting(true);
    const response = await client.request<object>({
      method: 'post',
      data: { email: text },
      endpoint: `util/request-demo`
    });
    const { payload } = response;
    if (payload.success) {
      setSent(true);
    } else {
      setError('An error ocurred 😬. Please email daniel@ddluc.com');
    }
    setSubmitting(false);
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(e.target.value);
    if (!isValidEmail) setError('Please enter a valid email');
    else setError(undefined);
  };

  return (
    <Block p="40px 20px 60px" background={{ color: palette.neutral[5] }}>
      <DemoContainer id="demo">
        <Scale xs="100%" sm="100%" md="100%" lg="400px" xl="400px">
          <Typography.H2>Learn how PadStack can augment your real-estate business</Typography.H2>
          <Typography.Body size="12px" lineHeight="16px">
            Book a demo to learn more about the product, see a detailed breakdown of the financial
            model and learn how PadStack could intergrate into your business!
          </Typography.Body>
        </Scale>
        <Flex alignItems="center" gap="10px" wrap="wrap" textAlign="left">
          <Block width="300px">
            <TextInput
              value={text}
              name="email"
              label="Email Address"
              animated
              touched={touched}
              error={error}
              disabled={isSent}
              help={isSent ? "Thanks, we'll be in touch soon!" : ''}
              onBlur={() => setTouched(true)}
              onChange={(e) => onTextChange(e)}
            />
          </Block>
          <Block p="0px 0px 20px 0px">
            <Button
              text="Request a Demo"
              variation="default"
              color="#E54091"
              border="solid 1px #E54091"
              disabled={isSent || isSubmitting}
              fluid
              onClick={() => sendDemoRequest()}
            />
          </Block>
          <Block width="40px" p="0px 0px 20px 0px">
            {isSent && <Badge icon={<CheckIcon size="16px" />} type="success" />}
          </Block>
        </Flex>
      </DemoContainer>
      <Spacer spacing={8} />
      <Block width="100%" m="0 auto">
        <Divider />
      </Block>
      <Flex justifyContent="space-between" wrap="wrap">
        <Block width="200px">
          <Typography.Mono size="10px">PadStack © 2024</Typography.Mono>
        </Block>
        <Block width="276px">
          <Typography.Mono size="10px">
            Designed & Developed by &nbsp;
            <a href="http://ddluc.com" target="_blank" rel="noreferrer">
              DDLUC ENGINEERING LLC
            </a>
          </Typography.Mono>
        </Block>
      </Flex>
    </Block>
  );
}

export default DemoForm;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CSSTransitionAnimation,
  Sidesheet,
  Flex,
  Block,
  Spacer,
  Divider,
  Typography
} from 'powder-ui';
import { RiCloseLine as CloseIcon } from 'react-icons/ri';
import { useGlobalContext } from '~/src/contexts/global/context';
import { setUser } from '~/src/contexts/global/actions';
import { DesktopSidebar, MobileSidebar, Menu } from './bin';

import WhiteLogo from '../../../assets/logo-white.svg';
import DefaultLogo from '../../../assets/logo-default.svg';
import UserProfile from '../../../assets/user-icon.png';
import { Portal } from '../Portal';
import { ThemeToggle } from '../ThemeToggle';

export interface Props {
  show?: boolean;
  transition?: CSSTransitionAnimation;
  onClose?: () => void;
}

function Sidebar({
  show = false,
  transition = { duration: 500, delay: 500 },
  onClose = () => {}
}: Props): JSX.Element {
  const { state, dispatch } = useGlobalContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (state.user === null) {
      navigate('/');
    }
  }, [state.user, navigate]);

  const logoutUser = () => {
    // Logout User
    setUser(dispatch, { user: null, token: null });
  };

  const Logo = state.theme === 'dark' ? WhiteLogo : DefaultLogo;

  return (
    <>
      <DesktopSidebar transition={transition}>
        <Flex column grow={1} justifyContent="flex-start" alignItems="center" gap="12px">
          <Block p="4px 0px">
            <img src={Logo} alt="PadStack" height="20px" />
          </Block>
          <Spacer spacing={16} />
          <img src={UserProfile} alt="user profile" height={60} />
          <Typography.Mono size="22px" lineHeight="22px" weight={700}>
            {state.user?.username}
          </Typography.Mono>
          <Block m="0 auto">
            <Divider width="80px" />
          </Block>
          <Menu onLogout={logoutUser} />
        </Flex>
        <ThemeToggle />
      </DesktopSidebar>
      <Portal>
        <MobileSidebar>
          <Sidesheet show={show} onClose={onClose} closeIcon={<CloseIcon size="32px" />}>
            <Flex column grow={1} justifyContent="flex-start" alignItems="center" gap="12px">
              <Spacer spacing={16} />
              <img src={Logo} alt="PadStack" height="20px" />
              <Spacer spacing={16} />
              <img src={UserProfile} alt="user profile" height={60} />
              <Typography.Mono size="22px" lineHeight="22px" weight={700}>
                {state.user?.username}
              </Typography.Mono>
              <Block m="0 auto">
                <Divider width="80px" />
              </Block>
              <Menu onLogout={logoutUser} />
            </Flex>
            <ThemeToggle />
          </Sidesheet>
        </MobileSidebar>
      </Portal>
    </>
  );
}

// Export the component as the default export
export default Sidebar;

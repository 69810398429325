/* eslint-disable camelcase */
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    
    body {
        background-color: ${({ theme }) => theme.palette.background};
    }

    body, p { 
        font-family: 'IBM Plex Sans';
        font-weight: normal;    
    }

    a { 
        text-decoration: none;
    }

`;

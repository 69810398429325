import React from 'react';
import { Skeleton, Block, Card } from 'powder-ui';
import { Investment } from '~/src/hooks/useStacksAPI';
import styled, { Theme, useTheme } from 'styled-components';
import { Numeric } from '~/src/components/core/Numeric';
import { RiCrosshair2Fill as TargetIcon } from 'react-icons/ri';

const VisualizationContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const RangeLine = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.palette.neutral[4]};
  flex-grow: 1;
  position: relative;
`;

const Marker = styled.div<{ position: number }>`
  width: 1px;
  top: -6px;
  height: 16px;
  border-right: dashed 1px ${({ theme }) => theme.palette.neutral[1]};
  position: absolute;
  left: ${({ position }) => `${position}%`};
`;

const MarketValueMarker = styled.div<{ position: number }>`
  height: 24px;
  width: 24px;
  top: -11px;
  position: absolute;
  border-radius: 50%;
  fill: ${({ theme }) => theme.palette.primary.main};
  stroke: ${({ theme }) => theme.palette.primary.main};
  left: ${({ position }) => `${position}%`};
  transform: translateX(-50%);
`;

const Label = styled.div<{
  position: number;
  location: 'bottom' | 'top';
  width: number;
  bold?: boolean;
  italic?: boolean;
  mono?: boolean;
}>`
  text-align: center;
  top: ${({ location }) => (location === 'top' ? '-28px' : '18px')};
  width: ${({ width }) => `${width}px`};
  left: ${({ position }) => `${position}%`};
  transform: translateX(-50%);
  position: absolute;
  font-family: ${({ theme, mono }) =>
    mono ? theme.fonts.family.mono : theme.fonts.family.display};
  font-size: 12px;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  color: ${({ theme }) => theme.palette.foreground};
  margin: 0px;
`;

interface Props {
  skeleton: boolean;
  investment: Investment;
}

export function MarketRent({ investment, skeleton }: Props): JSX.Element {
  const theme: Theme = useTheme();
  const rentcast = investment.meta.data?.rentcast;

  const positions = React.useMemo(() => {
    if (rentcast) {
      const MARGIN = 10;
      const { rentRangeLow: low = 0, rent: marketValue = 0, rentRangeHigh: high = 0 } = rentcast;
      const min = low - MARGIN;
      const max = high + MARGIN;
      return {
        // Calculate positions as percentages of the total width
        min,
        low: ((low - min) / (max - min)) * 100,
        high: ((high - min) / (max - min)) * 100,
        marketValue: ((marketValue - min) / (max - min)) * 100,
        max
      };
    }
    return null;
  }, [rentcast]);

  if (skeleton || !positions) {
    return (
      <Block grow={1} basis={200} height="100px">
        <Skeleton type="box" height={100} fluid />
      </Block>
    );
  }

  return (
    <Block grow={1} shrink={1} basis="500px" height="100px">
      <Card height="100%" p="20px 0px">
        <VisualizationContainer>
          <RangeLine>
            <Marker position={positions.low} />
            <Label position={positions.low} location="top" width={100} italic>
              (low)
            </Label>
            <Label position={positions.low} location="bottom" width={52} mono>
              <Numeric number={rentcast?.rentRangeLow} currency magnitude="K" />
            </Label>
            <MarketValueMarker position={positions.marketValue}>
              <TargetIcon size="24px" fill={theme.palette.primary.main} />
            </MarketValueMarker>
            <Label position={positions.marketValue} location="top" width={100}>
              Market Rent<em>*</em>
            </Label>
            <Label position={positions.marketValue} location="bottom" width={52} bold mono>
              <Numeric number={rentcast?.rent} currency magnitude="K" mode="fixed" />
            </Label>
            <Marker position={positions.high} />
            <Label position={positions.high} location="top" width={100} italic>
              (high)
            </Label>
            <Label position={positions.high} location="bottom" width={52} mono>
              <Numeric number={rentcast?.rentRangeHigh} currency magnitude="K" mode="fixed" />
            </Label>
          </RangeLine>
        </VisualizationContainer>
      </Card>
    </Block>
  );
}

import React from 'react';
import { Flex, Block, Fieldset, Legend, Typography, Spacer, Skeleton, TextInput } from 'powder-ui';
import { RangeField } from '~/src/components/forms/fields/RangeField';
import { CurrencyField } from '~/src/components/forms/fields/CurrencyField';
import { useFormikContext } from 'formik';

import { TextField } from '../../../fields/TextField';
import { ProjectionSchema } from '../_schema';

interface Props {
  skeleton?: boolean;
  showTaxes?: boolean;
  disabled?: boolean;
}

function SaleFieldset({ skeleton, showTaxes = true, disabled = false }: Props): JSX.Element {
  const { values } = useFormikContext<ProjectionSchema>();

  const annualTaxes = React.useMemo(() => {
    return Math.floor((values.propertyPrice * values.millage) / 1000);
  }, [values.millage, values.propertyPrice]);

  const shouldShowTaxField = () => {
    if (!showTaxes) return 'none';
    return 'block';
  };

  return (
    <Fieldset gap="0px" indent={false}>
      <Legend indent={false}>
        {skeleton ? (
          <Skeleton type="box" height={22} width={160} />
        ) : (
          <Typography.H5>Sale & Financing</Typography.H5>
        )}
      </Legend>
      <Spacer spacing={6} />
      <Flex column gap="16px">
        <Flex row gap="16px" justifyContent="flex-start" wrap="wrap">
          <Block width="280px" grow={1}>
            <CurrencyField
              skeleton={skeleton}
              label="Available Assets"
              name="availableAssets"
              disabled={disabled}
              help="What are your current liquid available assets?"
            />
          </Block>
          <Block width="280px" grow={1}>
            <CurrencyField
              skeleton={skeleton}
              label="Purchase Price"
              name="propertyPrice"
              disabled={disabled}
              help="What is the (estimated) sale price of the property"
            />
          </Block>
          <Block width="280px" grow={1}>
            <Block width="180px">
              <TextField
                skeleton={skeleton}
                label="Duration (Years)"
                name="duration"
                type="number"
                disabled={disabled}
                help="The duration of the projection / loan"
              />
            </Block>
          </Block>
        </Flex>
        <Flex row gap="16px" justifyContent="space-between" wrap="wrap" width="100%">
          <Block grow={1} width="280px">
            <RangeField
              skeleton={skeleton}
              type="percent"
              name="interestRate"
              label="Interest Rate"
              min={0}
              max={16}
              units="%"
              step={0.01}
              help="Interest rate on the mortgage product"
              disabled={disabled}
              indicatorSize={48}
            />
          </Block>
          <Block grow={1} width="280px">
            <RangeField
              skeleton={skeleton}
              type="percent"
              name="percentDown"
              label="Down Payment"
              min={0}
              max={100}
              units="%"
              step={1}
              help="Your down payment, as a percentage of the homes value"
              disabled={disabled}
              indicatorSize={48}
            />
          </Block>
          <Block grow={1} width="280px">
            <RangeField
              skeleton={skeleton}
              type="default"
              name="millage"
              label="Millage"
              min={0}
              max={20}
              units="m"
              step={0.01}
              help="The amount of tax payable per dollar of the assessed property value"
              disabled={disabled}
              indicatorSize={48}
            />
          </Block>
        </Flex>
        <Block width="300px" display={shouldShowTaxField()}>
          <TextInput
            value={`$ ${annualTaxes}.00`}
            skeleton={skeleton}
            label="Monthly Tax Payment"
            name="taxes"
            disabled
            help="The total annual taxes owed"
          />
        </Block>
      </Flex>
      <Spacer spacing={4} />
    </Fieldset>
  );
}

export default SaleFieldset;

import React from 'react';
import { RangeInput } from 'powder-ui';
import { useField } from 'formik';

export interface Props {
  name: string;
  type: 'default' | 'percent';
  label?: string;
  id?: string;
  min?: number;
  max?: number;
  step?: number;
  thumbSize?: number;
  indicatorSize?: number;
  value?: number;
  units?: string;
  touched?: boolean;
  error?: string;
  help?: string;
  skeleton?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function RangeField(props: Props) {
  const { type = 'default', name, value, onChange = () => {}, ...baseProps } = props;

  const [field, meta, { setValue }] = useField<number>(name);

  const { error, touched } = meta;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'percent') {
      setValue(parseFloat(e.target.value) / 100);
    } else {
      setValue(parseFloat(e.target.value));
    }
    onChange(e);
  };

  const getValue = React.useCallback(() => {
    if (type === 'percent') return Math.round(field.value * 10000) / 100;
    return field.value;
  }, [field.value, type]);

  return (
    <RangeInput
      name={field.name}
      value={getValue()}
      onChange={onInputChange}
      error={error}
      touched={touched}
      {...baseProps}
    />
  );
}

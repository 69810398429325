import React from 'react';
import { Button, Flex, Block, Typography, Spacer, Divider, Scale } from 'powder-ui';

import { useTheme } from 'styled-components';
import AppPreviewLight from '../assets/app-preview-light.png';
import AppPreviewDark from '../assets/app-preview-dark.png';

function Hero() {
  const theme = useTheme();

  const getPreviewImage = () => {
    if (theme.mode === 'light') return AppPreviewLight;
    return AppPreviewDark;
  };

  return (
    <Scale xs="100%" sm="100%" md="100%" lg="720px" xl="720px" m="0 auto">
      <Flex column textAlign="center" p="40px 10px" alignItems="center" gap="20px">
        <Typography.H1 size="46px" lineHeight="52px">
          Comprehensive financial models and analysis for property investors
        </Typography.H1>
        <a href="#demo" aria-label="Book a Demo">
          <Button
            text="Book a Demo"
            width={180}
            alignment="center"
            color="#E54091"
            border="solid 1px #E54091"
          />
        </a>
        <Scale xs="90%" sm="80%" md="80%" lg="720px" xl="720px" m="0 auto">
          <img src={getPreviewImage()} alt="Padstack Application Preview" width="100%" />
        </Scale>
        <Block p="80px 0px 60px 0px" width="90%">
          <Typography.Body size="24px" italic weight={200}>
            Unlock Your Property&apos;s Potential{' '}
          </Typography.Body>
          <Spacer spacing={4} />
          <Typography.Body>
            Welcome to PadStack—the ultimate tool for savvy investors ready to make informed
            decisions on residential property investments. Dive deep into the financial intricacies
            of potential acquisitions and maximize your returns with precision.
          </Typography.Body>
          <Block width="60%" m="0 auto">
            <Divider spacing={{ top: '20px', bottom: '4px' }} />
          </Block>
        </Block>
      </Flex>
    </Scale>
  );
}

export default Hero;

import { TableDataColumn } from 'powder-ui';

export const columns: TableDataColumn[] = [
  {
    key: 'name',
    header: 'Name',
    sortable: false,
    width: 20,
    align: 'left'
  },
  {
    key: 'description',
    header: 'Description',
    sortable: false,
    width: 60,
    align: 'left'
  },
  {
    key: 'amount',
    header: 'Amount',
    sortable: true,
    width: 20,
    align: 'right'
  }
];

import * as Yup from 'yup';
import { ManagmentSchema } from './types';

const init = (): ManagmentSchema => ({
  vacancyRate: 0.08,
  managementFee: 0.12,
  maintenance: 0.18
});

const validation = Yup.object({
  vacancyRate: Yup.number()
    .required('Vacancy rate is required.')
    .min(0, 'Vacancy rate cannot be negative.')
    .max(1, 'Vacancy rate must be less than 1'),
  managementFee: Yup.number()
    .required('Management fee is required.')
    .min(0, 'Management fee cannot be negative.')
    .max(1, 'Management fee must be less than 100'),
  maintenance: Yup.number()
    .required('Maintenance rate is required.')
    .min(0, 'Maintenance rate cannot be negative.')
    .max(1, 'Maintenance rate must be less than 100')
});

export default { init, validation };

import styled from 'styled-components';

export { Overview } from './Overview';
export { TabManager } from './TabManager';
export { ProjectionListener } from './ProjectionListener';

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

import React from 'react';
import { Nullable } from '~/src/types';

const LOADING_ANIMATION_DURATION = 800;

export const useLoadingTransition = (
  processing: boolean = false,
  duration: number = LOADING_ANIMATION_DURATION
) => {
  const [isViewLoading, setIsViewLoading] = React.useState<boolean>(true);

  const timeout = React.useRef<Nullable<NodeJS.Timeout>>(null);

  React.useEffect(() => {
    if (!processing && !timeout.current) {
      timeout.current = setTimeout(() => setIsViewLoading(false), duration);
    } else {
      timeout.current = null;
      setIsViewLoading(true);
    }
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = null;
    };
  }, [processing, duration]);

  return { isViewLoading };
};

/* eslint-disable react/function-component-definition */
import React from 'react';
import { TooltipProps } from 'recharts';
import { Typography, Block, Card } from 'powder-ui';
import { Theme, useTheme } from 'styled-components';
import { Numeric } from '../../Numeric';

export const CurrencyTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label
}) => {
  const theme: Theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <Card
        p="8px"
        background={{ color: theme.palette.background }}
        border={`solid 1px ${theme.palette.neutral[3]}`}
      >
        <Typography.Body condensed size="10px" lineHeight="12px">
          {`Year: ${label}`}
        </Typography.Body>
        {payload.map((entry, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Block key={`item-${index}`} m="4px 0px">
            <Typography.Body condensed size="10px" lineHeight="12px">
              {`${entry.name}: `}
            </Typography.Body>
            <Typography.Mono condensed size="10px" lineHeight="12px" weight={800}>
              <Numeric number={entry.value as number} currency />
            </Typography.Mono>
          </Block>
        ))}
      </Card>
    );
  }
  return null;
};

export const RateTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload, label }) => {
  const theme: Theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <Card
        p="8px"
        background={{ color: theme.palette.background }}
        border={`solid 1px ${theme.palette.neutral[3]}`}
      >
        <Typography.Body condensed size="10px" lineHeight="12px">
          {`Year: ${label}`}
        </Typography.Body>
        {payload.map((entry, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Block key={`item-${index}`} m="4px 0px">
            <Typography.Body condensed size="10px" lineHeight="12px">
              {`${entry.name}: `}
            </Typography.Body>
            <Typography.Mono condensed size="10px" lineHeight="12px" weight={800}>
              <Numeric number={entry.value as number} rate units="%" />
            </Typography.Mono>
          </Block>
        ))}
      </Card>
    );
  }
  return null;
};

export const AmortizationTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label
}) => {
  const theme: Theme = useTheme();
  if (active && payload && payload.length) {
    return (
      <Card
        p="8px"
        background={{ color: theme.palette.background }}
        border={`solid 1px ${theme.palette.neutral[3]}`}
      >
        <Typography.Body condensed size="10px" lineHeight="12px">
          {`Period: ${label}`}
        </Typography.Body>
        {payload.map((entry, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Block key={`item-${index}`} m="4px 0px">
            <Typography.Body condensed size="10px" lineHeight="12px">
              {`${entry.name}: `}
            </Typography.Body>
            <Typography.Mono condensed size="10px" lineHeight="12px" weight={800}>
              <Numeric number={entry.value as number} currency />
            </Typography.Mono>
          </Block>
        ))}
      </Card>
    );
  }
  return null;
};

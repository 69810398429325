import { TableDataColumn } from 'powder-ui';

export const columns: TableDataColumn[] = [
  {
    key: 'unit',
    header: 'Unit',
    sortable: false,
    width: 20
  },
  {
    key: 'base',
    header: 'Base Rent',
    sortable: true,
    width: 14,
    align: 'right'
  },
  {
    key: 'fees.pets',
    header: 'Pet',
    sortable: false,
    width: 14,
    align: 'right'
  },
  {
    key: 'fees.storage',
    header: 'Storage',
    sortable: false,
    width: 14,
    align: 'right'
  },
  {
    key: 'fees.parking',
    header: 'Parking',
    sortable: false,
    width: 14,
    align: 'right'
  },
  {
    key: 'fees.other',
    header: 'Other',
    sortable: false,
    width: 14,
    align: 'right'
  },
  {
    key: 'edir',
    header: '',
    sortable: false,
    width: 10,
    align: 'right'
  }
];

import { AlertData } from 'powder-ui';
import React from 'react';

// Args
type Args = { alert: AlertData };

// Payload
type Payload = { alert: AlertData };

// Action
export type AddAlert = {
  type: 'ADD_ALERT';
  payload: Payload;
};

export const addAlert = (dispatch: React.Dispatch<AddAlert>, args: Args): void =>
  dispatch({
    type: 'ADD_ALERT',
    payload: { alert: args.alert }
  });

import React from 'react';
import { Flex, Button, Block, Skeleton, Spacer } from 'powder-ui';
import { Header } from '~/src/components/core/Header';

export function SkeletonView(): JSX.Element {
  return (
    <Flex column gap="10px" width="100%">
      <Header text="Investment Detail" skeleton>
        <Flex alignItems="center" gap="4px">
          <Button text="Edit" type="button" skeleton />
          <Button text="Edit" type="button" skeleton />
        </Flex>
      </Header>
      <Flex gap="16px">
        <Block width="472px">
          <Skeleton type="box" height={200} fluid />
        </Block>
        <Block grow={1} radius="4px">
          <Skeleton type="box" height={200} fluid />
        </Block>
      </Flex>
      <Flex gap="16px">
        <Block grow={1} basis={200}>
          <Skeleton type="box" height={100} fluid />
        </Block>
        <Block grow={1} basis={200}>
          <Skeleton type="box" height={100} fluid />
        </Block>
        <Block grow={1} basis={200}>
          <Skeleton type="box" height={100} fluid />
        </Block>
        <Block grow={1} basis={200}>
          <Skeleton type="box" height={100} fluid />
        </Block>
        <Block grow={1} basis={200}>
          <Skeleton type="box" height={100} fluid />
        </Block>
      </Flex>
      <Spacer spacing={4} />
      <Skeleton type="box" height={40} width={180} />
      <Spacer spacing={1} />
      <Skeleton type="box" fluid height={300} />
    </Flex>
  );
}

export default SkeletonView;

/*
 * Define styled utility components specific to this components implementation
 * with and example implementation
 */
import styled, { css } from 'styled-components';
import { CSSTransitionAnimation } from 'powder-ui';
import animations from './animations';

export interface ContainerProps {
  transition: CSSTransitionAnimation;
}

// Defined a styled component implmementation
export const Container = styled.div<ContainerProps>`
  height: 60px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing[4]};
  fill: #fff;
  background-color: ${(props) => props.theme.palette.neutral[5]};
  z-index: ${({ theme }) => theme.zIndex[2]};
  transform: translateY(-800px);
  ${({ transition }) => css`
    animation: ${transition.duration}ms ease-in-out ${transition.delay}ms ${animations.slideInTop}
      forwards;
  `};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    border-radius: 0px;
    height: 80px;

    > #menu {
      display: none;
    }
  }
`;

import { User } from '~/src/hooks/useStacksAPI';

export const getAuthToken = () => {
  const token = localStorage.getItem('auth-token');
  if (token) return JSON.parse(token);
  return null;
};

export const getUser = () => {
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user) as User;
  return null;
};

export const getTheme = () => {
  const theme = localStorage.getItem('theme');
  if (theme) return JSON.parse(theme) as 'dark' | 'light';
  return 'light';
};

import React from 'react';
import type { Investment } from '~/src/hooks/useStacksAPI';
import { Skeleton, Card, Flex, Typography, Block } from 'powder-ui';
import { WalkscoreChart } from '~/src/components/core/Charts/WalkscoreChart';

interface Props {
  skeleton: boolean;
  investment: Investment;
}

export function Walkscore({ investment, skeleton }: Props): JSX.Element {
  const walkscore = investment.meta.data?.walkscore;

  if (skeleton || !walkscore) {
    return (
      <Block grow={1} basis={200} height="100px">
        <Skeleton type="box" height={100} fluid />
      </Block>
    );
  }

  return (
    <Block grow={1} shrink={0} basis="320px" height="100px">
      <Card height="100%">
        <Flex height="100%" gap="16px" justifyContent="space-evenly" alignItems="center">
          <Flex column alignItems="center">
            <WalkscoreChart score={walkscore.walkscore} />
            <Typography.Body condensed size="12px">
              Walk Score®
            </Typography.Body>
          </Flex>
          <Flex column alignItems="center">
            <WalkscoreChart score={walkscore.transit?.score} />
            <Typography.Body condensed size="12px">
              Transit Score®
            </Typography.Body>
          </Flex>
          <Flex column alignItems="center">
            <WalkscoreChart score={walkscore.bike?.score} />
            <Typography.Body condensed size="12px">
              Bike Score®
            </Typography.Body>
          </Flex>
        </Flex>
      </Card>
    </Block>
  );
}

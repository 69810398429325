import React from 'react';
import styled from 'styled-components';
import Timeline from 'react-timeline';
import { Block, Typography, Flex, Divider, Scale } from 'powder-ui';

import { useGlobalContext } from '~/src/contexts/global/context';
import TimelineBackground from '../assets/timeline-bg.png';
import { getTimelineEvents, getTimelineTheme, renderDate } from '../util';
import { TimelineContainer } from './util';

const Background = styled.div`
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

function ProjectTimeline() {
  const { state } = useGlobalContext();
  const { theme } = state;
  const TIMELINE_THEME = getTimelineTheme(theme);
  const EVENTS = getTimelineEvents(theme);
  return (
    <Block p="40px 0px" textAlign="center" position="relative">
      <Background>
        <img src={TimelineBackground} alt="background" />
      </Background>
      <TimelineContainer id="progress">
        <Flex column width="50%" justifyContent="center" alignItems="center">
          <Block width="300px">
            <Typography.H3 size="36px" lineHeight="42px" weight={200}>
              Our Work in Progress
            </Typography.H3>
            <Typography.Body>
              PadStack is continuously improving, fueled by user feedback and our commitment to
              excellence. Stay tuned!
            </Typography.Body>
            <Block>
              <Divider spacing={{ top: '16px', bottom: '4px' }} />
            </Block>
          </Block>
        </Flex>
        <Scale xs="80%" sm="400px" md="400px" lg="50%" xl="50%">
          <Timeline
            activeEventId="6"
            theme={TIMELINE_THEME}
            events={EVENTS}
            showDetailPanel={false}
            showHeader={false}
            height={1000}
            customDateRenderer={renderDate}
          />
        </Scale>
      </TimelineContainer>
    </Block>
  );
}

export default ProjectTimeline;

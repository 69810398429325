import React from 'react';
import { Skeleton } from 'powder-ui';

import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  YAxis
} from 'recharts';
import { Theme, useTheme } from 'styled-components';
import { Projection } from '~/src/hooks/useStacksAPI';
import { CurrencyTooltip } from './bin';

interface Props {
  projection: Projection;
  skeleton?: boolean;
}

export function CashflowFuturesChart({ projection, skeleton }: Props): JSX.Element {
  const theme: Theme = useTheme();
  const [key, setKey] = React.useState(Date.now());

  React.useEffect(() => {
    // Update the key when the data prop changes
    setKey(Date.now());
  }, [projection.analysis?.growth]);

  const isProfitLoss = () => {
    let isLoss: boolean = false;
    if (projection.analysis) {
      const { growth = [] } = projection.analysis;
      growth.forEach((year) => {
        if (year.total.profit < 0) {
          isLoss = true;
        }
      });
    }
    return isLoss;
  };

  if (skeleton) {
    return <Skeleton type="box" height={480} fluid />;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={projection.analysis?.growth}
        key={key}
        margin={{
          top: 8,
          right: 2,
          left: 8,
          bottom: 0
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis
          domain={['auto', 'auto']}
          fontSize="8px"
          tickCount={8}
          type="number"
          tickFormatter={(value) => `$ ${value.toFixed(2)}`}
        />
        <XAxis dataKey="year" interval={2} fontSize="8px" allowDataOverflow />
        {isProfitLoss() && <ReferenceLine y={0} stroke="red" strokeDasharray="3 3" />}
        <Tooltip content={CurrencyTooltip} />
        <Legend wrapperStyle={{ fontSize: '12px', fontFamily: theme.fonts.family.mono }} />
        <Line
          type="monotone"
          name="Annual Revenue"
          dataKey="annual.revenue"
          stroke={theme.palette.visualization[2]}
          dot={{ r: 4, fill: theme.palette.visualization[2] }}
          activeDot={{ r: 6, fill: theme.palette.visualization[2] }}
        />
        <Line
          type="monotone"
          name="Annual Expenses"
          dataKey="annual.expenses"
          stroke={theme.palette.visualization[1]}
          dot={{ r: 4, fill: theme.palette.visualization[1] }}
          activeDot={{ r: 6, fill: theme.palette.visualization[1] }}
        />
        <Line
          type="monotone"
          name="Annual Profit"
          dataKey="annual.profit"
          stroke={theme.palette.visualization[0]}
          dot={{ r: 4, fill: theme.palette.visualization[0] }}
          activeDot={{ r: 6, fill: theme.palette.visualization[0] }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

import React from 'react';
import { Block, Toggle } from 'powder-ui';
import { RiSunLine as LightIcon, RiMoonClearLine as DarkIcon } from 'react-icons/ri';
import { useGlobalContext } from '~/src/contexts/global/context';
import { setTheme } from '~/src/contexts/global/actions';

export function ThemeToggle(): JSX.Element {
  const { dispatch, state } = useGlobalContext();
  const [isDarkModeEnabled, setIsDarkModeEnabled] = React.useState(state.theme === 'dark');

  React.useEffect(() => {
    const theme = isDarkModeEnabled ? 'dark' : 'light';
    setTheme(dispatch, { theme });
  }, [isDarkModeEnabled, dispatch]);

  return (
    <Block position="absolute" bottom="10px" left="0" right="0" m="0 auto">
      <Toggle
        name="theme"
        label="Dark Mode"
        on={isDarkModeEnabled}
        hideLabel
        alignment="center"
        annotations={{
          on: <DarkIcon />,
          off: <LightIcon />
        }}
        onChange={() => setIsDarkModeEnabled(!isDarkModeEnabled)}
      />
    </Block>
  );
}

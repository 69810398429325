import { Block, Flex, Skeleton, Typography } from 'powder-ui';
import React from 'react';
import { Theme, useTheme } from 'styled-components';
import { Numeric } from '../Numeric';

interface Props {
  skeleton?: boolean;
  currency?: boolean;
  rate?: boolean;
  units?: string;
  precision?: number;
  magnitude?: 'M' | 'K';
  mode?: 'auto' | 'fixed';
  stat: number | undefined;
  label: string;
}

function Statistic({
  label,
  stat,
  currency,
  rate,
  units,
  skeleton,
  magnitude,
  mode = 'auto',
  precision = 2
}: Props): JSX.Element {
  /** ------------ */

  const theme: Theme = useTheme();
  if (skeleton) {
    return <Skeleton type="box" height={50} width={200} />;
  }

  const getColor = (): string => {
    if (stat && stat < 0) return theme.palette.danger.main;
    return theme.palette.foreground;
  };

  return (
    <Block grow={0} shrink={0}>
      <Flex column gap="2px">
        <Typography.Body size="12px" condensed color={getColor()}>
          {label}
        </Typography.Body>
        <Typography.Mono size="22px" weight={800} color={getColor()} condensed>
          <Numeric
            number={stat}
            currency={currency}
            magnitude={magnitude}
            mode={mode}
            units={units}
            rate={rate}
            precision={precision}
          />
        </Typography.Mono>
      </Flex>
    </Block>
  );
}

export default Statistic;

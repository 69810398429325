import { keyframes } from 'styled-components';

const slideInRight = keyframes`

  0% { 
    transform: translateX(-500px);
  }

  100% {
    transform: translateX(0);
  }

`;

export default { slideInRight };

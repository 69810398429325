import React from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  tabKey: string;
  activeTab: string;
}

const Container = styled.div<{ show: boolean }>`
  height: ${({ show }) => (show ? 'auto' : '0px')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 500ms ease-in-out 200ms;
  will-change: opacity;
  overflow: hidden;
`;

export function TabManager({ children, tabKey, activeTab }: Props): JSX.Element {
  const isTabActive = activeTab === tabKey;
  return <Container show={isTabActive}>{children}</Container>;
}

/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable dot-notation */

/**
 * API Client
 */
import { Nullable } from './types/util';
import { API } from './types/api';

type Environment = 'development' | 'staging' | 'production';

class APIClient {
  key: string;

  token: Nullable<string>;

  environment: 'development' | 'staging' | 'production';

  baseUrl: string;

  constructor() {
    this.token = null;
    this.key = '';
    this.environment = process.env.APP_ENV as Environment;
    this.baseUrl = this.setBaseUrl();
    console.log('Setup API connection', this.baseUrl, this.environment);
  }

  setBaseUrl(): string {
    switch (this.environment) {
      case 'development':
        return 'http://localhost:4030';
      case 'production':
        return 'https://api.padstack.io/v1';
      default:
        return 'https://api.padstack.io/v1';
    }
  }

  setAuth(token: string) {
    this.token = token;
  }

  // Encode query parameters object into a query string
  qs(params: object): string {
    return Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key as keyof object])}`)
      .join('&');
  }

  // Execute the request
  async exec<T = object>(url: string, options: RequestInit): Promise<API.Response<T>> {
    const response = await fetch(url, options);
    const payload: API.Payloads<T> = await response.json();
    return { payload, status: response.status };
  }

  async request<T>({
    method,
    endpoint,
    params,
    data
  }: API.RequestOptions): Promise<API.Response<T>> {
    try {
      let url = `${this.baseUrl}/${endpoint}`;
      const options: RequestInit = {
        method,
        headers: {
          'X-API-KEY': this.key || '',
          'X-API-Client': 'padstack-app-client',
          'Content-Type': 'Application/JSON',
          Authorization: this.token ? `Bearer ${this.token}` : ''
        }
      };
      // Assign url params for get requests
      if (method === 'get' && params) url = `${url}?${this.qs(params)}`;
      // Set request body for post
      if (method !== 'get' && data) options.body = JSON.stringify(data);
      // Execute the request
      const { payload, status } = await this.exec<T>(url, options);
      console.log(`REQUEST COMPLETE: ${options.method} ${url} => ${status}`);
      if (!payload.success) {
        console.warn('An API error occurred:', payload);
      }
      return { payload, status };
    } catch (err) {
      console.error('ERROR', err);
      const payload: API.ErrorPayload = {
        success: false,
        message: 'An unexpected error occurred',
        type: 'ApiClientError'
      };
      return { payload, status: 500 };
    }
  }
}

export default new APIClient();

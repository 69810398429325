import React from 'react';

export interface Props {
  currency?: boolean;
  rate?: boolean;
  units?: string;
  precision?: number;
  magnitude?: 'M' | 'K';
  mode?: 'auto' | 'fixed';
  number: number | undefined | any;
}

function Numeric({
  number,
  currency,
  rate,
  units,
  magnitude,
  mode = 'auto',
  precision = 2
}: Props): JSX.Element {
  /** ----------  */
  const hasMillionMagnitude = (n: number) =>
    magnitude === 'M' && (mode === 'fixed' || n > 1000000 || n < -1000000);

  const hasThousandMagniture = (n: number) =>
    magnitude === 'K' && (mode === 'fixed' || n > 1000 || n < -1000);

  const round = (n: number | undefined = 0): string => {
    const config = {
      maximumFractionDigits: precision,
      minimumFractionDigits: precision
    };
    if (rate) return (n * 100).toLocaleString('en-US', config);
    if (hasMillionMagnitude(n)) {
      return `${(n / 1000000).toLocaleString('en-US', config)}M`;
    }
    if (hasThousandMagniture(n)) {
      return `${(n / 1000).toLocaleString('en-US', config)}K`;
    }
    return n.toLocaleString('en-US', config);
  };

  return (
    <>
      {currency && '$'} {round(number)} {units && units}
    </>
  );
}

export default Numeric;

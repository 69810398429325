import { ObjectSchema } from 'yup';

// Define a generic type for Yup object schemas without specifying the detailed structure
type GenericYupSchema = ObjectSchema<any>;

export function mergeSchemas(...schemas: GenericYupSchema[]): ObjectSchema<any> {
  const [first, ...rest] = schemas;
  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);
  return merged;
}

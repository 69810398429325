import { px } from 'powder-ui';
import styled from 'styled-components';

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
  width: string;
}

export const Tab = styled.button<Props>`
  border: none;
  margin: 0px;
  padding: 8px 8px;
  width: ${({ width = 'auto' }) => width};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  font-family: ${({ theme }) => theme.fonts.family.display};
  font-size: ${({ theme }) => px(theme.fonts.size.small - 2)};
  font-weight: ${({ isActive, theme }) =>
    isActive ? theme.fonts.weight.bold : theme.fonts.weight.light};
  color: ${({ theme, isActive }) =>
    isActive ? theme.palette.primary.shades[1] : theme.palette.neutral[1]};
  background-color: ${({ theme }) => theme.palette.background};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.neutral[5]};
  }

  &::after {
    content: '';
    display: ${({ isActive }) => (isActive ? 'block' : 'none')};
    position: absolute;
    height: 1px;
    bottom: -5px;
    left: 0px;
    right: 0px;
    z-index: 100;
    width: 72%;
    margin-left: auto;
    margin-right: auto;
    background-color: ${({ theme }) => theme.palette.primary.shades[1]};
  }
`;

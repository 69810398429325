import React from 'react';
import { v4 as uuid } from 'uuid';
import { Link, useNavigate } from 'react-router-dom';

import { Flex, Button, Scale } from 'powder-ui';
import { Header } from '~/src/components/core/Header';
import { RiSendBackward as CloneIcon, RiAddFill as AddIcon } from 'react-icons/ri';

import { useStacksAPI } from '~/src/hooks/useStacksAPI/useStacksAPI';
import { useLoadingTransition } from '~/src/hooks/useLoadingTransition/useLoadingTransition';
import { useOnce } from '~/src/hooks/useOnce';

import { isSuccessPayload } from '~/src/hooks/useStacksAPI';
import type { Investment } from '~/src/hooks/useStacksAPI';
import type { Nullable } from '~/src/types';

import { addAlert } from '~/src/contexts/global/actions';
import { useGlobalContext } from '~/src/contexts/global/context';
import { ErrorAlert } from '~/src/components/core/Alerts';
import { InvestmentCard } from '~/src/components/core/InvestmentCard';
import { DeleteInvestmentDialog } from '~/src/components/core/DeleteInvestmentDialog';
import { NoResults, SkeletonView } from './bin';

const LOADING_TRANSITION_DURATION = 1000;

function InvestmentDashboardView(): JSX.Element {
  const { dispatch } = useGlobalContext();
  const navigate = useNavigate();
  const { listInvestments, deleteInvestment } = useStacksAPI();
  const [investments, setInvestments] = React.useState<Investment[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [showDialog, setShowDialog] = React.useState(false);
  const [activeInvestment, setActiveInvestment] = React.useState<Nullable<Investment>>(null);

  const { isViewLoading } = useLoadingTransition(isLoading, LOADING_TRANSITION_DURATION);

  const loadView = async () => {
    setIsLoading(true);
    const { payload } = await listInvestments();
    if (isSuccessPayload(payload)) {
      const { data } = payload;
      setInvestments(data);
      setIsLoading(false);
    } else {
      navigate('/error', { state: payload });
    }
  };

  const onDeleteInvestment = async (id: string) => {
    const args = { params: { id } };
    const { payload } = await deleteInvestment(args);
    // Reload the page once the investment has been deleted
    if (isSuccessPayload(payload)) {
      loadView();
    } else {
      addAlert(dispatch, {
        alert: ErrorAlert(uuid(), {
          title: 'An error occurred',
          message: 'Could not delete investment'
        })
      });
    }
  };

  useOnce(() => {
    loadView();
  }, [loadView]);

  if (isViewLoading) return <SkeletonView cards={investments.length || 3} />;

  return (
    <Flex column gap="10px" width="100%">
      <Header text="Investments">
        <Flex gap="10px">
          <Link to="/investment/new">
            <Button iconSize="sm" icon={<AddIcon size="16px" />} text="Create Investment" />
          </Link>
          <Button disabled iconSize="sm" icon={<CloneIcon size="16px" />} text="Compare" />
        </Flex>
      </Header>
      {investments.length === 0 && <NoResults />}
      <Flex row wrap="wrap" gap="10px" justifyContent="flex-start">
        {investments?.map((investment) => (
          <Scale
            xs="100%"
            sm="100%"
            md="calc(50% - 10px)"
            lg="calc(50% - 10px)"
            xl="calc(33.333% - 10px)"
          >
            <InvestmentCard
              key={investment.id}
              investment={investment}
              withActions
              onDelete={(id) => {
                setShowDialog(true);
                setActiveInvestment(investments.find((i) => i.id === id) || null);
              }}
            />
          </Scale>
        ))}
      </Flex>
      <DeleteInvestmentDialog
        investment={activeInvestment}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        onConfirmDelete={(id) => onDeleteInvestment(id)}
      />
    </Flex>
  );
}

export default InvestmentDashboardView;

import React from 'react';
import { Skeleton } from 'powder-ui';

import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  YAxis
} from 'recharts';
import { Theme, useTheme } from 'styled-components';
import { Projection } from '~/src/hooks/useStacksAPI';
import { AmortizationTooltip } from './bin';

interface Props {
  projection: Projection;
  skeleton?: boolean;
}

export function LoanBalanceChart({ projection, skeleton }: Props): JSX.Element {
  const theme: Theme = useTheme();
  const [key, setKey] = React.useState(Date.now());

  React.useEffect(() => {
    // Update the key when the data prop changes
    setKey(Date.now());
  }, [projection.analysis?.growth]);

  if (skeleton || !projection.analysis?.loan) {
    return <Skeleton type="box" height={300} fluid />;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={projection.analysis?.loan?.amortization?.schedule}
        key={key}
        margin={{
          top: 8,
          right: 2,
          left: 0,
          bottom: 0
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis
          domain={['auto', 'auto']}
          fontSize="8px"
          tickCount={12}
          type="number"
          tickFormatter={(value) => `$ ${(value / 1000).toFixed(0)}K`}
        />
        <XAxis dataKey="period" interval={24} fontSize="8px" allowDataOverflow />
        <Tooltip content={AmortizationTooltip} />
        <Legend wrapperStyle={{ fontSize: '12px', fontFamily: theme.fonts.family.mono }} />
        <Line
          type="monotone"
          name="Balance"
          dataKey="balance"
          stroke={theme.palette.visualization[3]}
          dot={false}
          activeDot={{ r: 6, fill: theme.palette.visualization[3] }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RiArrowLeftLine as BackArrow } from 'react-icons/ri';
import { Block, Flex, Typography, Divider, Button, Skeleton } from 'powder-ui';

export interface Props {
  text: string;
  children?: React.ReactNode;
  back?: boolean;
  backText?: string;
  backTo?: string;
  skeleton?: boolean;
}

function Header({ children, text, back, backText, backTo, skeleton }: Props): JSX.Element {
  const navigate = useNavigate();

  const showBackButton = () => back || backText || backTo;

  const onBackClick = () => {
    if (backTo) navigate(backTo);
    else {
      navigate(-1);
    }
  };

  if (skeleton) {
    return (
      <Block>
        <Block height="20px">
          {showBackButton() && <Button skeleton height={20} width={40} />}
        </Block>
        <Flex justifyContent="space-between" height="40px" alignItems="center" p="2px 0px">
          <Skeleton type="box" height={40} width={320} />
          <Block>{children}</Block>
        </Flex>
        <Divider />
      </Block>
    );
  }

  return (
    <Block>
      <Block height="20px">
        {showBackButton() && (
          <Button
            onClick={onBackClick}
            variation="minimal"
            condensed
            icon={<BackArrow size="16px" />}
            text={backText || 'Back'}
            textSize={12}
            iconSize="sm"
          />
        )}
      </Block>
      <Flex justifyContent="space-between" height="40px" alignItems="center" p="2px 0px">
        <Typography.H2 weight={300}>{text}</Typography.H2>
        <Block>{children}</Block>
      </Flex>
      <Divider />
    </Block>
  );
}

export default Header;

import React from 'react';
import { Card, Flex, Typography, Button, Divider, Badge, Block } from 'powder-ui';
import { Investment } from '~/src/hooks/useStacksAPI';
import { Theme, useTheme } from 'styled-components';
import { RiDeleteBinFill as DeleteButton, RiEdit2Line, RiEyeFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Address, Badges, Stats, PropertyIcon } from './bin/Content';

interface Props {
  investment: Investment;
  withActions?: boolean;
  mini?: boolean;
  onDelete?: (id: string) => void;
}

function InvestmentCard({ investment, withActions, mini, onDelete = () => {} }: Props) {
  const theme: Theme = useTheme();

  if (withActions) {
    return (
      <Card width="100%" background={{ color: theme.palette.background }}>
        <Flex justifyContent="space-between" alignItems="center" p="4px">
          <Typography.H5 condensed>{investment.address}</Typography.H5>
          <Flex alignItems="center" gap="4px">
            <Link to={`/investment/edit/${investment.id}`}>
              <Button type="button" variation="secondary" icon={<RiEdit2Line size="16px" />} />
            </Link>
            <Button
              type="button"
              variation="secondary"
              icon={<DeleteButton size="16px" fill={theme.palette.danger.main} />}
              intent="danger"
              onClick={() => onDelete(investment.id)}
            />
          </Flex>
        </Flex>
        <Divider />
        <Flex p="20px 8px" gap="20px" alignItems="center">
          <Block width="140px">
            <PropertyIcon investment={investment} />
          </Block>
          <Flex column gap="8px" grow={1} width="100%">
            <Address investment={investment} showAddress={false} />
            <Badges investment={investment} />
            <Stats investment={investment} />
            <Link to={`/investment/${investment.id}`}>
              <Button text="View" width={180} icon={<RiEyeFill size="16px" />} />
            </Link>
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (mini) {
    return (
      <Card width="100%" background={{ color: theme.palette.background }}>
        <Flex p="0px 0px" gap="12px" alignItems="center">
          <PropertyIcon investment={investment} height={28} />
          <Flex column gap="4px" grow={1} width="100%">
            <Typography.H6 size="12px" lineHeight="12px" condensed>
              {investment.address}
            </Typography.H6>
            <Typography.H6 size="10px" lineHeight="10px" condensed>
              {investment.city}, {investment.state} {investment.zip}
            </Typography.H6>
          </Flex>
          <Badge
            text={`${investment.units.items.length} Units`}
            type="primary"
            colors={{
              background: theme.palette.primary.shades[4],
              text: theme.palette.primary.shades[0],
              border: theme.palette.primary.shades[4],
              icon: theme.palette.primary.shades[0]
            }}
          />
        </Flex>
      </Card>
    );
  }

  return (
    <Card width="100%" background={{ color: theme.palette.background }}>
      <Flex p="20px 8px" gap="20px" alignItems="center">
        <PropertyIcon investment={investment} />
        <Flex column gap="8px" grow={1} width="100%">
          <Address investment={investment} />
          <Badges investment={investment} />
          <Stats investment={investment} />
        </Flex>
      </Flex>
    </Card>
  );
}

export default InvestmentCard;

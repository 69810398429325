import React from 'react';
import { TextInput } from 'powder-ui';
import { useField } from 'formik';

interface Props {
  name: string;
  label: string;
  placeholder?: string;
  hideLabel?: boolean;
  animated?: boolean;
  error?: string;
  help?: string;
  type?: 'text' | 'email' | 'tel' | 'password' | 'number';
  touched?: boolean;
  disabled?: boolean;
  condensed?: boolean;
  skeleton?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export function CurrencyField(props: Props) {
  const { name, onBlur = () => {}, onChange = () => {}, onFocus = () => {}, ...baseProps } = props;

  const [field, meta, helpers] = useField<number>(name);
  const { setValue } = helpers;
  const { error, touched } = meta;

  const [inputValue, setInputValue] = React.useState('$ 0.00');

  const [isFocused, setIsFocused] = React.useState(false);

  React.useEffect(() => {
    // This effect ensures the local input value is in sync with Formik's state when not focused
    setInputValue(field.value.toString());
  }, [field.value]);

  React.useEffect(() => {
    if (!isFocused) {
      const config = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
      const formattedValue = `$ ${field.value.toLocaleString('en-US', config)}`;
      setInputValue(formattedValue);
    }
  }, [field.value, isFocused]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    // Optionally, call props.onChange if you have additional logic to execute
    onChange(e);
  };

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    // Show the raw numeric value for editing
    setInputValue(field.value.toString());
    onFocus(e);
    // Handle cursor focus
    const inputElement = e.target;
    // Wait for the next tick to ensure the input's value has been updated
    setTimeout(() => {
      // Example: Set cursor position at the end of the input value
      const { length } = inputElement.value;
      inputElement.setSelectionRange(length, length);
    }, 0);
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(e);
    // Updated to remove $ signs as well as non-numeric characters except the decimal point and minus sign
    const sanitizedInput = e.target.value.replace(/[^\d.-]/g, '');
    // Attempt to parse the sanitized input as a float, if it's a valid number or empty string, update Formik
    const numericValue = sanitizedInput ? parseFloat(sanitizedInput) : NaN;
    // Update the form state
    if (!Number.isNaN(numericValue) || sanitizedInput === '') {
      setValue(numericValue || 0, true);
    } else if (sanitizedInput.match(/^\d*\.$/)) {
      setValue(0, true);
    }
    setIsFocused(false);
    onBlur(e);
  };

  return (
    <TextInput
      name={field.name}
      value={inputValue}
      type="text"
      onBlur={onInputBlur}
      onChange={onInputChange}
      onFocus={onInputFocus}
      error={error}
      touched={touched}
      {...baseProps}
    />
  );
}

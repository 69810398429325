import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { MainView } from './views/Main';
import { DashboardView } from './views/Dashboard';
import { CreateInvestmentView } from './views/CreateInvestment';
import { ErrorView } from './views/Error/Error';
import { EditInvestment } from './views/EditInvestment';
import CreateProjectionView from './views/CreateProjection/CreateProjectionView';
import { InvestmentView } from './views/Investment';
import { ProjectionView } from './views/Projection';
import LoginView from './views/Auth/Login';
import { HomepageView } from './views/Homepage';

export function Router() {
  return (
    <Routes>
      <Route index element={<HomepageView />} />
      <Route path="/login" element={<LoginView />} />
      <Route path="/dashboard" element={<MainView />}>
        <Route index element={<DashboardView />} />
      </Route>
      <Route path="/investment" element={<MainView />}>
        <Route path="new" element={<CreateInvestmentView />} />
        <Route path=":id" element={<InvestmentView />} />
        <Route path="edit/:id" element={<EditInvestment />} />
      </Route>
      <Route path="/error" element={<MainView sidebar={false} />}>
        <Route index element={<ErrorView />} />
      </Route>
      <Route path="/projection" element={<MainView />}>
        <Route path="new" element={<CreateProjectionView />} />
      </Route>
      <Route path="/projection" element={<MainView />}>
        <Route path=":id" element={<ProjectionView />} />
      </Route>
    </Routes>
  );
}

import React from 'react';
import { Block, Divider, Flex } from 'powder-ui';
import { useGlobalContext } from '~/src/contexts/global/context';
import Menu from './Menu';
import { Head } from './util';

import LogoLight from '../assets/logo-light.svg';
import LogoDark from '../assets/logo-dark.svg';

function TopBar() {
  const { state } = useGlobalContext();
  const { theme } = state;

  const getLogo = () => {
    if (theme === 'light') return LogoLight;
    return LogoDark;
  };

  const getDividerColor = () => {
    if (theme === 'light') return undefined;
    return '#cecece';
  };

  return (
    <Head>
      <Block height="80px" m="0 auto" p="20px 0px 0px 0px">
        <Flex alignItems="center" p="10px 20px" justifyContent="space-between">
          <Block>
            <img src={getLogo()} alt="Pad Stack" height="30px" />
          </Block>
          <Menu />
        </Flex>
        <Divider color={getDividerColor()} />
      </Block>
    </Head>
  );
}

export default TopBar;

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeContextProvider } from '../theme/ThemeContext';
import { GlobalContextProvider } from '../contexts/global/context';
import { Router } from './Router';

export function App(): JSX.Element {
  return (
    <GlobalContextProvider>
      <ThemeContextProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ThemeContextProvider>
    </GlobalContextProvider>
  );
}

import React from 'react';
import { useTheme, Theme } from 'styled-components';
import { Flex, Block, isMobile, useWindowSize } from 'powder-ui';
import type { CSSTransitionAnimation } from 'powder-ui';

import { Sidebar } from '../Sidebar';
import { Navbar } from '../Navbar';
import { Target } from './bin';

export interface Props {
  children?: React.ReactNode;
  sidebar?: boolean;
  alignment?: 'center' | 'left';
}

const loadingTransition: CSSTransitionAnimation = {
  duration: 500,
  delay: 400
};

function Shell(props: Props): JSX.Element {
  const { children, sidebar = true, alignment = 'left' } = props;

  const [showMobileMenu, setShowMobileMenu] = React.useState(false);

  const theme: Theme = useTheme();
  const window = useWindowSize();

  return (
    <Block position="relative" boxSizing="border-box">
      {isMobile(window, theme) && sidebar && (
        <Navbar
          onClick={() => setShowMobileMenu(true)}
          transition={loadingTransition}
          showMenuButton={sidebar}
        />
      )}
      <Flex>
        {sidebar && (
          <Sidebar
            show={showMobileMenu}
            onClose={() => setShowMobileMenu(false)}
            transition={loadingTransition}
          />
        )}
        <Target id="shell-target" alignment={alignment} transition={loadingTransition}>
          {children}
        </Target>
      </Flex>
    </Block>
  );
}

// Export the component as the default export
export default Shell;

import React from 'react';
import { Table, Flex, Block, Spacer, Button } from 'powder-ui';
import type { TableDataColumn } from 'powder-ui';
import { Expense } from '~/src/hooks/useStacksAPI';
import { RiPencilLine as EditIcon } from 'react-icons/ri';

import { columns as tableColumns } from './bin/config';

export interface Props {
  items: Expense[];
  hasErrors: boolean;
  onAddExpense: () => void;
  onEdit: () => void;
}

function ExpenseTable({ items, hasErrors, onAddExpense, onEdit }: Props): JSX.Element {
  return (
    <Flex column m="0px 0px 0px 0px" gap="4px">
      {items.length > 0 && (
        <Table.Table rows={items} columns={tableColumns} spacing={4} scroll={false}>
          {({ rows, columns, sortColumn, sortDirection, onSortColumn }) => (
            <>
              <Table.Header>
                <Table.Row>
                  {columns.map((column: TableDataColumn) => (
                    <Table.HeaderCell
                      key={column.key}
                      column={column}
                      align={column.align}
                      sortColumn={sortColumn !== null ? sortColumn : ''}
                      sortDirection={sortDirection}
                      onClick={() => onSortColumn(column.key)}
                    />
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {rows.map((row) => (
                  <Table.Row key={row.id}>
                    <Table.BodyCell align="left">{row.name}</Table.BodyCell>
                    <Table.BodyCell align="left">{row.description}</Table.BodyCell>
                    <Table.BodyCell align="right">$ {row.amount}.00</Table.BodyCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </>
          )}
        </Table.Table>
      )}
      <Spacer spacing={2} />
      <Block>
        <Button
          type="button"
          variation="secondary"
          intent={hasErrors ? 'danger' : undefined}
          text="Edit"
          width={76}
          icon={<EditIcon size="16px" />}
          onClick={() => {
            if (items.length === 0) onAddExpense();
            onEdit();
          }}
        />
      </Block>
    </Flex>
  );
}

export default ExpenseTable;

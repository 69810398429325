import React from 'react';
import { Skeleton } from 'powder-ui';

import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { Theme, useTheme } from 'styled-components';
import { Projection } from '~/src/hooks/useStacksAPI';
import { RateTooltip } from './bin';

interface Props {
  projection: Projection;
  skeleton?: boolean;
  metric?: 'roi' | 'capRate' | 'grossYield';
  name?: string;
}

export function YieldFuturesChart({ projection, skeleton, metric, name }: Props): JSX.Element {
  const theme: Theme = useTheme();
  const [key, setKey] = React.useState(Date.now());

  React.useEffect(() => {
    // Update the key when the data prop changes
    setKey(Date.now());
  }, [projection.analysis?.growth]);

  if (skeleton) {
    return <Skeleton type="box" height={180} fluid />;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={projection.analysis?.growth}
        key={key}
        margin={{
          top: 0,
          right: 2,
          left: 2,
          bottom: 0
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" interval={2} fontSize="6px" allowDataOverflow />
        <Tooltip content={RateTooltip} />
        <Legend wrapperStyle={{ fontSize: '10px', fontFamily: theme.fonts.family.mono }} />
        <Line
          type="monotone"
          name={name}
          dataKey={`annual.${metric}`}
          stroke={theme.palette.visualization[0]}
          dot={{ r: 2, fill: theme.palette.visualization[0] }}
          activeDot={{ r: 4, fill: theme.palette.visualization[0] }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

import React from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { ResetCSS, shouldForwardProp } from 'powder-ui';
import { useGlobalContext } from '~/src/contexts/global/context';
import GlobalStyles from './styles';

import LIGHT_THEME from './light';
import DARK_THEME from './dark';

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

export function ThemeContextProvider({ children }: Props): JSX.Element {
  const { state } = useGlobalContext();

  const getTheme = () => {
    if (state.theme === 'dark') return DARK_THEME;
    return LIGHT_THEME;
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ThemeProvider theme={getTheme()}>
        <ResetCSS />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyleSheetManager>
  );
}

/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { PowderTheme as Theme } from 'powder-ui';

// Example component Props
type MobileSidebarProps = object;

export interface ThemedProps extends MobileSidebarProps {
  theme: Theme;
}

export const MobileSidebar = styled.div<ThemedProps>`
  display: none;
  @media screen and (max-width: ${(props: ThemedProps) => props.theme.breakpoints.md}) {
    display: block;
  }
`;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import { Button, Flex } from 'powder-ui';
import { Link } from 'react-router-dom';

export interface Props {
  onLogout: () => void;
}

const NavItem = styled.div`
  button {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.family.mono};
    color: ${({ theme }) => theme.palette.foreground};
    border-bottom: solid 1px;
    border-radius: 0px;
    border-color: transparent;
    &:hover {
      border-bottom: solid 1px;
      border-color: ${({ theme }) => theme.palette.primary};
    }
  }
`;

function Menu({ onLogout }: Props): JSX.Element {
  return (
    <Flex column grow={1} justifyContent="flex-start" alignItems="center" gap="8px">
      <NavItem>
        <Link to="/dashboard">
          <Button type="button" variation="minimal" text="Dashboard" fixed />
        </Link>
      </NavItem>
      <NavItem>
        <Button type="button" variation="minimal" text="Logout" onClick={onLogout} fixed />
      </NavItem>
    </Flex>
  );
}

export default Menu;

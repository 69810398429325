import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  Block,
  Button,
  Flex,
  Fieldset,
  Legend,
  Typography,
  Spacer,
  Sidesheet,
  SidesheetHeader,
  Divider,
  FormMessage
} from 'powder-ui';
import { Portal } from '~/src/components/core/Portal';
import { Unit } from '~/src/hooks/useStacksAPI';
import { useFormikContext } from 'formik';
import { UnitsTable } from '~/src/components/core/UnitsTable';
import { RiCheckFill as CheckIcon, RiDeleteBin3Fill as DeleteIcon } from 'react-icons/ri';
import { investmentTypes, states } from '../../const';
import { SelectField } from '../../fields/SelectField';
import { TextField } from '../../fields/TextField';
import { InvestmentSchema } from './_schema';
import { CheckboxField } from '../../fields/CheckboxField';
import { PropertyIconSelector } from '../../fields/PropertyIconSelector';

function InvestmentFieldset(): JSX.Element {
  const [showSidesheet, setShowSidesheet] = React.useState(false);
  const { setFieldValue, values, errors } = useFormikContext<InvestmentSchema>();

  const { units } = values;

  const [activeUnit, setActiveUnit] = React.useState<number>(-1);

  const hasActiveUnit = () => activeUnit >= 0;

  const hasUnitErrors = () => 'units' in errors;

  const onEditUnit = (id: string | number) => {
    const unit = units.items.findIndex((u: Unit) => u.id === id);
    if (unit >= 0) {
      setActiveUnit(unit);
      setShowSidesheet(true);
    }
  };

  const onDeleteUnit = () => {
    setShowSidesheet(false);
    if (hasActiveUnit()) {
      const items = units.items.filter((item, index) => index !== activeUnit);
      setFieldValue('units', { items: [...items] }, false);
      setActiveUnit(-1);
    }
  };

  const onAddUnit = () => {
    const unit: Unit = {
      id: uuid(),
      name: `Unit ${units.items.length + 1}`,
      bedrooms: 0,
      bathroom: 0,
      sqft: 0,
      pets: false
    };
    setFieldValue('units', { items: [...units.items, unit] }, false);
  };

  return (
    <>
      <Fieldset gap="0px" indent={false}>
        <Flex gap="10px">
          <Block grow={4}>
            <TextField label="Address" name="address" />
          </Block>
          <Block grow={1}>
            <SelectField label="Type" name="type" options={investmentTypes} />
          </Block>
        </Flex>
        <Block>
          <PropertyIconSelector />
        </Block>
        <Flex gap="20px">
          <Block grow={1}>
            <TextField label="City" name="city" />
          </Block>
          <SelectField label="State" name="state" options={states} />
          <TextField label="Zip" name="zip" />
          <TextField label="Country" name="country" disabled />
        </Flex>
        <TextField label="Neighborhood" name="neighborhood" />
        <Fieldset gap="0px" border>
          <Legend>
            <Typography.H5>Rent</Typography.H5>
          </Legend>
          <UnitsTable items={units.items} onAddUnit={onAddUnit} onEditUnit={onEditUnit} />
        </Fieldset>
        <FormMessage
          touched={hasUnitErrors()}
          error="Some units have invalid data. Please review"
        />
        <Spacer spacing={8} />
        <Flex gap="20px">
          <TextField label="Total Square Ft." name="sqft" type="number" />
          <TextField label="Lot Size" name="lotSize" type="number" />
          <TextField label="Year Built" name="year" type="number" />
        </Flex>
      </Fieldset>
      <Portal>
        <Sidesheet show={showSidesheet} onClose={() => setShowSidesheet(false)}>
          <SidesheetHeader>
            <Typography.H4>Edit Unit</Typography.H4>
            <Divider />
          </SidesheetHeader>
          {hasActiveUnit() && (
            <Flex gap="16px" wrap="wrap" p="16px 0px">
              <Block width="100%">
                <TextField
                  animated
                  condensed
                  name={`units.items[${activeUnit}].name`}
                  label="Name"
                />
              </Block>
              <Block grow={1} width="80px">
                <TextField
                  animated
                  condensed
                  name={`units.items[${activeUnit}].bedrooms`}
                  label="Bedrooms"
                  type="number"
                />
              </Block>
              <Block grow={1} width="80px">
                <TextField
                  animated
                  condensed
                  name={`units.items[${activeUnit}].bathroom`}
                  label="Bathrooms"
                  type="number"
                />
              </Block>
              <Block grow={1} width="80px">
                <TextField
                  animated
                  condensed
                  name={`units.items[${activeUnit}].sqft`}
                  label="Sqft."
                  type="number"
                />
              </Block>
              <CheckboxField
                condensed
                name={`units.items[${activeUnit}].pets`}
                value="enabled"
                label="Pets"
                checked={values.units.items[activeUnit].pets}
              />
            </Flex>
          )}
          <Flex gap="8px">
            <Button
              icon={<CheckIcon size="16px" />}
              text="Done"
              disabled={hasUnitErrors()}
              onClick={() => setShowSidesheet(false)}
            />
            <Button
              icon={<DeleteIcon size="16px" />}
              text="Delete"
              intent="danger"
              disabled={hasUnitErrors()}
              onClick={() => onDeleteUnit()}
            />
          </Flex>
        </Sidesheet>
      </Portal>
    </>
  );
}

export default InvestmentFieldset;

import React from 'react';
import { Skeleton, Block, Card, Flex, Typography } from 'powder-ui';
import { Investment } from '~/src/hooks/useStacksAPI';
import { Numeric } from '~/src/components/core/Numeric';

interface Props {
  skeleton: boolean;
  investment: Investment;
}

export function MarketValue({ investment, skeleton }: Props): JSX.Element {
  const rentcast = investment.meta.data?.rentcast;
  if (skeleton || !rentcast) {
    return (
      <Block grow={1} basis={200} height="100px">
        <Skeleton type="box" height={100} fluid />
      </Block>
    );
  }

  const getMarketValue = () => {
    const marketValue = investment.meta.data?.marketValue.priceRangeHigh;
    if (marketValue) {
      // Artifically inflate market values (estimates are low compared to zillow *_*!)
      const MARGIN = 25000.0;
      return marketValue + MARGIN;
    }
    return '--';
  };

  return (
    <Block grow={1} shrink={0} basis="320px" height="100px">
      <Card height="100%">
        <Flex height="100%" gap="20px" justifyContent="center">
          <Flex height="100%" column alignItems="center" justifyContent="center">
            <Typography.Mono condensed weight={700} size="20px">
              <Numeric number={getMarketValue()} currency />
            </Typography.Mono>
            <Typography.H6>Estimated Value</Typography.H6>
          </Flex>
        </Flex>
      </Card>
    </Block>
  );
}

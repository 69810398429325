/* eslint-disable react/prop-types */
import React from 'react';
import { reducer, initialState } from './reducer';

import { State, Action, Reducer, Context } from './types';

const GlobalContext = React.createContext({
  state: initialState as State,
  dispatch: () => {}
} as Context);

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export function GlobalContextProvider(props: Props) {
  const { children } = props;
  const [state, dispatch] = React.useReducer<Reducer<State, Action>>(reducer, initialState);
  const value = React.useMemo(() => ({ dispatch, state }), [dispatch, state]);

  // Persist state to local storage
  React.useEffect(() => {
    localStorage.setItem('auth-token', JSON.stringify(state.authToken));
    localStorage.setItem('user', JSON.stringify(state.user));
    localStorage.setItem('theme', JSON.stringify(state.theme));
  }, [state.authToken, state.user, state.theme]);

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
}

export const useGlobalContext = (): Context => {
  const { state, dispatch } = React.useContext(GlobalContext);
  return { state, dispatch };
};

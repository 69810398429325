import { keyframes } from 'styled-components';

const bubble = keyframes`

  0%, 50% {
    transform: translateY(100px);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }

`;

export default { bubble };

import React from 'react';
import { Flex, Block, Fieldset, Legend, Typography, Spacer, Skeleton } from 'powder-ui';
import { RangeField } from '~/src/components/forms/fields/RangeField';

interface Props {
  skeleton?: boolean;
  disabled?: boolean;
}

function SaleFieldset({ skeleton, disabled = false }: Props): JSX.Element {
  return (
    <Fieldset gap="0px" indent={false}>
      <Legend indent={false}>
        {skeleton ? (
          <Skeleton type="box" height={22} width={160} />
        ) : (
          <Typography.H5>Management & Upkeep</Typography.H5>
        )}
      </Legend>
      <Spacer spacing={6} />
      <Flex row gap="16px" justifyContent="space-between" wrap="wrap" width="100%">
        <Block grow={1} width="280px">
          <RangeField
            skeleton={skeleton}
            type="percent"
            name="maintenance"
            label="Maintenance"
            min={0}
            max={20}
            units="%"
            step={1}
            help="The annual maintenance costs, as a ratio to the property value"
            disabled={disabled}
            indicatorSize={48}
          />
        </Block>
        <Block grow={1} width="280px">
          <RangeField
            skeleton={skeleton}
            type="percent"
            name="vacancyRate"
            label="Vacancy Rate"
            min={0}
            max={20}
            units="%"
            step={1}
            help="Amount of time units will be unoccupied"
            disabled={disabled}
            indicatorSize={48}
          />
        </Block>
        <Block grow={1} width="280px">
          <RangeField
            skeleton={skeleton}
            type="percent"
            name="managementFee"
            label="Property Management"
            min={0}
            max={20}
            units="%"
            step={1}
            help="The annual management costs, as a ratio to the property value"
            disabled={disabled}
            indicatorSize={48}
          />
        </Block>
      </Flex>
      <Spacer spacing={8} />
    </Fieldset>
  );
}

export default SaleFieldset;

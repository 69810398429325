import React from 'react';
import { Flex, Card, Typography, Scale, Block } from 'powder-ui';
import { Investment, Projection } from '~/src/hooks/useStacksAPI';
import { InvestmentCard } from '~/src/components/core/InvestmentCard';
import { Theme, useTheme } from 'styled-components';
import { ProjectionOverviewChart } from '~/src/components/core/Charts';
import { Numeric } from '~/src/components/core/Numeric';
import { RiBarChart2Line, RiCurrencyLine } from 'react-icons/ri';
import { StatBlock } from '~/src/components/core/StatBlock';
import { Statistic } from '~/src/components/core/Statistic';

export interface Props {
  investment: Investment;
  projection: Projection;
  isLoading: boolean;
}

export function Overview({ investment, projection, isLoading }: Props): JSX.Element {
  const theme: Theme = useTheme();
  return (
    <Scale shrink={0} xs="100%" sm="100%" md="100%" lg="300px" xl="300px">
      <Flex column gap="8px">
        <InvestmentCard investment={investment} mini />
        <Card p="8px 8px" background={{ color: theme.palette.neutral[5] }}>
          <Flex column gap="8px">
            <Block p="0px 4px">
              <Typography.H4 size="12px" lineHeight="14px" weight={200}>
                Projection Overview
              </Typography.H4>
            </Block>
            <ProjectionOverviewChart skeleton={isLoading} projection={projection} />
            <Flex wrap="wrap" gap="8px">
              <StatBlock
                skeleton={isLoading}
                label="Cash on Cash (ROI)"
                type="primary"
                icon={<RiCurrencyLine size="16px" />}
              >
                <Numeric number={projection.analysis?.yield?.roi} rate units="%" precision={1} />
              </StatBlock>
              <StatBlock
                skeleton={isLoading}
                label="Capitalization Rate"
                type="accent"
                icon={<RiBarChart2Line size="16px" />}
              >
                <Numeric
                  number={projection.analysis?.yield?.capRate}
                  rate
                  units="%"
                  precision={1}
                />
              </StatBlock>
              <Scale xs="100%" sm="140px" md="140px" lg="100%" xl="100%">
                <Flex wrap="wrap" gap="8px" justifyContent="space-between">
                  <StatBlock skeleton={isLoading} size="small">
                    <Statistic
                      label="Gross Yield"
                      rate
                      stat={projection.analysis?.yield?.grossYield}
                      units="%"
                    />
                  </StatBlock>
                  <StatBlock skeleton={isLoading} size="small">
                    <Statistic
                      label="Payback Years"
                      stat={projection.analysis?.yield?.paybackYears}
                    />
                  </StatBlock>
                </Flex>
              </Scale>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </Scale>
  );
}

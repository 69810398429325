import React from 'react';
import { v4 as uuid } from 'uuid';
import { Flex, Scale, Button, FormMessage, Spacer, Block } from 'powder-ui';
import { TextField } from '~/src/components/forms/fields/TextField';
import { RiAddFill as AddIcon } from 'react-icons/ri';
import { Form, Formik, FormikHelpers } from 'formik';
import { Header } from '~/src/components/core/Header';
import {
  ProjectionSchema,
  schema
} from '~/src/components/forms/fieldsets/ProjectionFieldsets/_schema';
import SaleFieldset from '~/src/components/forms/fieldsets/ProjectionFieldsets/SaleFieldset/SaleFieldset';
import ExpenseFieldset from '~/src/components/forms/fieldsets/ProjectionFieldsets/ExpenseFieldset/ExpenseFieldset';
import ManagementFieldset from '~/src/components/forms/fieldsets/ProjectionFieldsets/ManagementFieldset/ManagementFieldset';
import GrowthFieldset from '~/src/components/forms/fieldsets/ProjectionFieldsets/GrowthFieldset/GrowthFieldset';
import RentFieldset from '~/src/components/forms/fieldsets/ProjectionFieldsets/RentFieldset/RentFieldset';
import { useOnce } from '~/src/hooks/useOnce';
import { Investment, Projection, isSuccessPayload, useStacksAPI } from '~/src/hooks/useStacksAPI';
import { useLoadingTransition } from '~/src/hooks/useLoadingTransition/useLoadingTransition';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Nullable } from '~/src/types';
import { ErrorAlert, SuccessAlert } from '~/src/components/core/Alerts';
import { addAlert } from '~/src/contexts/global/actions';
import { useGlobalContext } from '~/src/contexts/global/context';

function CreateProjectionView() {
  /** --------------- */
  const { dispatch } = useGlobalContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [investment, setInvestment] = React.useState<Nullable<Investment>>(null);
  const { getInvestment, createProjection } = useStacksAPI();

  const [urlParams] = useSearchParams();

  const { isViewLoading } = useLoadingTransition(isLoading, 2000);

  const loadView = async () => {
    const id = urlParams.get('investment') || '1234';
    const { payload } = await getInvestment({ params: { id } });
    if (isSuccessPayload(payload)) {
      const { data } = payload;
      setInvestment(data);
      setIsLoading(false);
    } else {
      navigate('/error', { state: payload });
    }
  };

  useOnce(() => {
    loadView();
  }, [loadView]);

  const onFormSubmit = async (values: ProjectionSchema, actions: FormikHelpers<Projection>) => {
    const projection = { ...values, investment: investment?.id };
    const { payload } = await createProjection({ data: projection });
    if (isSuccessPayload(payload)) {
      const alert = SuccessAlert(payload.data.id, {
        title: 'Success',
        message: 'Projection created successfully'
      });
      addAlert(dispatch, { alert });
      navigate(`/projection/${payload.data.id}`);
    } else {
      const alert = ErrorAlert(uuid(), {
        title: 'Unexpected Error',
        message: payload.message
      });
      addAlert(dispatch, { alert });
      setTimeout(() => {
        actions.setSubmitting(false);
      }, 1000);
    }
  };

  const hasError = (isValid: boolean, submitCount: number) => !isValid && submitCount > 0;

  return (
    <Flex column gap="10px" width="100%">
      <Header text="Create New Projection" back skeleton={isViewLoading} />
      <Formik
        validationSchema={schema.validation}
        initialValues={schema.init()}
        onSubmit={onFormSubmit}
      >
        {({ isValid, submitCount, isSubmitting, values, errors }) => {
          return (
            <Form>
              <Scale xs="100%" sm="100%" md="100%" lg="100%" xl="90%">
                <Block>
                  <Block width="50%">
                    <TextField skeleton={isViewLoading} label="Title" name="title" />
                  </Block>
                  <SaleFieldset skeleton={isViewLoading} />
                  <ExpenseFieldset
                    skeleton={isViewLoading}
                    title="Closing Costs"
                    id="closingCosts"
                    values={values.closingCosts}
                    hasErrors={() => !!('closingCosts' in errors)}
                    defaultExpense={{
                      id: uuid(),
                      name: 'Closing Cost',
                      description: '---',
                      amount: 0
                    }}
                    help="The costs to close on the property sale and secure financing"
                  />
                  <ExpenseFieldset
                    skeleton={isViewLoading}
                    title="Seed Funding"
                    id="seedFunding"
                    values={values.seedFunding}
                    hasErrors={() => !!('seedFunding' in errors)}
                    defaultExpense={{
                      id: uuid(),
                      name: 'Seed Funding',
                      description: '---',
                      amount: 0
                    }}
                    help="The initial costs to get the property up and running"
                  />
                  <RentFieldset units={investment?.units} skeleton={isViewLoading} />
                  <ManagementFieldset skeleton={isViewLoading} />
                  <ExpenseFieldset
                    skeleton={isViewLoading}
                    title="Utilities"
                    id="utilities"
                    values={values.utilities}
                    hasErrors={() => !!('utilities' in errors)}
                    defaultExpense={{
                      id: uuid(),
                      name: 'Utility',
                      description: '---',
                      amount: 0
                    }}
                    help="The monthly utilities not paid directly by the tenant"
                  />
                  <ExpenseFieldset
                    skeleton={isViewLoading}
                    title="Fees"
                    id="fees"
                    values={values.fees}
                    hasErrors={() => !!('fees' in errors)}
                    defaultExpense={{
                      id: uuid(),
                      name: 'Misc. Fee',
                      description: '---',
                      amount: 0
                    }}
                    help="Other misc.fees not already included, such as an HOA "
                  />
                  <GrowthFieldset skeleton={isViewLoading} />
                  <Spacer spacing={8} />
                  <Button
                    type="submit"
                    width={210}
                    skeleton={isSubmitting || isViewLoading}
                    intent={hasError(isValid, submitCount) ? 'danger' : undefined}
                    iconSize="sm"
                    icon={<AddIcon size="16px" />}
                    text="Create Projection"
                  />
                </Block>
                <FormMessage
                  touched={hasError(isValid, submitCount)}
                  error="Please review the form for errors"
                />
              </Scale>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default CreateProjectionView;

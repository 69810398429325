import React from 'react';
import styled from 'styled-components';
import { Flex, Block } from 'powder-ui';
import { ThemeToggle } from '~/src/components/core/ThemeToggle';
import Header from './bin/Header';
import Hero from './bin/Hero';
import Features from './bin/Features';
import ProjectTimeline from './bin/ProjectTimeline';

import BackgroundAnimation from './assets/wavesBg.svg';
import DemoForm from './bin/DemoForm';

const Background = styled.div`
  z-index: -1;
  position: absolute;
  top: -400px;
  left: 0px;
  right: 0px;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  img {
    transform: rotate(-5deg) translateX(-100px);
  }
`;

const Container = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`;

function HomepageView() {
  return (
    <Container>
      <Flex column position="relative">
        <Background>
          <img src={BackgroundAnimation} alt="background" />
        </Background>
        <Header />
        <Hero />
        <Features />
        <ProjectTimeline />
        <DemoForm />
        <Block position="fixed" right="60px" bottom="-10px">
          <ThemeToggle />
        </Block>
      </Flex>
    </Container>
  );
}

export default HomepageView;

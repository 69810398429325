/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { Card, Flex, Spacer, Block, Label } from 'powder-ui';
import { PROPERTY_ICON_MAP } from '~/src/components/core/PropertyIcons';
import styled, { useTheme } from 'styled-components';
import { useField } from 'formik';
import { PropertyIconKey } from '~/src/hooks/useStacksAPI';

const CardSelector = styled.button`
  padding: 0px;
  cursor: pointer;
  border: none;
  background: transparent;
`;

export function PropertyIconSelector() {
  const [field, meta, helpers] = useField<PropertyIconKey>('icon');
  const { setValue } = helpers;

  const { palette } = useTheme();
  const [selected, setSelected] = React.useState<PropertyIconKey>(field.value);

  /**
   * Sync selected state to formik context
   */
  React.useEffect(() => {
    setValue(selected);
  }, [selected, setValue]);

  const icons: Array<PropertyIconKey> = [
    'house-1',
    'house-2',
    'house-3',
    'house-4',
    'house-5',
    'house-6',
    'house-7',
    'house-8',
    'apt-1',
    'apt-2',
    'manufactured-1'
  ];

  const getBorder = (icon: PropertyIconKey) => {
    if (icon === selected) return `solid 1px ${palette.primary.main}`;
    return undefined;
  };

  const getBackground = (icon: PropertyIconKey) => {
    if (icon === selected) return { color: palette.neutral[5] };
    return { color: palette.background };
  };

  return (
    <Block width="100%">
      <Label value="Select Icon" htmlFor="property-icon" />
      <Flex width="100%" m="10px 0px" gap="5px" wrap="wrap">
        {icons.map((icon) => (
          <Block key={icon} shrink={0}>
            <CardSelector type="button" onClick={() => setSelected(icon)}>
              <Card
                height="80px"
                width="80px"
                border={getBorder(icon)}
                background={getBackground(icon)}
              >
                <Flex justifyContent="center">
                  <img height="60px" src={PROPERTY_ICON_MAP[icon]} alt="1" />
                </Flex>
              </Card>
            </CardSelector>
          </Block>
        ))}
      </Flex>
      <Spacer spacing={4} />
    </Block>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { useFormikContext } from 'formik';
import { debounce } from 'powder-ui';
import { ProjectionSchema } from '../../../forms/fieldsets/ProjectionFieldsets/_schema';

/**
 * Utility component which listens to formik state changeså
 */

interface Props {
  onFormChange: (
    schema: ProjectionSchema,
    isValid: boolean,
    isDirty: boolean,
    resetForm: (s?: object) => void
  ) => void;
}

export function ProjectionListener({ onFormChange }: Props): null {
  const { values, isValid, dirty, resetForm } = useFormikContext();

  const onDebouncedChange = React.useCallback(
    debounce((a, b, c, d) => {
      onFormChange(a, b, c, d);
    }, 600),
    [onFormChange]
  );

  React.useEffect(() => {
    onDebouncedChange(values, isValid, dirty, resetForm);
  }, [values, isValid, dirty, onDebouncedChange]);

  return null;
}

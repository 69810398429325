import React from 'react';

// Args
type Args = boolean;

// Payload
type Payload = boolean;

// Action
export type ClearAlert = {
  type: 'CLEAR_ALERT';
  payload: Payload;
};

export const clearAlerts = (dispatch: React.Dispatch<ClearAlert>, args: Args): void =>
  dispatch({
    type: 'CLEAR_ALERT',
    payload: args
  });

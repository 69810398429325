import React from 'react';
import { Card, Skeleton } from 'powder-ui';

import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  YAxis,
  ReferenceLine
} from 'recharts';
import { Theme, useTheme } from 'styled-components';
import { Projection } from '~/src/hooks/useStacksAPI';
import { CurrencyTooltip } from './bin';

interface Props {
  projection: Projection;
  skeleton?: boolean;
}

export function TotalGrowthChart({ projection, skeleton }: Props): JSX.Element {
  const theme: Theme = useTheme();
  const [key, setKey] = React.useState(Date.now());

  React.useEffect(() => {
    // Update the key when the data prop changes
    setKey(Date.now());
  }, [projection.analysis?.growth]);

  if (skeleton) {
    return <Skeleton type="box" height={480} fluid />;
  }

  return (
    <Card width="100%" height="480px" background={{ color: theme.palette.background }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={projection.analysis?.growth}
          key={key}
          margin={{
            top: 8,
            right: 2,
            left: 0,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis
            domain={['auto', 'auto']}
            fontSize="8px"
            tickCount={8}
            type="number"
            tickFormatter={(value) => `$ ${(value / 1000).toFixed(0)}K`}
          />
          <XAxis dataKey="year" interval={2} fontSize="8px" allowDataOverflow />
          <Tooltip content={CurrencyTooltip} />
          <Legend wrapperStyle={{ fontSize: '12px', fontFamily: theme.fonts.family.mono }} />
          <ReferenceLine y={0} stroke="red" strokeDasharray="3 3" />
          <Line
            type="monotone"
            name="Total Return"
            dataKey="total.totalReturn"
            stroke={theme.palette.visualization[2]}
            dot={{ r: 2, fill: theme.palette.visualization[2] }}
            activeDot={{ r: 6, fill: theme.palette.visualization[2] }}
          />
          <Line
            type="monotone"
            name="Liquid Assets"
            dataKey="total.liquidAssets"
            stroke={theme.palette.visualization[1]}
            dot={{ r: 2, fill: theme.palette.visualization[1] }}
            activeDot={{ r: 6, fill: theme.palette.visualization[1] }}
          />
          <Line
            type="monotone"
            name="Property Value"
            dataKey="total.propertyValue"
            stroke={theme.palette.visualization[3]}
            dot={{ r: 2, fill: theme.palette.visualization[3] }}
            activeDot={{ r: 6, fill: theme.palette.visualization[3] }}
          />
          <Line
            type="monotone"
            name="Equity"
            dataKey="total.equity"
            stroke={theme.palette.visualization[4]}
            dot={{ r: 2, fill: theme.palette.visualization[4] }}
            activeDot={{ r: 6, fill: theme.palette.visualization[4] }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
}

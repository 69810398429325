import React from 'react';
import { v4 as uuid } from 'uuid';
import { useFormikContext } from 'formik';
import { Block, Flex, Scroll, Divider, Spacer, Typography, Card } from 'powder-ui';
import { Projection } from '~/src/hooks/useStacksAPI';

import { Statistic } from '~/src/components/core/Statistic';
import { Theme, useTheme } from 'styled-components';
import RentFieldset from '~/src/components/forms/fieldsets/ProjectionFieldsets/RentFieldset/RentFieldset';
import ManagementFieldset from '~/src/components/forms/fieldsets/ProjectionFieldsets/ManagementFieldset/ManagementFieldset';
import { Chip } from '~/src/components/core/Chip';
import { Numeric } from '~/src/components/core/Numeric';
import ExpenseFieldset from '~/src/components/forms/fieldsets/ProjectionFieldsets/ExpenseFieldset/ExpenseFieldset';
import { ProjectionSchema } from '~/src/components/forms/fieldsets/ProjectionFieldsets/_schema';

import { ExpensePieChart } from '~/src/components/core/Charts/ExpensePieChart';
import { CashflowFuturesChart } from '~/src/components/core/Charts/CashflowFuturesChart';

export interface Props {
  projection: Projection;
  isLoading?: boolean;
  isInView?: boolean;
}

export function CashflowAnalysis({ projection, isLoading, isInView }: Props): JSX.Element {
  const theme: Theme = useTheme();
  const { values, errors } = useFormikContext<ProjectionSchema>();

  if (!isInView) {
    return <Block height="800px" />;
  }

  return (
    <Flex column>
      <Scroll height="80px" width="100%" direction="x">
        <Flex gap="16px" alignItems="center" wrap="nowrap" justifyContent="flex-start">
          <Statistic
            label="Annual Cashflow"
            currency
            stat={projection?.analysis?.cashflow?.net}
            skeleton={isLoading}
            magnitude="M"
          />
          <Block grow={0}>
            <Divider type="vertical" height="50px" color={theme.palette.neutral[4]} />
          </Block>
          <Statistic
            label="Annual Revenue"
            currency
            stat={projection?.analysis?.cashflow?.revenue}
            skeleton={isLoading}
            magnitude="M"
          />
          <Block grow={0}>
            <Divider type="vertical" height="50px" color={theme.palette.neutral[4]} />
          </Block>
          <Statistic
            label="Annual Expenses"
            currency
            stat={projection?.analysis?.cashflow?.expenses.total}
            skeleton={isLoading}
            magnitude="M"
          />
        </Flex>

        <Flex />
      </Scroll>
      <ManagementFieldset skeleton={false} disabled={isLoading} />
      <ExpenseFieldset
        title="Utilities"
        id="utilities"
        values={values.utilities}
        hasErrors={() => !!('utilities' in errors)}
        defaultExpense={{
          id: uuid(),
          name: 'Utility',
          description: '---',
          amount: 0
        }}
        help="The monthly utilities not paid directly by the tenant"
      />
      <Flex gap="8px">
        <Flex column gap="8px" shrink={0} grow={0} width="160px">
          <Chip skeleton={isLoading} label="Vacancy">
            <Numeric
              number={projection.analysis?.cashflow?.expenses.vacancy}
              currency
              magnitude="M"
            />
          </Chip>
          <Chip skeleton={isLoading} label="Maintenance">
            <Numeric
              number={projection.analysis?.cashflow?.expenses.maintenance}
              currency
              magnitude="M"
            />
          </Chip>
          <Chip skeleton={isLoading} label="Management">
            <Numeric
              number={projection.analysis?.cashflow?.expenses.management}
              currency
              magnitude="M"
            />
          </Chip>
          <Chip skeleton={isLoading} label="Property Taxes">
            <Numeric
              number={projection.analysis?.cashflow?.expenses.taxes}
              currency
              magnitude="M"
            />
          </Chip>
          <Chip skeleton={isLoading} label="Utilties">
            <Numeric
              number={projection.analysis?.cashflow?.expenses.utilities}
              currency
              magnitude="M"
            />
          </Chip>
          <Chip skeleton={isLoading} label="Fees">
            <Numeric number={projection.analysis?.cashflow?.expenses.fees} currency magnitude="M" />
          </Chip>
          <Chip skeleton={isLoading} label="Principal">
            <Numeric
              number={projection.analysis?.cashflow?.expenses.principal}
              currency
              magnitude="M"
            />
          </Chip>
          <Chip skeleton={isLoading} label="Interest">
            <Numeric
              number={projection.analysis?.cashflow?.expenses.interest}
              currency
              magnitude="M"
            />
          </Chip>
        </Flex>
        <Card height="520px" width="100%">
          <ExpensePieChart skeleton={isLoading} projection={projection} />
        </Card>
      </Flex>
      <Spacer spacing={4} />
      <RentFieldset skeleton={false} disabled={isLoading} />
      <Typography.H5>Cashflow Projections</Typography.H5>
      <Spacer spacing={2} />
      <Card width="100%" height="480px" background={{ color: theme.palette.background }}>
        <CashflowFuturesChart skeleton={isLoading} projection={projection} />
      </Card>
      <Spacer spacing={4} />
    </Flex>
  );
}

import React from 'react';
import { useTheme, Theme } from 'styled-components';
import { Link } from 'react-router-dom';
import { Card, Flex, Typography, Block, Button } from 'powder-ui';

export function NoResults(): JSX.Element {
  const { palette }: Theme = useTheme();
  return (
    <Card width="40%" m="40px auto" p="40px" background={{ color: palette.neutral[5] }}>
      <Flex column gap="4px" alignItems="center" justifyContent="center">
        <Typography.Body align="center">
          Let&apos;s get started! Click the button below to create your first investment property
          projection.
        </Typography.Body>
        <Block>
          <Link to="/investment/new">
            <Button type="button" text="Get Started" />
          </Link>
        </Block>
      </Flex>
    </Card>
  );
}

import React from 'react';
import { Select } from 'powder-ui';
import { useField } from 'formik';

type Props = {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  value?: string | null;
  placeholder?: string;
  hideLabel?: boolean;
  animated?: boolean;
  skeleton?: boolean;
  disabled?: boolean;
  touched?: boolean;
  error?: string;
  help?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
};

export function SelectField(props: Props) {
  const { name, value, onBlur = () => {}, onChange = () => {}, ...baseProps } = props;

  const [field, meta] = useField<any>(name);

  const { error, touched } = meta;

  const onInputBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
    field.onBlur(e);
    onBlur(e);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    field.onChange(e);
    onChange(e);
  };

  return (
    <Select
      name={field.name}
      value={field.value}
      onBlur={onInputBlur}
      onChange={onInputChange}
      error={error}
      touched={touched}
      {...baseProps}
    />
  );
}

import House1Icon from '~/src/assets/property-icons/house-1.png';
import House2Icon from '~/src/assets/property-icons/house-2.png';
import House3Icon from '~/src/assets/property-icons/house-3.png';
import House4Icon from '~/src/assets/property-icons/house-4.png';
import House5Icon from '~/src/assets/property-icons/house-5.png';
import House6Icon from '~/src/assets/property-icons/house-6.png';
import House7Icon from '~/src/assets/property-icons/house-7.png';
import House8Icon from '~/src/assets/property-icons/house-8.png';
import Apt1Icon from '~/src/assets/property-icons/apt-1.png';
import Apt2Icon from '~/src/assets/property-icons/apt-2.png';
import Manufactured1Icon from '~/src/assets/property-icons/manufactured-1.png';

export const PROPERTY_ICON_MAP = {
  'house-1': House1Icon,
  'house-2': House2Icon,
  'house-3': House3Icon,
  'house-4': House4Icon,
  'house-5': House5Icon,
  'house-6': House6Icon,
  'house-7': House7Icon,
  'house-8': House8Icon,
  'apt-1': Apt1Icon,
  'apt-2': Apt2Icon,
  'manufactured-1': Manufactured1Icon
};

import React from 'react';
import { Sidesheet, SidesheetHeader, Typography, Divider, Flex, Button } from 'powder-ui';
import { RiAddFill as AddIcon, RiCheckFill as CheckIcon } from 'react-icons/ri';

import { Portal } from '../../../../../core/Portal';

export interface Props {
  children: React.ReactNode;
  show: boolean;
  title: string;
  onClose: () => void;
  onAddItem: () => void;
}

export function LineItemsSheet({ children, title, show, onClose, onAddItem }: Props): JSX.Element {
  return (
    <Portal>
      <Sidesheet show={show} onClose={onClose}>
        <SidesheetHeader>
          <Typography.H4>{title}</Typography.H4>
          <Divider />
        </SidesheetHeader>
        {children}
        <Divider spacing={{ top: '16px', bottom: '16px' }} />
        <Flex p="0px 2px" gap="4px">
          <Button
            type="button"
            variation="secondary"
            text={`Add ${title}`}
            onClick={() => onAddItem()}
            icon={<AddIcon size="16px" />}
          />
          <Button
            type="button"
            text="Done"
            icon={<CheckIcon size="16px" />}
            onClick={() => onClose()}
          />
        </Flex>
      </Sidesheet>
    </Portal>
  );
}

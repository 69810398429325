// theme.ts
import { PowderThemes, type PowderTheme } from 'powder-ui';

const THEME: PowderTheme = {
  ...PowderThemes.default,
  mode: 'dark',
  name: 'Pad UI Dark',
  fonts: {
    family: {
      display: 'IBM Plex Sans',
      body: 'IBM Plex Sans',
      mono: 'IBM Plex Mono'
    },
    weight: {
      light: 300,
      normal: 400,
      semibold: 600,
      bold: 700
    },
    size: {
      xsmall: 12,
      small: 14,
      normal: 16,
      large: 20,
      subtitle: 24,
      title: 36
    }
  },
  palette: {
    primary: {
      // 600
      main: '#5945e6',
      contrastText: '#FFFFFF',
      // 100, 300, 500, 700, 900
      shades: ['#dde1ff', '#9ca3ff', '#6555ff', '#4b29d9', '#342689']
    },
    accent: {
      // 600
      main: '#a0559e',
      contrastText: '#FFFFFF',
      // 100, 300, 500, 700, 900
      shades: ['#f2e7ff', '#d5b1ff', '#a14dff', '#8221e4', '#55178c']
    },
    success: {
      main: '#22c52b',
      contrastText: '#FFFFFF',
      shades: ['#bbf7bd', '#16a31e', '#16651b']
    },
    warning: {
      main: '#ff9500',
      contrastText: '#FFFFFF',
      shades: ['#ffe485', '#ce9600', '#983908']
    },
    danger: {
      main: '#ff2d2d',
      contrastText: '#FFFFFF',
      shades: ['#ff2d2d', '#ff2d2d', '#ff2d2d']
    },
    base: '#000000',
    background: '#202328',
    foreground: '#FFFFFF',
    grey: '#C4C4C4',
    lightgrey: '#EDEDED',
    // 50, 200, 400, 600, 800, 950
    neutral: ['#f7f8f8', '#d8dbdf', '#8e95a2', '#5a616f', '#3f434d', '#151619'],
    visualization: ['#64B1E3', '#1F78B4', '#22B9B4', '#896FCE', '#DB84D9', '#944687']
  },
  border: {
    radius: {
      sm: '4px',
      lg: '18px'
    },
    width: '1px'
  },
  breakpoints: {
    xs: '400px',
    sm: '720px',
    md: '960px',
    lg: '1380px',
    xl: '1600px'
  },
  skeleton: {
    colors: ['#212b32', '#394851'],
    animation: 'wave'
  },
  forms: {
    outline: '0px 0px 0px 2px'
  }
};

export default THEME;

/* eslint-disable prettier/prettier */

import styled from 'styled-components';
import { PowderTheme as Theme, CSSTransitionAnimation } from 'powder-ui';
import animations from './animations';

type DesktopSidebarProps = {
  transition: CSSTransitionAnimation;
};

export interface ThemedProps extends DesktopSidebarProps {
  theme: Theme;
}

export const DesktopSidebar = styled.div<ThemedProps>`
  height: 100vh;
  width: 180px;
  box-sizing: border-box;
  padding: 20px 10px;
  top: 0px;
  left: 0px;
  flex-shrink: 0;
  transition:
    background-color,
    border-color 0.2s ease-in-out;
  background-color: ${(props: ThemedProps) => props.theme.palette.neutral[5]};
  border-right: solid 1px ${(props: ThemedProps) => props.theme.palette.neutral[5]};
  transform: translateX(-500px);
  animation-name: ${animations.slideInRight};
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-duration: ${({ transition }) => transition.duration}ms;
  animation-delay: ${({ transition }) => transition.delay}ms;
  @media screen and (max-width: ${({ theme }: ThemedProps) => theme.breakpoints.md}) {
    display: none;
  }
`;

/* eslint-disable jsx-a11y/tabindex-no-positive */

import React from 'react';
import { Formik, Form } from 'formik';
import { Flex, Button, Typography, Block, Spacer, FormMessage } from 'powder-ui';
import { TextField } from '~/src/components/forms/fields/TextField';
import { User, useStacksAPI } from '~/src/hooks/useStacksAPI';
import { useNavigate } from 'react-router-dom';
import { setUser } from '~/src/contexts/global/actions/setUser';
import { useGlobalContext } from '~/src/contexts/global/context';
import { LoginUser } from '~/src/hooks/useStacksAPI/types/endpoints/user';
import DefaultLogo from '../../../assets/logo-default.svg';
import WhiteLogo from '../../../assets/logo-white.svg';

type LoginFormData = {
  username: string;
  password: string;
};

function LoginView(): JSX.Element {
  /** ------------- */

  const { dispatch, state } = useGlobalContext();
  const values: LoginFormData = { username: '', password: '' };
  const [error, setError] = React.useState<string | undefined>();
  const { loginUser, setAuthToken } = useStacksAPI();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (state.user && state.authToken) {
      navigate('/dashboard');
    }
  }, [state, navigate]);

  const onFormSubmit = async (data: LoginFormData) => {
    const { payload } = await loginUser({ data });
    if (!payload.success) setError(payload.message);
    else {
      const user: User = payload.data;
      const meta = payload.meta as LoginUser.Meta;
      // Set the user to global state
      setUser(dispatch, { user, token: meta.token });
      // Set the API client auth token
      setAuthToken(meta.token);
    }
  };

  const onFormValidate = (data: LoginFormData) => {
    const errors: any = {};
    if (data.username.length < 3) errors.username = 'Please enter a valid username';
    if (data.password.length < 5) errors.password = 'Please enter a valid username';
    return errors;
  };

  const Logo = state.theme === 'dark' ? WhiteLogo : DefaultLogo;

  return (
    <Flex position="relative" column alignItems="center" p="20% 0px 0px 0px" gap="10px">
      <Block position="absolute" top="20px">
        <img src={Logo} alt="PadStack" height="20px" />
      </Block>
      <Typography.H2>Welcome back!</Typography.H2>
      <Spacer spacing={4} />
      <Formik
        initialValues={values}
        validateOnChange
        onSubmit={onFormSubmit}
        validate={onFormValidate}
      >
        <Form>
          <Flex column alignItems="center">
            <Block width="400px">
              <TextField autoFocus name="username" label="Username" type="text" animated />
              <Spacer spacing={2} />
              <TextField name="password" label="Password" type="password" animated />
            </Block>
            <Spacer spacing={4} />
            <p>
              <Typography.Link href="/book-demo" size="12px" tabIndex={-1}>
                Don&apos;t have an account?
              </Typography.Link>
            </p>
            <Spacer spacing={4} />
            <Button
              type="submit"
              text="Sign In"
              variation="default"
              width={100}
              alignment="center"
              tabIndex={0}
            />
            <FormMessage error={error} touched />
          </Flex>
        </Form>
      </Formik>
    </Flex>
  );
}

export default LoginView;

/* eslint-disable @typescript-eslint/no-namespace */
import { HTTPMethods } from './util';

export function isSuccessPayload<D = object>(
  payload: API.SuccessPayload<D> | API.ErrorPayload<D>
): payload is API.SuccessPayload<D> {
  return payload.success;
}

export namespace API {
  /**
   * Core API Payloads
   */

  // Success Payload
  export interface SuccessPayload<D = object, M = object> {
    success: true;
    message: string;
    data: D;
    meta?: M;
  }

  // Error Payload
  export interface ErrorPayload<D = object> {
    success: false;
    message: string;
    data?: D;
    type: string;
  }

  export interface RequestOptions<P = object, D = object> {
    method: HTTPMethods;
    endpoint: string;
    params?: P;
    data?: D;
  }

  export type Payloads<T> = API.SuccessPayload<T> | API.ErrorPayload;

  export type Response<T> = { payload: Payloads<T>; status: number };

  export type AsyncResponse<T> = Promise<Response<T>>;
}

import { getUser, getAuthToken, getTheme } from '~/src/util/localStorage';
import { Action, State } from './types';

export const initialState: State = {
  user: getUser(),
  authToken: getAuthToken(),
  theme: getTheme(),
  globalAlerts: []
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_THEME':
      return { ...state, theme: action.payload.theme };
    case 'ADD_ALERT':
      return { ...state, globalAlerts: [...state.globalAlerts, action.payload.alert] };
    case 'CLEAR_ALERT':
      return { ...state, globalAlerts: [] };
    case 'ADD_USER':
      return { ...state, user: action.payload.user, authToken: action.payload.token };
    default:
      return state;
  }
};

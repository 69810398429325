import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  Fieldset,
  Legend,
  Typography,
  Flex,
  Block,
  Spacer,
  FormMessage,
  Button,
  Table,
  Skeleton
} from 'powder-ui';
import { Portal } from '~/src/components/core/Portal';
import { ExpenseTable } from '~/src/components/core/ExpenseTable';
import { useFormikContext } from 'formik';
import { TextField } from '~/src/components/forms/fields/TextField';
import type { Expense, LineItems } from '~/src/hooks/useStacksAPI';
import { RiDeleteBin2Line as DeleteIcon } from 'react-icons/ri';
import { CurrencyField } from '~/src/components/forms/fields/CurrencyField';
import { LineItemsSheet } from './bin/LineItemsSheet';

interface Props<> {
  title: string;
  id: string;
  values: LineItems<Expense>;
  help?: string;
  hasErrors: () => boolean;
  defaultExpense?: Expense;
  skeleton?: boolean;
  disabled?: boolean;
}

function ExpenseFieldset({
  id,
  title,
  values,
  help,
  hasErrors,
  defaultExpense,
  skeleton,
  disabled = false
}: Props): JSX.Element {
  const { setFieldValue } = useFormikContext();
  const [showSidesheet, setShowSidesheet] = React.useState(false);

  const onAddExpense = () => {
    const expense: Expense = {
      id: uuid(),
      name: defaultExpense?.name || 'Expense',
      description: defaultExpense?.description || '...',
      amount: defaultExpense?.amount || 0
    };
    setFieldValue(id, { items: [...values.items, expense] }, false);
  };

  const onDeleteExpense = (expenseId: string | undefined) => {
    if (expenseId) {
      const items = values.items.filter((item) => item.id !== expenseId);
      setFieldValue(id, { items: [...items] }, false);
    }
  };

  if (skeleton) {
    return (
      <Block>
        <Fieldset gap="0px" border>
          <Legend>
            <Skeleton type="box" height={22} width={160} />
          </Legend>
          <Table.Table
            skeleton
            rows={[]}
            columns={[]}
            rowCount={values.items.length}
            colCount={3}
            spacing={4}
          >
            {() => <span key={Math.random()} />}
          </Table.Table>
          <Spacer spacing={4} />
          <Button skeleton width={120} />
          <Spacer spacing={2} />
        </Fieldset>
        <FormMessage />
        <Spacer spacing={4} />
      </Block>
    );
  }

  return (
    <>
      <Fieldset gap="0px" border>
        <Legend>
          <Typography.H5>{title}</Typography.H5>
        </Legend>
        <ExpenseTable
          items={values.items}
          onEdit={() => setShowSidesheet(true)}
          onAddExpense={() => onAddExpense()}
          hasErrors={hasErrors()}
        />
        <Spacer spacing={2} />
      </Fieldset>
      <FormMessage help={help} touched={hasErrors()} error="Please review expenses for errors" />
      <Spacer spacing={4} />
      <Portal>
        <LineItemsSheet
          title={title}
          show={showSidesheet}
          onAddItem={() => onAddExpense()}
          onClose={() => setShowSidesheet(false)}
        >
          {values.items.map((e: Expense, i: number) => (
            <Flex key={e.id} column gap="8px" m="16px 0px">
              <Typography.H6 condensed>Line Item Expense</Typography.H6>
              <Flex row gap="8px" alignItems="center">
                <Block grow={1} width="160px">
                  <TextField
                    animated
                    condensed
                    name={`${id}.items[${i}].name`}
                    label="Name"
                    disabled={disabled}
                  />
                </Block>
                <Block grow={1}>
                  <TextField
                    animated
                    condensed
                    name={`${id}.items[${i}].description`}
                    label="Description"
                    disabled={disabled}
                  />
                </Block>
                <Block width="100px">
                  <CurrencyField
                    animated
                    condensed
                    name={`${id}.items[${i}].amount`}
                    label="Amount"
                    disabled={disabled}
                  />
                </Block>
                <Block>
                  <Button
                    variation="minimal"
                    icon={<DeleteIcon size="16px" />}
                    intent="danger"
                    disabled={i === 0}
                    onClick={() => onDeleteExpense(e.id)}
                  />
                </Block>
              </Flex>
            </Flex>
          ))}
          <Spacer spacing={2} />
        </LineItemsSheet>
      </Portal>
    </>
  );
}

export default ExpenseFieldset;

/* eslint-disable no-console */

import React from 'react';
import { v4 as uuid } from 'uuid';
import { Flex, Button, Scale, Block, FormMessage } from 'powder-ui';
import { RiAddFill as AddIcon } from 'react-icons/ri';
import { Header } from '~/src/components/core/Header';
import { Form, Formik, FormikHelpers } from 'formik';

import { ErrorAlert, SuccessAlert } from '~/src/components/core/Alerts';
import { addAlert } from '~/src/contexts/global/actions';
import {
  InvestmentFieldset,
  schema,
  type InvestmentSchema
} from '~/src/components/forms/fieldsets/InvestmentFieldset';
import { isSuccessPayload, useStacksAPI } from '~/src/hooks/useStacksAPI';
import { useGlobalContext } from '~/src/contexts/global/context';
import { useNavigate } from 'react-router-dom';

function CreateInvestmentView() {
  /** ---------------  */
  const navigate = useNavigate();
  const { dispatch, state } = useGlobalContext();
  const { createInvestment } = useStacksAPI();

  /** Submit data to API */
  const onFormSubmit = async (
    values: InvestmentSchema,
    actions: FormikHelpers<InvestmentSchema>
  ) => {
    const { payload } = await createInvestment({ data: values });
    if (isSuccessPayload(payload)) {
      const alert = SuccessAlert(payload.data.id, {
        title: 'Success',
        message: 'Investment created successfully'
      });
      addAlert(dispatch, { alert });
      navigate(`/investment/${payload.data.id}`);
    } else {
      const alert = ErrorAlert(uuid(), {
        title: 'Unexpected Error',
        message: payload.message
      });
      addAlert(dispatch, { alert });
    }
    setTimeout(() => {
      actions.setSubmitting(false);
    }, 1000);
  };

  const hasError = (isValid: boolean, submitCount: number) => !isValid && submitCount > 0;
  return (
    <Flex column gap="10px" width="100%">
      <Header text="Add Investment Property" back />
      <Formik
        validationSchema={schema.validation}
        initialValues={schema.init(state.user?.id)}
        onSubmit={onFormSubmit}
      >
        {({ isValid, submitCount, isSubmitting }) => {
          return (
            <Form>
              <Scale xs="100%" sm="100%" md="100%" lg="100%" xl="80%">
                <Block>
                  <InvestmentFieldset />
                  <Button
                    type="submit"
                    width={210}
                    skeleton={isSubmitting}
                    intent={hasError(isValid, submitCount) ? 'danger' : undefined}
                    iconSize="sm"
                    icon={<AddIcon size="16px" />}
                    text="Create Investment"
                  />
                </Block>
                <FormMessage
                  touched={hasError(isValid, submitCount)}
                  error="Please review the form for errors"
                />
              </Scale>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default CreateInvestmentView;

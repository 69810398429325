import React from 'react';
import { ITimelineEvent, TimelineTheme } from 'react-timeline';
import { Block } from 'powder-ui';

import TodoIconLight from './assets/todo.svg';
import TodoIconDark from './assets/todo-white.svg';
import CheckIcon from './assets/check.svg';

export const getTimelineEvents = (mode: 'dark' | 'light') => {
  const EVENTS: ITimelineEvent[] = [
    {
      id: '1',
      title: 'Research & develop proprietary financial model',
      date: new Date('8/01/23'),
      description: 'Ingest & analyize thousands of data points from real-life property investments',
      content: async () => <span />,
      active: false,
      icon: (
        <Block p="4px">
          <img src={CheckIcon} alt="done" />
        </Block>
      )
    },
    {
      id: '2',
      title: 'Engineer API v0.1',
      date: new Date('10/01/23'),
      description:
        'Build performant & scalable backend with Node.js & Typescript with a financial projection engine',
      content: async () => <span />,
      icon: (
        <Block p="4px">
          <img src={CheckIcon} alt="todo" />
        </Block>
      )
    },
    {
      id: '3',
      title: 'Design initial app user experience',
      date: new Date('12/01/23'),
      description:
        'Create high-fidelity app designs, data visualizations, and brand guidelines for an easy-to-use exeperience',
      content: async () => <span />,
      icon: (
        <Block p="4px">
          <img src={CheckIcon} alt="done" />
        </Block>
      )
    },
    {
      id: '4',
      title: 'Build app user interface',
      date: new Date('02/01/24'),
      description:
        "Implement a beautiful interactive UI for the platform that's performant, elegant, and fun to use",
      content: async () => <span />,
      icon: (
        <Block p="4px">
          <img src={CheckIcon} alt="done" />
        </Block>
      )
    },
    {
      id: '5',
      title: 'Launch initial Alpha prototype',
      date: new Date('06/01/24'),
      description:
        '🎉 🚀 Get iniitial user feedback, fine tune modeling, fix minor bugs, and test proof of concept',
      content: async () => <span />,
      icon: (
        <Block p="4px">
          <img src={CheckIcon} alt="done" />
        </Block>
      )
    },
    {
      id: '6',
      title: 'Raise pre-seed capital investment',
      date: { start: new Date('7/01/24'), end: 'present' },
      description:
        'Expand modeling capabilities, add application features, and fine-tune application functionality for a public beta release',
      content: async () => <span />,
      icon: (
        <Block p="4px">
          <img src={mode === 'dark' ? TodoIconDark : TodoIconLight} alt="todo" />
        </Block>
      )
    }
  ];
  return EVENTS;
};

export const getTimelineTheme = (mode: 'light' | 'dark'): any => ({
  ...TimelineTheme.default,
  id: 'custom',
  background: 'rgba(255, 255, 255, 0)',
  fonts: {
    main: 'IBM Plex Sans',
    secondary: 'IBM Plex Sans'
  },
  border: {
    color: '#E6E6E6',
    radius: '4px'
  },
  timeline: {
    color: '#A3ACB9',
    spacing: {
      top: '10px',
      bottom: '10px'
    },
    point: {
      spacing: {
        top: '0px',
        bottom: '2px'
      }
    }
  },
  card: {
    colors: {
      text: mode === 'light' ? '#5F646A' : '#FFFFFF',
      background: mode === 'light' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(100, 100, 100, 0.8)',
      active: {
        border: '#57B181',
        background: mode === 'light' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(100, 100, 100, 0.8)',
        text: mode === 'light' ? '#5F646A' : '#FFFFFF'
      }
    },
    shadow: '2px 2px 4px 0px rgba(0,0,0, 0.2)',
    spacing: {
      top: '10px',
      bottom: '15px'
    },
    width: '400px'
  },
  breakpoints: {
    xs: '600px',
    sm: '980px',
    md: '1200px',
    lg: '1380px'
  }
});

export const renderDate = (date: Date | { start: Date; end: Date | 'present' }): string => {
  const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUNE',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
  ];
  if (date instanceof Date) {
    return `${months[date.getMonth()]} ${date.getFullYear()}`;
  }
  const start = `${months[date.start.getMonth()]} ${date.start.getFullYear()}`;
  let end;
  if (date.end === 'present') end = 'PRESENT';
  else end = `${months[date.end.getMonth()]} ${date.end.getFullYear()}`;
  return `${start} - ${end}`;
};

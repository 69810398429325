import React from 'react';
import { AlertData } from 'powder-ui';
import { RiErrorWarningFill as ErrorIcon, RiCheckLine as SuccessIcon } from 'react-icons/ri';

export const SuccessAlert = (id: string, text: { title: string; message: string }): AlertData => ({
  id,
  title: text.title,
  content: text.message,
  type: 'success',
  duration: 3000,
  icon: <SuccessIcon height="24px" width="24px" fill="green" />
});

export const ErrorAlert = (id: string, text: { title: string; message: string }): AlertData => ({
  id,
  title: text.title,
  content: text.message,
  type: 'danger',
  duration: 3000,
  icon: <ErrorIcon height="24px" width="24px" fill="red" />
});

import React from 'react';
import { Block, Button, Flex } from 'powder-ui';
import { Link } from 'react-router-dom';
import { Navigation, NavLink } from './util';

function Menu() {
  return (
    <Navigation>
      <Flex gap="10px" alignItems="center">
        <NavLink href="#features">
          <Button type="button" text="Features" variation="minimal" alignment="center" fixed />
        </NavLink>
        <NavLink href="#progress">
          <Button type="button" text="Progress" variation="minimal" alignment="center" fixed />
        </NavLink>
        <NavLink href="#demo">
          <Button type="button" text="Demo" variation="minimal" alignment="center" fixed />
        </NavLink>
        <Block m="0px 8px">
          <Link to="/login">
            <Button type="button" text="Login" width={80} alignment="center" />
          </Link>
        </Block>
      </Flex>
    </Navigation>
  );
}

export default Menu;

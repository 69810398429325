import React from 'react';
import { Table, Flex, Button, Scale } from 'powder-ui';
import type { TableDataColumn } from 'powder-ui';
import { Rent } from '~/src/hooks/useStacksAPI';
import { RiPencilLine as EditIcon } from 'react-icons/ri';

import { columns as tableColumns } from './bin/config';

export interface Props {
  items: Rent[];
  onEditRent: (id: number | string) => void;
}

function RentTable({ items, onEditRent }: Props): JSX.Element {
  return (
    <Scale xs="88vw" sm="auto" md="auto" lg="auto" xl="auto">
      <Flex column m="0px 0px 0px 0px" gap="4px">
        {items.length > 0 && (
          <Table.Table rows={items} columns={tableColumns} spacing={4}>
            {({ rows, columns, sortColumn, sortDirection, onSortColumn }) => (
              <>
                <Table.Header>
                  <Table.Row>
                    {columns.map((column: TableDataColumn) => (
                      <Table.HeaderCell
                        key={column.key}
                        column={column}
                        align={column.align}
                        sortColumn={sortColumn !== null ? sortColumn : ''}
                        sortDirection={sortDirection}
                        onClick={() => onSortColumn(column.key)}
                      />
                    ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {rows.map((row) => (
                    <Table.Row key={row.id}>
                      <Table.BodyCell>{row.unit}</Table.BodyCell>
                      <Table.BodyCell align="right">$ {row.base}.00</Table.BodyCell>
                      <Table.BodyCell align="right">$ {row.fees?.pet}.00</Table.BodyCell>
                      <Table.BodyCell align="right">$ {row.fees?.storage}.00</Table.BodyCell>
                      <Table.BodyCell align="right">$ {row.fees?.parking}.00</Table.BodyCell>
                      <Table.BodyCell align="right">$ {row.fees?.other}.00</Table.BodyCell>
                      <Table.BodyCell align="right">
                        <Flex width="100%" justifyContent="flex-end">
                          <Button
                            icon={<EditIcon size="16px" />}
                            variation="minimal"
                            onClick={() => onEditRent(row.id)}
                          />
                        </Flex>
                      </Table.BodyCell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </>
            )}
          </Table.Table>
        )}
      </Flex>
    </Scale>
  );
}

export default RentTable;

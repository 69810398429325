/* eslint-disable no-console */

import React from 'react';
import { v4 as uuid } from 'uuid';
import { Flex, Button, Scale, Block, FormMessage } from 'powder-ui';
import { RiSave2Fill as SaveIcon } from 'react-icons/ri';
import { Header } from '~/src/components/core/Header';
import { Form, Formik, FormikHelpers } from 'formik';
import type { Nullable } from '~/src/types';
import type { Investment } from '~/src/hooks/useStacksAPI';
import { ErrorAlert, SuccessAlert } from '~/src/components/core/Alerts';
import { addAlert } from '~/src/contexts/global/actions';
import {
  InvestmentFieldset,
  schema,
  type InvestmentSchema
} from '~/src/components/forms/fieldsets/InvestmentFieldset';
import { isSuccessPayload, useStacksAPI } from '~/src/hooks/useStacksAPI';
import { useGlobalContext } from '~/src/contexts/global/context';
import { useNavigate, useParams } from 'react-router-dom';
import { useOnce } from '~/src/hooks/useOnce';
import { useLoadingTransition } from '~/src/hooks/useLoadingTransition/useLoadingTransition';

function EditInvestmentView() {
  /** ---------------  */
  const navigate = useNavigate();
  const { dispatch, state } = useGlobalContext();
  const [isLoading, setIsLoading] = React.useState(true);
  const [investment, setInvestment] = React.useState<Nullable<Investment>>(null);
  const { updateInvestment, getInvestment } = useStacksAPI();

  const { id = '' } = useParams();

  const { isViewLoading } = useLoadingTransition(isLoading);

  const loadView = async () => {
    const { payload } = await getInvestment({ params: { id } });
    if (isSuccessPayload(payload)) {
      const { data } = payload;
      setInvestment(data);
      setIsLoading(false);
    } else {
      navigate('/error', { state: payload });
    }
  };

  useOnce(() => {
    loadView();
  }, [loadView]);

  if (isViewLoading || !investment) {
    return <div />;
  }

  /** Submit data to API */
  const onFormSubmit = async (
    values: InvestmentSchema,
    actions: FormikHelpers<InvestmentSchema>
  ) => {
    const { payload } = await updateInvestment({ params: { id }, data: values });
    if (isSuccessPayload(payload)) {
      const alert = SuccessAlert(payload.data.id, {
        title: 'Success',
        message: 'Investment saved successfully!'
      });
      addAlert(dispatch, { alert });
      navigate(`/investment/${payload.data.id}`);
    } else {
      const alert = ErrorAlert(uuid(), {
        title: 'Unexpected Error',
        message: payload.message
      });
      addAlert(dispatch, { alert });
    }
    setTimeout(() => {
      actions.setSubmitting(false);
    }, 1000);
  };

  const hasError = (isValid: boolean, submitCount: number) => !isValid && submitCount > 0;

  const getUserId = (): string => {
    // Return the original user id from the existing investment record
    if (typeof investment.user === 'string') return investment.user;
    if (investment.user?.id) return investment.user.id;
    // Return the logged in user as a fallback
    return state.user?.id || '';
  };

  return (
    <Flex column gap="10px" width="100%">
      <Header text="Edit Investment Property" back />
      <Formik
        validationSchema={schema.validation}
        initialValues={schema.init(getUserId(), investment)}
        onSubmit={onFormSubmit}
      >
        {({ isValid, submitCount, isSubmitting }) => {
          return (
            <Form>
              <Scale xs="100%" sm="100%" md="100%" lg="100%" xl="80%">
                <Block>
                  <InvestmentFieldset />
                  <Button
                    type="submit"
                    width={160}
                    skeleton={isSubmitting}
                    intent={hasError(isValid, submitCount) ? 'danger' : undefined}
                    iconSize="sm"
                    icon={<SaveIcon size="16px" />}
                    text="Save Investment"
                  />
                </Block>
                <FormMessage
                  touched={hasError(isValid, submitCount)}
                  error="Please review the form for errors"
                />
              </Scale>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
}

export default EditInvestmentView;

import React from 'react';
import { Block, Flex, Scroll, Divider, Card, Spacer, Typography } from 'powder-ui';
import { Projection } from '~/src/hooks/useStacksAPI';
import { Statistic } from '~/src/components/core/Statistic';
import { Theme, useTheme } from 'styled-components';
import { AmortizationChart } from '~/src/components/core/Charts/AmortizationChart';
import { LoanBalanceChart } from '~/src/components/core/Charts/LoanBalanceChart';
import { AmortizationTable } from '~/src/components/core/AmortizationTable';

export interface Props {
  projection: Projection;
  isLoading?: boolean;
  isInView?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function AmortizationAnalysis({ projection, isLoading, isInView }: Props): JSX.Element {
  const theme: Theme = useTheme();

  if (!isInView) {
    return <Block height="800px" />;
  }

  return (
    <Flex column>
      <Scroll height="80px" width="100%" direction="x">
        <Flex gap="16px" alignItems="center" wrap="nowrap" justifyContent="flex-start">
          <Statistic
            label="Duration"
            stat={projection.duration}
            skeleton={isLoading}
            precision={0}
            units="Years"
          />
          <Block grow={0}>
            <Divider type="vertical" height="50px" color={theme.palette.neutral[4]} />
          </Block>
          <Statistic
            label="Interest"
            stat={projection.interestRate}
            skeleton={isLoading}
            precision={2}
            rate
            units="%"
          />
          <Block grow={0}>
            <Divider type="vertical" height="50px" color={theme.palette.neutral[4]} />
          </Block>
          <Statistic
            label="Loan Amount"
            currency
            stat={projection.analysis?.loan?.amount}
            skeleton={isLoading}
            magnitude="M"
          />
          <Block grow={0}>
            <Divider type="vertical" height="50px" color={theme.palette.neutral[4]} />
          </Block>
          <Statistic
            label="Total Interest Paid"
            currency
            stat={projection.analysis?.loan?.totalInterest}
            skeleton={isLoading}
            magnitude="M"
          />
          <Block grow={0}>
            <Divider type="vertical" height="50px" color={theme.palette.neutral[4]} />
          </Block>
          <Statistic
            label="Monthly Payment"
            currency
            stat={projection.analysis?.loan?.payment}
            skeleton={isLoading}
            magnitude="M"
          />
        </Flex>
      </Scroll>
      <Spacer spacing={4} />
      <Typography.H5>Overview</Typography.H5>
      <Spacer spacing={4} />
      <Flex gap="16px">
        <Card height="320px" basis="50%">
          <AmortizationChart projection={projection} skeleton={isLoading} />
        </Card>
        <Card height="320px" basis="50%">
          <LoanBalanceChart projection={projection} skeleton={isLoading} />
        </Card>
      </Flex>
      <Spacer spacing={4} />
      <Typography.H5>Amortization Schedule</Typography.H5>
      <Spacer spacing={4} />
      <AmortizationTable projection={projection} skeleton={isLoading} />
    </Flex>
  );
}

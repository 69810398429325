import * as Yup from 'yup';
import { mergeSchemas } from '~/src/components/forms/util/mergeSchemas';
import { ProjectionSchema } from './types';
import { schema as salesSchema } from '../SaleFieldset/_schema';
import { schema as closingCostsSchema } from '../ExpenseFieldset/_schemas/closingCosts';
import { schema as seedFundingSchema } from '../ExpenseFieldset/_schemas/seedFunding';
import { schema as feesSchema } from '../ExpenseFieldset/_schemas/fees';
import { schema as utilitiesSchema } from '../ExpenseFieldset/_schemas/utilities';
import { schema as managementSchema } from '../ManagementFieldset/_schema';
import { schema as growthSchema } from '../GrowthFieldset/_schema';
import { schema as rentSchema } from '../RentFieldset/_schema';

const init = (): ProjectionSchema => ({
  id: '',
  title: '',
  ...salesSchema.init(),
  ...seedFundingSchema.init(),
  ...closingCostsSchema.init(),
  ...utilitiesSchema.init(),
  ...feesSchema.init(),
  ...rentSchema.init(),
  ...managementSchema.init(),
  ...growthSchema.init(),
  start: new Date(),
  end: new Date()
});

const validation = mergeSchemas(
  Yup.object({ title: Yup.string().required('Title is required') }),
  salesSchema.validation,
  seedFundingSchema.validation,
  closingCostsSchema.validation,
  utilitiesSchema.validation,
  feesSchema.validation,
  rentSchema.validation,
  managementSchema.validation,
  growthSchema.validation
);

export default { init, validation };

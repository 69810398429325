import React from 'react';
import { Card, Skeleton } from 'powder-ui';

import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';
import { Theme, useTheme } from 'styled-components';
import { Projection } from '~/src/hooks/useStacksAPI';
import { CurrencyTooltip } from './bin';

interface Props {
  projection: Projection;
  skeleton?: boolean;
}

export function ProjectionOverviewChart({ projection, skeleton }: Props): JSX.Element {
  const theme: Theme = useTheme();
  const [key, setKey] = React.useState(Date.now());

  React.useEffect(() => {
    // Update the key when the data prop changes
    setKey(Date.now());
  }, [projection.analysis?.growth]);

  const isProfitLoss = () => {
    let show: boolean = false;
    if (projection.analysis) {
      const { growth = [] } = projection.analysis;
      growth.forEach((year) => {
        if (year.total.profit < 0) {
          show = true;
        }
      });
    }
    return show;
  };

  if (skeleton) {
    return <Skeleton type="box" height={200} fluid />;
  }

  return (
    <Card width="100%" height="200px" background={{ color: theme.palette.background }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={projection.analysis?.growth}
          key={key}
          margin={{
            top: 0,
            right: 2,
            left: 2,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" interval={2} fontSize="6px" allowDataOverflow />
          {isProfitLoss() && <ReferenceLine y={0} stroke="red" strokeDasharray="3 3" />}
          <Tooltip content={CurrencyTooltip} />
          <Legend wrapperStyle={{ fontSize: '10px', fontFamily: theme.fonts.family.mono }} />
          <Line
            type="monotone"
            name="Total Profit"
            dataKey="total.profit"
            stroke={theme.palette.visualization[0]}
            dot={{ r: 2, fill: theme.palette.visualization[0] }}
            activeDot={{ r: 4, fill: theme.palette.visualization[0] }}
          />
          <Line
            type="monotone"
            name="Property Value"
            dataKey="total.propertyValue"
            stroke={theme.palette.visualization[1]}
            dot={{ r: 2, fill: theme.palette.visualization[1] }}
            activeDot={{ r: 4, fill: theme.palette.visualization[1] }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
}

import { useFormikContext } from 'formik';
import { v4 as uuid } from 'uuid';
import { Flex, Divider, Block, Scroll, Spacer } from 'powder-ui';
import React from 'react';
import { Statistic } from '~/src/components/core/Statistic';
import ExpenseFieldset from '~/src/components/forms/fieldsets/ProjectionFieldsets/ExpenseFieldset/ExpenseFieldset';
import SaleFieldset from '~/src/components/forms/fieldsets/ProjectionFieldsets/SaleFieldset/SaleFieldset';
import { ProjectionSchema } from '~/src/components/forms/fieldsets/ProjectionFieldsets/_schema';
import { Projection } from '~/src/hooks/useStacksAPI';
import { Numeric } from '~/src/components/core/Numeric';
import { Chip } from '~/src/components/core/Chip';
import { Theme, useTheme } from 'styled-components';

export interface Props {
  projection: Projection;
  isLoading?: boolean;
  isInView?: boolean;
}

export function FinancingAnalysis({ isLoading, projection, isInView }: Props): JSX.Element {
  const theme: Theme = useTheme();
  const { values, errors, isValid } = useFormikContext<ProjectionSchema>();

  if (!isInView) {
    return <Block height="800px" />;
  }
  return (
    <Flex column>
      <Scroll height="140px" width="100%" direction="x">
        <Flex gap="16px" alignItems="center" wrap="nowrap" justifyContent="flex-start">
          <Statistic
            label="Downpayment"
            currency
            stat={projection?.analysis?.sale?.downpayment}
            skeleton={isLoading}
            magnitude="M"
          />
          <Block grow={0}>
            <Divider type="vertical" height="50px" color={theme.palette.neutral[4]} />
          </Block>
          <Statistic
            label="Total Cash Invested"
            currency
            stat={projection?.analysis?.sale?.cashInvested}
            skeleton={isLoading}
            magnitude="M"
          />
          <Block grow={0}>
            <Divider type="vertical" height="50px" color={theme.palette.neutral[4]} />
          </Block>
          <Statistic
            label="Total Acquisition Cost"
            currency
            stat={projection?.analysis?.sale?.totalPurchasePrice}
            skeleton={isLoading}
            magnitude="M"
          />
          <Block grow={0}>
            <Divider type="vertical" height="50px" color={theme.palette.neutral[4]} />
          </Block>
          <Statistic
            label="Remaining Assets"
            currency
            stat={projection?.analysis?.sale?.remainingAssets}
            skeleton={isLoading}
            magnitude="M"
          />
          <Block grow={0}>
            <Divider type="vertical" height="50px" color={theme.palette.neutral[4]} />
          </Block>
          <Statistic
            label="Init. Property Value"
            currency
            stat={projection?.analysis?.sale?.initialPropertyValue}
            skeleton={isLoading}
            magnitude="M"
          />
        </Flex>
        <Spacer spacing={2} />
        <Flex gap="20px" alignItems="center" wrap="nowrap" justifyContent="flex-start">
          <Chip skeleton={isLoading} label="Loan Amount">
            <Numeric number={projection.analysis?.loan?.amount} currency magnitude="M" />
          </Chip>
          <Chip skeleton={isLoading} label="Total Start Up Costs">
            <Numeric number={projection.seedFunding.total} currency magnitude="M" />
          </Chip>
          <Chip skeleton={isLoading} label="Total Closing Costs">
            <Numeric number={projection.closingCosts.total} currency magnitude="M" />
          </Chip>
          <Chip skeleton={isLoading} label="Monthly Fees">
            <Numeric number={projection.fees.total} currency magnitude="M" />
          </Chip>
          <Chip skeleton={isLoading} label="Mothly Tax Payment">
            <Numeric number={projection.analysis?.loan?.taxes} currency magnitude="M" />
          </Chip>
          <Chip skeleton={isLoading} label="Total Monthly Payment">
            <Numeric number={projection.analysis?.loan?.total} currency magnitude="M" />
          </Chip>
        </Flex>
        <Flex />
      </Scroll>
      <Spacer spacing={4} />
      <SaleFieldset showTaxes={false} disabled={isLoading && isValid} />
      <ExpenseFieldset
        title="Closing Costs"
        id="closingCosts"
        values={values.closingCosts}
        hasErrors={() => !!('closingCosts' in errors)}
        disabled={isLoading && isValid}
        defaultExpense={{
          id: uuid(),
          name: 'Closing Cost',
          description: '---',
          amount: 0
        }}
        help="The costs to close on the property sale and secure financing"
      />
      <ExpenseFieldset
        title="Seed Funding"
        id="seedFunding"
        values={values.seedFunding}
        hasErrors={() => !!('seedFunding' in errors)}
        disabled={isLoading && isValid}
        defaultExpense={{
          id: uuid(),
          name: 'Seed Funding',
          description: '---',
          amount: 0
        }}
        help="The initial costs to get the property up and running"
      />
      <ExpenseFieldset
        title="Fees"
        id="fees"
        values={values.fees}
        hasErrors={() => !!('fees' in errors)}
        disabled={isLoading && isValid}
        defaultExpense={{
          id: uuid(),
          name: 'Misc. Fee',
          description: '---',
          amount: 0
        }}
        help="Other misc.fees not already included, such as an HOA "
      />
    </Flex>
  );
}

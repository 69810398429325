import React from 'react';
import { v4 as uuid } from 'uuid';
import { Flex, Button, Block, Typography, Spacer } from 'powder-ui';
import { Header } from '~/src/components/core/Header';
import type { Investment, Projection } from '~/src/hooks/useStacksAPI';
import type { Nullable } from '~/src/types';
import { isSuccessPayload, useStacksAPI } from '~/src/hooks/useStacksAPI';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { addAlert } from '~/src/contexts/global/actions';
import { useOnce } from '~/src/hooks/useOnce';
import { useLoadingTransition } from '~/src/hooks/useLoadingTransition/useLoadingTransition';
import { InvestmentCard } from '~/src/components/core/InvestmentCard';
import {
  RiDeleteBinFill as DeleteIcon,
  RiEdit2Line as EditIcon,
  RiAddLine as AddIcon
} from 'react-icons/ri';
import { ErrorAlert, SuccessAlert } from '~/src/components/core/Alerts';

import { ProjectionTable } from '~/src/components/core/ProjectionTable';
import { useGlobalContext } from '~/src/contexts/global/context';
import { DeleteInvestmentDialog } from '~/src/components/core/DeleteInvestmentDialog';
import { Theme, useTheme } from 'styled-components';

import { SkeletonView } from './bin/SkeletonView';
import { Map } from './bin/Map';
import { Walkscore } from './bin/Walkscore';
import { MarketRent } from './bin/MarketRent';
import { MarketValue } from './bin/MarketValue';

function InvestmentView(): JSX.Element {
  /** ---------------  */
  const { dispatch } = useGlobalContext();
  const theme: Theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [investment, setInvestment] = React.useState<Nullable<Investment>>(null);
  const [projections, setProjections] = React.useState<Projection[]>([]);
  const { getInvestment, listProjections, deleteInvestment } = useStacksAPI();
  const [showDialog, setShowDialog] = React.useState(false);

  const { id = '' } = useParams();

  const { isViewLoading } = useLoadingTransition(isLoading, 1000);

  const loadView = React.useCallback(async () => {
    const { payload } = await getInvestment({ params: { id } });
    if (isSuccessPayload(payload)) {
      const { data } = payload;
      setInvestment(data);
      setIsLoading(false);
    } else {
      navigate('/error', { state: payload });
    }
  }, [id, getInvestment, navigate]);

  const loadProjections = React.useCallback(async () => {
    if (investment && investment.id) {
      const { payload } = await listProjections({ query: { investment: investment.id } });
      if (isSuccessPayload(payload)) {
        const { data } = payload;
        setProjections(data);
        setIsLoading(false);
      } else {
        addAlert(dispatch, {
          alert: ErrorAlert(uuid(), {
            title: 'An error occurred',
            message: 'Could not load projections'
          })
        });
      }
    }
  }, [investment, listProjections, dispatch]);

  const onDeleteInvestment = async () => {
    const args = { params: { id } };
    const { payload } = await deleteInvestment(args);
    // Return to the dashboard once the investment is deleteed
    if (isSuccessPayload(payload)) {
      addAlert(dispatch, {
        alert: SuccessAlert(uuid(), {
          title: 'Success!',
          message: 'Investment successfully deleted.'
        })
      });
      navigate('/dashboard');
    } else {
      addAlert(dispatch, {
        alert: ErrorAlert(uuid(), {
          title: 'An error occurred',
          message: 'Could not delete investment'
        })
      });
    }
  };

  useOnce(() => {
    loadView();
  }, [loadView]);

  React.useEffect(() => {
    loadProjections();
  }, [loadProjections]);

  if (isViewLoading || !investment) {
    return <SkeletonView />;
  }

  return (
    <Flex column gap="10px" width="100%">
      <Header text={investment.address} backTo="/dashboard">
        <Flex alignItems="center" gap="4px">
          <Link to={`/investment/edit/${investment.id}`}>
            <Button
              text="Edit"
              type="button"
              variation="secondary"
              icon={<EditIcon size="16px" />}
            />
          </Link>
          <Button
            type="button"
            variation="secondary"
            text="Delete"
            icon={<DeleteIcon size="16px" />}
            intent="danger"
            onClick={() => setShowDialog(true)}
          />
        </Flex>
      </Header>
      <Flex gap="16px" wrap="wrap">
        <Block basis="400px" shrink={0} grow={1}>
          <InvestmentCard investment={investment} />
        </Block>
        <Block basis="600px" grow={3} radius="4px">
          <Map
            coordinates={investment.meta.data?.coordinates}
            title={investment.address}
            height={220}
            zoom={12}
          />
        </Block>
      </Flex>
      <Flex gap="16px" wrap="wrap">
        <MarketValue investment={investment} skeleton={isViewLoading} />
        <MarketRent investment={investment} skeleton={isViewLoading} />
        <Walkscore investment={investment} skeleton={isViewLoading} />
      </Flex>
      <Spacer spacing={1} />
      <Block>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          p="8px"
          background={{ color: theme.palette.neutral[5] }}
        >
          <Typography.H4>Projections</Typography.H4>
          <Link to={`/projection/new?investment=${investment.id}`}>
            <Button height={32} text="Create New Projection" icon={<AddIcon size="16px" />} />
          </Link>
        </Flex>
        <ProjectionTable projections={projections} />
      </Block>
      <Typography.Body size="10px">
        * Market rent data is calculated as the average rent amount <em>per unit</em>
      </Typography.Body>
      <DeleteInvestmentDialog
        investment={investment}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        onConfirmDelete={() => onDeleteInvestment()}
      />
    </Flex>
  );
}

export default InvestmentView;

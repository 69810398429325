import React from 'react';
import { Flex, Block, Divider } from 'powder-ui';
import { Theme, useTheme } from 'styled-components';

import { Tab } from './bin/Tab';

export interface Props {
  active: string;
  height?: string;
  tabs: Array<{ key: string; label: string; width: string }>;
  onSelectTab: (key: string) => void;
}

function Tabs({ tabs, active, height = 'auto', onSelectTab }: Props): JSX.Element {
  const theme: Theme = useTheme();
  return (
    <Block>
      <Flex justifyContent="flex-start" alignItems="center">
        {tabs.map((t) => (
          <Flex
            column
            height={height}
            position="relative"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Tab
              type="button"
              isActive={active === t.key}
              width={t.width}
              onClick={() => onSelectTab(t.key)}
            >
              {t.label}
            </Tab>
          </Flex>
        ))}
      </Flex>
      <Divider color={theme.palette.neutral[4]} size={1} spacing={{ top: '4px', bottom: '4px' }} />
    </Block>
  );
}

export default Tabs;

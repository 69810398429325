import React from 'react';
import ReactDOM from 'react-dom';
import { Block } from 'powder-ui';

interface Props {
  children: React.ReactNode;
}

export function Portal(props: Props) {
  const { children } = props;
  const portal = document.getElementById('portal');
  if (!portal) return <span />;
  // use React Portal to render the modal outside of the current component
  // https://reactjs.org/docs/portals.html
  return ReactDOM.createPortal(<Block>{children}</Block>, portal);
}

import React from 'react';
import styled from 'styled-components';
import { Block, Typography, Spacer } from 'powder-ui';
import { FeatureContainer, FeatureBox } from './util';

import BackgroundImage from '../assets/features-bg.png';
import Icon1 from '../assets/feature-icon-1.svg';
import Icon2 from '../assets/feature-icon-2.svg';
import Icon3 from '../assets/feature-icon-3.svg';

const Background = styled.div`
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      display: none;
    }
  }
`;

function Features() {
  return (
    <FeatureContainer id="features">
      <Background>
        <img src={BackgroundImage} alt="background" />
      </Background>
      <Spacer spacing={4} />
      <Typography.H2>Application Features</Typography.H2>
      <Spacer spacing={8} />
      <FeatureBox>
        <Block basis={1 / 3}>
          <img src={Icon1} alt="feature icon 1" />
          <Typography.H4 size="14px" weight={600}>
            Detailed Financial Models
          </Typography.H4>
          <Typography.Body size="14px" lineHeight="22px">
            Dive deep into the financials of each property investment within your portfolio. Create
            detailed financial models to gain a clear understanding of your investment&apos;s
            potential, from projected cash flows to expenses.
          </Typography.Body>
        </Block>
        <Block basis={1 / 3}>
          <img src={Icon2} alt="feature icon 1" />
          <Typography.H4 size="14px" weight={600}>
            In Depth Analysis
          </Typography.H4>
          <Typography.Body size="14px" lineHeight="22px">
            Take the guesswork out of your investment decisions. PadStack enables you to run
            comprehensive analyses on your investments, providing you with yield and growth
            projections that are based on real data and financial modeling.
          </Typography.Body>
        </Block>
        <Block basis={1 / 3}>
          <img src={Icon3} alt="feature icon 1" />
          <Typography.H4 size="14px" weight={600}>
            Stress Testing & Risk Management
          </Typography.H4>
          <Typography.Body size="14px" lineHeight="22px">
            Real estate investment can be unpredictable. Pad Stack empowers you to stress-test your
            investments by adjusting key modifiers, assessing your position, and making informed
            decisions that adapt to the evolving real estate market.
          </Typography.Body>
        </Block>
      </FeatureBox>
    </FeatureContainer>
  );
}

export default Features;

import React from 'react';
import { TextInput } from 'powder-ui';
import { useField } from 'formik';

interface Props {
  name: string;
  label: string;
  value?: string;
  placeholder?: string;
  hideLabel?: boolean;
  animated?: boolean;
  error?: string;
  help?: string;
  type?: 'text' | 'email' | 'tel' | 'password' | 'number';
  touched?: boolean;
  disabled?: boolean;
  condensed?: boolean;
  skeleton?: boolean;
  autoFocus?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export function TextField(props: Props) {
  const {
    name,
    value,
    onBlur = () => {},
    onChange = () => {},
    type = 'text',
    ...baseProps
  } = props;

  const [field, meta, helpers] = useField<any>(name);
  const { setValue } = helpers;

  const [inputValue, setInputValue] = React.useState(field.value);

  const { error, touched } = meta;

  React.useEffect(() => {
    // This effect ensures the local input value is in sync with Formik's state when not focused
    setInputValue(field.value.toString());
  }, [field.value, type]);

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(e);
    if (type === 'number') setValue(parseInt(inputValue, 10));
    else setValue(inputValue);
    onBlur(e);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    onChange(e);
  };

  return (
    <TextInput
      name={field.name}
      value={inputValue}
      onBlur={onInputBlur}
      onChange={onInputChange}
      error={error}
      touched={touched}
      type={type}
      {...baseProps}
    />
  );
}

import { Nullable } from 'powder-ui';
import { InvestmentType, LineItems, Unit, Id } from './core';
import { User } from './user';

export interface Investment {
  id: Id;
  user: Id | Nullable<User>;
  type: InvestmentType;
  address: string;
  icon: PropertyIconKey;
  city: string;
  state: string;
  country: string;
  zip: string;
  neighborhood: string;
  lotSize: number;
  year: number;
  units: LineItems<Unit>;
  sqft: number;
  meta: InvestmentMeta;
}

export type InvestmentMeta = {
  built: boolean;
  data?: {
    walkscore: Walkscore;
    coordinates: Coordinaates;
    rentcast: RentcastRentEstimate;
    marketValue: RentcastMarketValue;
  };
};

export type PropertyIconKey =
  | 'house-1'
  | 'house-2'
  | 'house-3'
  | 'house-4'
  | 'house-5'
  | 'house-6'
  | 'house-7'
  | 'house-8'
  | 'apt-1'
  | 'apt-2'
  | 'manufactured-1';

export type Coordinaates = { lat: number; lng: number };

export type Geometry = {
  location: Coordinaates;
  bounds: {
    northeast: Coordinaates;
    southwest: Coordinaates;
  };
  location_type: string;
  viewport: {
    northeast: Coordinaates;
    southwest: Coordinaates;
  };
};

export type GeocodingResponse = {
  results: Array<{ geometry: Geometry }>;
};

/**
 * Walk Score
 */
export type Walkscore = {
  status?: number;
  walkscore?: number;
  description?: string;
  updated?: string;
  transit?: {
    description?: string;
    summary?: string;
    score?: number;
  };
  bike?: {
    description?: string;
    summary?: string;
    score?: number;
  };
};

/**
 * Rentcast
 */
export type RentcastRentEstimate = {
  rent?: number;
  rentRangeLow?: number;
  rentRangeHigh?: number;
  latitude?: number;
  longitude?: number;
  comparables?: Array<{
    id?: string;
    formattedAddress?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    county?: string;
    latitude?: number;
    longitude?: number;
    propertyType?: string;
    bedrooms?: number;
    bathrooms?: number;
    squareFootage?: number;
    lotSize?: number;
    yearBuilt?: number;
    price?: number;
    listedDate?: string;
    lastSeenDate?: string;
    daysOld?: number;
    distance?: number;
    correlation?: number;
  }>;
};

export type RentcastMarketValue = {
  price?: number;
  priceRangeLow?: number;
  priceRangeHigh?: number;
  latitude?: number;
  longitude?: number;
  comparables?: Array<{
    id?: string;
    formattedAddress?: string;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    county?: string;
    latitude?: number;
    longitude?: number;
    propertyType?: string;
    bedrooms?: number;
    bathrooms?: number;
    squareFootage?: number;
    lotSize?: number;
    yearBuilt?: number;
    price?: number;
    listedDate?: string;
    lastSeenDate?: string;
    daysOld?: number;
    distance?: number;
    correlation?: number;
  }>;
};

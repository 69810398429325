import { TableDataColumn } from 'powder-ui';

export const columns: TableDataColumn[] = [
  {
    key: 'name',
    header: 'Name',
    sortable: false,
    width: 65
  },
  {
    key: 'bedrooms',
    header: 'Bedrooms',
    sortable: false,
    width: 15,
    align: 'right'
  },
  {
    key: 'bathroom',
    header: 'Bathrooms',
    sortable: false,
    width: 15,
    align: 'right'
  },
  {
    key: 'sqft',
    header: 'Sqft',
    sortable: true,
    width: 5,
    align: 'right'
  },
  {
    key: 'pets',
    header: 'Pets',
    sortable: false,
    width: 10,
    align: 'center'
  }
];

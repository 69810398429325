// Aliases for diffrent documentation purposes
export type Rate = number;
export type Finance = number;
export type Id = string;

export enum InvestmentType {
  house = 'Single Family',
  condo = 'Condo',
  multi = 'Multi-Family',
  apartment = 'Apartment',
  manufactured = 'Manufactured',
  /** Deprecated * */
  _ = 'House',
  none = ''
}

export type LineItems<T> = {
  total?: number;
  items: T[];
};

export type Unit = {
  id: Id;
  name?: string;
  bedrooms?: number;
  bathroom?: number;
  pets?: boolean;
  sqft?: number;
};

export type Expense = {
  id: Id;
  name: string;
  amount: Finance;
  description?: string;
};

export type Rent = {
  id: Id;
  unit: string;
  amount?: Finance;
  base: Finance;
  fees: {
    pet: Finance;
    storage: Finance;
    parking: Finance;
    other: Finance;
  };
};

export type Amortization = {
  year: number;
  periods: number;
  amount: Finance;
};

export type AmortizationPeriod = {
  period: number;
  payment: Finance;
  interest: Finance;
  principal: Finance;
  balance: Finance;
};

export type AmortizationSchedule = {
  schedule: AmortizationPeriod[];
  principal?: Amortization[];
  interest?: Amortization[];
};

export type Sale = {
  downpayment: Finance;
  totalPurchasePrice: Finance;
  cashInvested: Finance;
  remainingAssets: Finance;
  initialEquity: Finance;
  initialPropertyValue: Finance;
};

export type Loan = {
  duration: number;
  amount: Finance;
  payment: Finance;
  taxes: Finance;
  escrow: Finance;
  total: Finance;
  totalInterest?: Finance;
  amortization?: AmortizationSchedule;
};

export type AnnualExpenses = {
  vacancy: Finance;
  management: Finance;
  maintenance: Finance;
  utilities: Finance;
  taxes: Finance;
  fees: Finance;
  principal: Finance;
  interest: Finance;
  total: Finance;
};

export type AnnualCashflow = {
  revenue: Finance;
  expenses: AnnualExpenses;
  net: number;
};

export type Yield = {
  grossYield: Rate;
  capRate: Rate;
  roi: Rate;
  paybackYears: number;
};

export type AnnualMetrics = {
  revenue: Finance;
  expenses: Finance;
  interest: Finance;
  principal: Finance;
  capitalGrowth: Finance;
  profit: Finance;
  return: Finance;
  grossYield: Rate;
  capRate: Rate;
  cashOnCash: Rate;
  roi: Rate;
};

export type CumulativeMetrics = {
  profit: Finance;
  liquidAssets: Finance;
  propertyValue: Finance;
  equity: Finance;
  totalReturn: Finance;
};

export type GrowthMetrics = {
  year: number;
  annual: AnnualMetrics;
  total: CumulativeMetrics;
};

export interface Analysis {
  sale?: Sale;
  loan?: Loan;
  cashflow?: AnnualCashflow;
  yield?: Yield;
  growth?: GrowthMetrics[];
}

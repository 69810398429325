import React from 'react';
import client from './client';
import { Investment, Projection, User } from './types';
import { LoginUser } from './types/endpoints/user';
import {
  ListInvestments,
  DeleteInvestment,
  CreateInvestment,
  GetInvestment,
  UpdateInvestment
} from './types/endpoints/investments';
import {
  CreateProjection,
  GetProjection,
  ListProjections,
  RunProjection,
  UpdateProjection
} from './types/endpoints/projections';

export const useStacksAPI = () => {
  // Set auth token
  const setAuthToken = (token: string) => {
    client.token = token;
  };

  // User Login
  const loginUser = async ({ data }: LoginUser.Args): LoginUser.Response => {
    return client.request<User>({
      method: 'post',
      data,
      endpoint: 'user/login'
    });
  };

  // Fetch all investments
  const listInvestments = async (): ListInvestments.Response => {
    return client.request<Investment[]>({
      method: 'get',
      endpoint: 'investment'
    });
  };

  // Get a single investment
  const getInvestment = async ({ params }: GetInvestment.Args): GetInvestment.Response => {
    return client.request<Investment>({
      method: 'get',
      params,
      endpoint: `investment/${params.id}`
    });
  };

  // Delete Investment
  const deleteInvestment = async ({ params }: DeleteInvestment.Args): DeleteInvestment.Response => {
    return client.request<{ id: string }>({
      method: 'delete',
      endpoint: `investment/${params.id}`
    });
  };

  // Create Investment
  const createInvestment = async ({ data }: CreateInvestment.Args): CreateInvestment.Response => {
    return client.request<Investment>({
      method: 'post',
      data,
      endpoint: `investment`
    });
  };

  // Update Investment
  const updateInvestment = async ({
    params,
    data
  }: UpdateInvestment.Args): UpdateInvestment.Response => {
    return client.request<Investment>({
      method: 'put',
      data,
      endpoint: `investment/${params.id}`
    });
  };

  // Create Projection
  const createProjection = async ({ data }: CreateProjection.Args): CreateProjection.Response => {
    return client.request<Projection>({
      method: 'post',
      data,
      endpoint: `projection`
    });
  };

  // Update Projection
  const updateProjection = async ({
    data,
    params
  }: UpdateProjection.Args): UpdateProjection.Response => {
    return client.request<Projection>({
      method: 'put',
      data,
      endpoint: `projection/${params.id}`
    });
  };

  // Get a single Projection
  const getProjection = async ({ params }: GetProjection.Args): GetProjection.Response => {
    return client.request<Projection>({
      method: 'get',
      params,
      endpoint: `projection/${params.id}`
    });
  };

  // Run Projection
  const runProjection = async ({ data }: RunProjection.Args): RunProjection.Response => {
    return client.request<Projection>({
      method: 'post',
      data,
      endpoint: `projection/run`
    });
  };

  // List Projection
  const listProjections = async ({ query }: ListProjections.Args): ListProjections.Response => {
    return client.request<Projection[]>({
      method: 'get',
      params: { investment: query.investment },
      endpoint: `projection`
    });
  };

  return React.useMemo(
    () => ({
      // Client
      client,
      setAuthToken,
      // User
      loginUser,
      // Investments
      listInvestments,
      getInvestment,
      createInvestment,
      updateInvestment,
      deleteInvestment,
      // Projections
      createProjection,
      updateProjection,
      getProjection,
      listProjections,
      runProjection
    }),
    []
  );
};

import { User } from '~/src/hooks/useStacksAPI';
import React from 'react';
import { Nullable } from '~/src/types';

// Args
type Args = { user: Nullable<User>; token: Nullable<string> };

// Payload
type Payload = { user: Nullable<User>; token: Nullable<string> };

// Action
export type AddUser = {
  type: 'ADD_USER';
  payload: Payload;
};

export const setUser = (dispatch: React.Dispatch<AddUser>, args: Args): void =>
  dispatch({
    type: 'ADD_USER',
    payload: { user: args.user, token: args.token }
  });

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Flex, Card, Typography, Divider, transparentize, Scale, Button, Spacer } from 'powder-ui';
import { Theme, useTheme } from 'styled-components';
import { setUser } from '~/src/contexts/global/actions';
import { useGlobalContext } from '~/src/contexts/global/context';
import type { API } from '../../../hooks/useStacksAPI';

import WhiteLogo from '../../../assets/logo-white.svg';
import DefaultLogo from '../../../assets/logo-default.svg';

export function ErrorView(): JSX.Element {
  const location = useLocation();
  const theme: Theme = useTheme();
  const { dispatch } = useGlobalContext();
  const { state }: { state: API.ErrorPayload } = location;

  const logoutUser = React.useCallback(() => {
    // Logout User
    setUser(dispatch, { user: null, token: null });
  }, [dispatch]);

  React.useEffect(() => {
    if (state.type === 'AuthError') {
      logoutUser();
    }
  }, [state, logoutUser]);

  const Logo = theme.mode === 'dark' ? WhiteLogo : DefaultLogo;

  return (
    <Flex column alignItems="center" justifyContent="center" width="100%" gap="8px">
      <Flex p="0px 20px">
        <img src={Logo} alt="Padstack" width="200px" />
      </Flex>
      <Divider width="50%" alignment="center" color={theme.palette.neutral[4]} />
      {state && (
        <>
          <Typography.Mono size="12px" align="center" color={theme.palette.danger.main}>
            <strong>{state.message}</strong>
          </Typography.Mono>
          <Scale xs="100%" sm="100%" md="60%" lg="40%" xl="30%">
            <Card
              width="100%"
              background={{ color: transparentize(theme.palette.danger.shades[2], 0.4) }}
              border={`solid 1px ${theme.palette.danger.shades[2]}`}
            >
              <pre>
                <Typography.Mono size="10px">{JSON.stringify(state, null, 2)}</Typography.Mono>
              </pre>
            </Card>
          </Scale>
          <Spacer spacing={2} />
          <Link to="/">
            <Button type="button" text="Return Home" />
          </Link>
          <Typography.Link size="12px" href="mailto:daniel@ddluc.com">
            Report an Issue
          </Typography.Link>
        </>
      )}
    </Flex>
  );
}

import React from 'react';

// Args
type Args = { theme: 'light' | 'dark' };

// Payload
type Payload = { theme: 'light' | 'dark' };

// Action
export type SetTheme = {
  type: 'SET_THEME';
  payload: Payload;
};

export const setTheme = (
  dispatch: React.Dispatch<SetTheme>,
  args: Args = { theme: 'light' }
): void =>
  dispatch({
    type: 'SET_THEME',
    payload: { theme: args.theme }
  });

/* eslint-disable @typescript-eslint/no-namespace */
import { API } from '../api';
import { Investment } from '../models/investment';

export namespace GetInvestment {
  export type Args = {
    params: { id: string };
  };
  export type Response = API.AsyncResponse<Investment>;
}

export namespace ListInvestments {
  export type Args = {
    query: { user: string };
  };
  export type Response = API.AsyncResponse<Investment[]>;
}

export namespace CreateInvestment {
  export type Args = {
    data: Investment;
  };
  export type Response = API.AsyncResponse<Investment>;
}

export namespace UpdateInvestment {
  export type Args = {
    params: { id: string };
    data: Investment;
  };
  export type Response = API.AsyncResponse<Investment>;
}

export namespace DeleteInvestment {
  export type Args = {
    params: { id: string };
  };
  export type Response = API.AsyncResponse<{ id: string }>;
}

import { Nullable } from 'powder-ui';
import { Investment } from './investment';
import { Finance, LineItems, Expense, Rate, Rent, Id, Analysis } from './core';

export interface Projection {
  id: Id;
  investment?: Id | Nullable<Investment>;
  title: string;
  availableAssets: Finance;
  propertyPrice: Finance;
  seedFunding: LineItems<Expense>;
  closingCosts: LineItems<Expense>;
  percentDown: Rate;
  interestRate: Rate;
  start: Date;
  end: Date;
  duration: number;
  rent: LineItems<Rent>;
  maintenance: Rate;
  utilities: LineItems<Expense>;
  managementFee: Rate;
  vacancyRate: Rate;
  millage: Rate;
  fees: LineItems<Expense>;
  capitalGrowthRate: Rate;
  marketGrowthRate: Rate;
  inflationRate: Rate;
  analysis?: Analysis;
}

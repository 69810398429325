import React from 'react';
import { Table, Flex, Button, Typography } from 'powder-ui';
import type { TableDataColumn } from 'powder-ui';
import { Projection } from '~/src/hooks/useStacksAPI';

import { RiEyeFill as ViewIcon, RiSendBackward as CloneIcon } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Theme, useTheme } from 'styled-components';
import { columns as tableColumns } from './bin/config';
import { Numeric } from '../Numeric';

export interface Props {
  projections: Projection[];
}

function ProjectionTable({ projections }: Props): JSX.Element {
  const theme: Theme = useTheme();
  return (
    <Flex column m="0px 0px 0px 0px" gap="4px">
      {projections.length > 0 && (
        <Table.Table rows={projections} columns={tableColumns} spacing={2}>
          {({ rows, columns, sortColumn, sortDirection, onSortColumn }) => (
            <>
              <Table.Header topBorder bottomBorder borderColor={theme.palette.neutral[3]}>
                <Table.Row>
                  {columns.map((column: TableDataColumn) => (
                    <Table.HeaderCell
                      key={column.key}
                      column={column}
                      align={column.align}
                      sortColumn={sortColumn !== null ? sortColumn : ''}
                      sortDirection={sortDirection}
                      onClick={() => onSortColumn(column.key)}
                    />
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {rows.map((row) => (
                  <Table.Row key={row.id}>
                    <Table.BodyCell align="left">
                      <Link to={`/projection/${row.id}`}>
                        <Typography.Link size="12px" lineHeight="18px" condensed>
                          {row.title}
                        </Typography.Link>
                      </Link>
                    </Table.BodyCell>
                    <Table.BodyCell align="right">
                      <Typography.Mono size="12px" lineHeight="18px" condensed>
                        <Numeric number={row.propertyPrice} magnitude="M" currency />
                      </Typography.Mono>
                    </Table.BodyCell>
                    <Table.BodyCell align="right">
                      <Typography.Mono size="12px" lineHeight="18px" condensed>
                        <Numeric number={row.availableAssets} magnitude="M" currency />
                      </Typography.Mono>
                    </Table.BodyCell>
                    <Table.BodyCell align="right">
                      <Typography.Mono size="12px" lineHeight="18px" condensed>
                        <Numeric number={row.interestRate} rate units="%" />
                      </Typography.Mono>
                    </Table.BodyCell>
                    <Table.BodyCell align="right">
                      <Typography.Mono size="12px" lineHeight="18px" condensed>
                        <Numeric number={row.percentDown} rate units="%" />
                      </Typography.Mono>
                    </Table.BodyCell>
                    <Table.BodyCell align="right">
                      <Typography.Mono size="12px" lineHeight="18px" condensed>
                        <Numeric number={row.closingCosts.total} currency />
                      </Typography.Mono>
                    </Table.BodyCell>
                    <Table.BodyCell align="right">
                      <Typography.Mono size="12px" lineHeight="18px" condensed>
                        <Numeric number={row.seedFunding.total} currency />
                      </Typography.Mono>
                    </Table.BodyCell>
                    <Table.BodyCell align="right">
                      <Typography.Mono size="12px" lineHeight="18px" condensed>
                        <Numeric number={row.rent.total} currency />
                      </Typography.Mono>
                    </Table.BodyCell>
                    <Table.BodyCell align="right">
                      <Typography.Mono size="12px" lineHeight="18px" condensed>
                        <Numeric number={row.duration} precision={0} /> years
                      </Typography.Mono>
                    </Table.BodyCell>
                    <Table.BodyCell align="right">
                      <Typography.Mono size="12px" lineHeight="18px" condensed>
                        <Numeric number={row.capitalGrowthRate} rate units="%" />
                      </Typography.Mono>
                    </Table.BodyCell>
                    <Table.BodyCell align="right">
                      <Flex gap="8px" alignItems="center" justifyContent="flex-end">
                        <Link to={`/projection/${row.id}`}>
                          <Button
                            icon={<ViewIcon size="16px" />}
                            iconSize="sm"
                            variation="minimal"
                          />
                        </Link>
                        <Button
                          icon={<CloneIcon size="16px" />}
                          iconSize="sm"
                          variation="minimal"
                        />
                      </Flex>
                    </Table.BodyCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </>
          )}
        </Table.Table>
      )}
    </Flex>
  );
}

export default ProjectionTable;

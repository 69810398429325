import React from 'react';
import { Block, Typography, Card, Skeleton, Spacer } from 'powder-ui';
import { Theme, useTheme } from 'styled-components';

interface Props {
  label: string;
  children: React.ReactNode;
  skeleton?: boolean;
}

function Chip({ children, label, skeleton }: Props): JSX.Element {
  const theme: Theme = useTheme();
  if (skeleton) {
    return (
      <Block grow={0} shrink={0} width="148px" overflow="hidden">
        <Spacer spacing={2} />
        <Skeleton type="box" height={14} width={label.length * 6} />
        <Spacer spacing={2} />
        <Skeleton type="box" height={32} fluid />
      </Block>
    );
  }
  return (
    <Block grow={0} shrink={0} width="148px" overflow="hidden">
      <Typography.Body condensed size="12px" ellipsis>
        {label}
      </Typography.Body>
      <Card background={{ color: theme.palette.neutral[4] }} p="4px 8px 4px 48px">
        <Typography.Mono condensed size="12px" align="right">
          {children}
        </Typography.Mono>
      </Card>
    </Block>
  );
}

export default Chip;

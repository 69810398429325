import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import { ClosingCostsSchema } from './types';

const init = (): ClosingCostsSchema => ({
  closingCosts: {
    items: [{ id: uuid(), name: 'Closing Cost', description: '---', amount: 0 }],
    total: 0
  }
});

const validation = Yup.object({
  closingCosts: Yup.object({
    items: Yup.array()
      .of(
        Yup.object({
          name: Yup.string().required('Name is required'),
          description: Yup.string(),
          amount: Yup.number().min(0, 'Amount cannot be less than 0')
        })
      )
      .min(0, 'At least one unit is required')
  })
});

export default { init, validation };

import React from 'react';
import { Flex, Typography, Badge, Image } from 'powder-ui';
import { Investment } from '~/src/hooks/useStacksAPI';
import { Theme, useTheme } from 'styled-components';

import { PROPERTY_ICON_MAP } from '../../PropertyIcons';

interface Props {
  investment: Investment;
}

export function Badges({ investment }: Props) {
  const theme: Theme = useTheme();
  return (
    <Flex row gap="8px" m="4px 0px">
      <Badge
        text={investment.type}
        type="primary"
        colors={{
          background: theme.palette.primary.shades[4],
          text: theme.palette.primary.shades[0],
          border: theme.palette.primary.shades[4],
          icon: theme.palette.primary.shades[0]
        }}
      />
      <Badge
        text={`${investment.units.items.length} Units`}
        type="primary"
        colors={{
          background: theme.palette.primary.shades[4],
          text: theme.palette.primary.shades[0],
          border: theme.palette.primary.shades[4],
          icon: theme.palette.primary.shades[0]
        }}
      />
    </Flex>
  );
}

export function Stats({ investment }: Props) {
  const theme: Theme = useTheme();
  return (
    <Flex row gap="4px" wrap="wrap">
      <Typography.Mono condensed size="12px">
        {investment.neighborhood}
      </Typography.Mono>
      <Typography.Mono condensed size="12px" color={theme.palette.neutral[4]}>
        |
      </Typography.Mono>
      <Typography.Mono condensed size="12px">
        {investment.sqft} sqft
      </Typography.Mono>
      <Typography.Mono condensed size="12px" color={theme.palette.neutral[4]}>
        |
      </Typography.Mono>
      <Typography.Mono condensed size="12px">
        {investment.year}
      </Typography.Mono>
      <Typography.Mono condensed size="12px" color={theme.palette.neutral[4]}>
        |
      </Typography.Mono>
      <Typography.Mono condensed size="12px">
        {investment.lotSize} Acre
      </Typography.Mono>
    </Flex>
  );
}

export function PropertyIcon({
  investment,
  height = 160
}: Props & { height?: number }): JSX.Element {
  const type = investment.icon || 'house-1';
  const icon = PROPERTY_ICON_MAP[type];
  return <Image src={{ main: icon }} fit="contain" height={height} />;
}

export function Address({
  investment,
  showAddress = true,
  showCity = true,
  size = '16px',
  lineHeight = '16px'
}: Props & {
  showAddress?: boolean;
  showCity?: boolean;
  size?: string;
  lineHeight?: string;
}): JSX.Element {
  return (
    <Flex column gap="8px" grow={1} width="100%">
      {showAddress && (
        <Typography.H6 size={size} lineHeight={lineHeight} condensed>
          {investment.address}
        </Typography.H6>
      )}
      {showCity && (
        <Typography.H6 size={size} lineHeight={lineHeight} condensed>
          {investment.city}, {investment.state} {investment.zip}
        </Typography.H6>
      )}
    </Flex>
  );
}

/* eslint-disable react/no-array-index-key */
import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { Typography, Block } from 'powder-ui';
import styled from 'styled-components';

interface Props {
  score?: number;
}

const Score = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 14px;
  top: 25%;
`;

export function WalkscoreChart({ score = 0 }: Props) {
  const data = [
    { name: 'Score', value: score },
    { name: 'Diff', value: 100 - score }
  ];

  const getColor = (entry: { name: string; value: number }) => {
    if (entry.name === 'Diff') return '#EDEDED';
    return '#8884d8';
  };

  return (
    <Block position="relative">
      <PieChart width={50} height={50}>
        <Pie data={data} dataKey="value" cx="50%" cy="50%" innerRadius={18} outerRadius={25}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={getColor(entry)} />
          ))}
        </Pie>
      </PieChart>
      <Score>
        <Typography.H4 condensed weight={700} size="12px">
          {score}
        </Typography.H4>
      </Score>
    </Block>
  );
}

import { Flex, Skeleton, Button, Scale } from 'powder-ui';
import { Header } from '~/src/components/core/Header';
import React from 'react';

interface Props {
  cards: number;
}

export function SkeletonView({ cards = 5 }: Props) {
  return (
    <Flex column gap="10px" width="100%">
      <Header text="Investments" skeleton>
        <Flex gap="10px">
          <Button skeleton width={140} height={40} />
          <Button skeleton width={100} height={40} />
        </Flex>
      </Header>
      <Flex row wrap="wrap" gap="10px" justifyContent="flex-start">
        {Array.from({ length: cards }, (v, i) => i).map((c) => (
          <Scale
            key={c}
            xs="100%"
            sm="100%"
            md="100%"
            lg="calc(50% - 10px)"
            xl="calc(33.333% - 10px)"
          >
            <Skeleton type="box" height={250} fluid />
          </Scale>
        ))}
      </Flex>
    </Flex>
  );
}

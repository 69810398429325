import * as Yup from 'yup';
import { SaleSchema } from './types';

const init = (): SaleSchema => ({
  availableAssets: 50000,
  propertyPrice: 150000,
  interestRate: 0.0515,
  percentDown: 0.25,
  duration: 30,
  millage: 8.5
});

const validation = Yup.object({
  availableAssets: Yup.number()
    .required('Available assets are required.')
    .min(0, 'Available assets must be at least 0'),
  propertyPrice: Yup.number()
    .required('Property price is required.')
    .min(1, 'Property price must be greater than 0'),
  interestRate: Yup.number()
    .required('Interest rate is required.')
    .min(0, 'Interest rate cannot be negative.')
    .max(1, 'Interest rate must be less than 100'),
  percentDown: Yup.number()
    .required('Percent down is required.')
    .min(0, 'Percent down cannot be negative.')
    .max(1.00000001, 'Percent down cannot be more than 100'),
  duration: Yup.number()
    .required('Duration is required.')
    .max(30, 'Duration must be at least 1 year.')
    .integer('Duration must be an integer.')
});

export default { init, validation };

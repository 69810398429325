import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import { RentSchema } from './types';

const init = (): RentSchema => ({
  rent: {
    items: [
      {
        id: uuid(),
        unit: 'Unit 1',
        base: 1200,
        fees: { pet: 0, storage: 0, parking: 0, other: 0 }
      }
    ],
    total: 0
  }
});

const validation = Yup.object({
  rent: Yup.object({
    items: Yup.array()
      .of(
        Yup.object({
          unit: Yup.string().required('Unit name is required'),
          base: Yup.number()
            .min(0, 'Base rent cannot be less than 0')
            .required('Base rent is required'),
          fees: Yup.object({
            pet: Yup.number()
              .min(0, 'Pets fees cannot be less than 0')
              .required('Pent fees cannot be blank'),
            storage: Yup.number()
              .min(0, 'Storage fees cannot be less than 0')
              .required('Storage fees cannot be blank'),
            parking: Yup.number()
              .min(0, 'Parking fees cannot be less than 0')
              .required('Parking fees cannot be blank'),
            other: Yup.number()
              .min(0, 'Other fees cannot be less than 0')
              .required('Other fees cannot be blank')
          })
        })
      )
      .min(0, 'At least one unit is required')
  })
});

export default { init, validation };

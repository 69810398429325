import * as Yup from 'yup';
import { GrowthSchema } from './types';

const init = (): GrowthSchema => ({
  capitalGrowthRate: 0.035,
  inflationRate: 0.06,
  marketGrowthRate: 0.04
});

const validation = Yup.object({
  capitalGrowthRate: Yup.number()
    .required('Capital growth rate is required.')
    .min(0, 'Capital growth rate cannot be negative.')
    .max(1, 'Capital growth rate must be less than 100'),
  inflationRate: Yup.number()
    .required('Inflation rate is required.')
    .min(0, 'Inflation rate cannot be negative.')
    .max(1, 'Inflation rate must be less than 100'),
  marketGrowthRate: Yup.number()
    .required('Market growth rate is required.')
    .min(0, 'Market growth rate cannot be negative.')
    .max(1, 'Market growth rate must be less than 100')
});

export default { init, validation };

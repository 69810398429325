/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { EffectCallback } from 'react';

/**
 * Runs an abitrary effect a single time
 * with multiple dependencies
 *
 * @param effect - the function to exectute as a result of the effect
 * @param deps - the effect dependencies
 * @param condition - an additional condition required to exectute the effect
 */
export const useOnce = (effect: EffectCallback, deps: any[] = [], condition = true) => {
  const run = React.useRef<boolean>(true);

  React.useEffect(() => {
    if (run.current && condition) {
      effect();
      run.current = false;
    }
  }, [...deps]);
};

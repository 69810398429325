import React from 'react';
import { v4 as uuid } from 'uuid';
import {
  Fieldset,
  Legend,
  Typography,
  Flex,
  Block,
  Spacer,
  FormMessage,
  Sidesheet,
  SidesheetHeader,
  Divider,
  Button,
  Skeleton,
  Table
} from 'powder-ui';
import { Portal } from '~/src/components/core/Portal';
import { useFormikContext } from 'formik';
import type { LineItems, Rent, Unit } from '~/src/hooks/useStacksAPI';
import RentTable from '~/src/components/core/RentTable/RentTable';
import { RiCheckFill as CheckIcon } from 'react-icons/ri';
import { CurrencyField } from '~/src/components/forms/fields/CurrencyField';
import { TextField } from '~/src/components/forms/fields/TextField';
import { RentSchema } from './_schema';

interface Props {
  units?: LineItems<Unit>;
  skeleton?: boolean;
  disabled?: boolean;
}

function RentFieldset({ skeleton, units, disabled = false }: Props): JSX.Element {
  const { values, errors, setFieldValue } = useFormikContext<RentSchema>();
  const [showSidesheet, setShowSidesheet] = React.useState(false);
  const [activeRent, setActiveRent] = React.useState<number>(-1);

  const hasActiveRent = () => activeRent >= 0;

  const hasErrors = () => 'rent' in errors;

  React.useEffect(() => {
    if (units && units.items.length > 0) {
      const items = units.items.map(
        (u): Rent => ({
          id: uuid(),
          unit: u.name || '',
          base: 1200,
          fees: {
            pet: 0,
            parking: 0,
            storage: 0,
            other: 0
          }
        })
      );
      setFieldValue('rent', { items: [...items] });
    }
  }, [units, setFieldValue]);

  const onEditRent = (id: string | number) => {
    const rent = values.rent.items.findIndex((r: Rent) => r.id === id);
    if (rent >= 0) {
      setActiveRent(rent);
      setShowSidesheet(true);
    }
  };

  if (skeleton) {
    return (
      <Block>
        <Fieldset gap="0px" border>
          <Legend>
            <Skeleton type="box" height={22} width={160} />
          </Legend>
          <Table.Table
            skeleton
            rows={[]}
            columns={[]}
            rowCount={values.rent.items.length}
            colCount={3}
            spacing={4}
          >
            {() => <span />}
          </Table.Table>
          <Spacer spacing={2} />
        </Fieldset>
        <FormMessage />
        <Spacer spacing={4} />
      </Block>
    );
  }

  return (
    <>
      <Fieldset gap="0px" border>
        <Legend>
          <Typography.H5>Rent</Typography.H5>
        </Legend>
        <RentTable items={values.rent.items} onEditRent={onEditRent} />
      </Fieldset>
      <FormMessage
        touched={hasErrors()}
        error="Please review rent details"
        help="Setup rent structure across all property units"
      />
      <Spacer spacing={8} />
      <Portal>
        <Sidesheet show={showSidesheet} onClose={() => setShowSidesheet(false)}>
          <SidesheetHeader>
            <Typography.H4>Edit Rent</Typography.H4>
            <Divider />
          </SidesheetHeader>
          {hasActiveRent() && (
            <Flex column gap="4px" p="16px 0px">
              <Flex row gap="8px">
                <Block grow={1} width="180px">
                  <TextField disabled name={`rent.items[${activeRent}].unit`} label="Name" />
                </Block>
                <Block width="110px">
                  <CurrencyField
                    condensed
                    name={`rent.items[${activeRent}].base`}
                    label="Base Rent"
                    disabled={disabled}
                  />
                </Block>
              </Flex>
              <Flex row gap="8px" wrap="wrap">
                <Block grow={1} width="110px">
                  <CurrencyField
                    condensed
                    name={`rent.items[${activeRent}].fees.pet`}
                    label="Pet Fees"
                    disabled={disabled}
                  />
                </Block>
                <Block grow={1} width="110px">
                  <CurrencyField
                    condensed
                    name={`rent.items[${activeRent}].fees.storage`}
                    label="Storage Fees"
                    disabled={disabled}
                  />
                </Block>
                <Block grow={1} width="110px">
                  <CurrencyField
                    condensed
                    name={`rent.items[${activeRent}].fees.parking`}
                    label="Parking Fees"
                    disabled={disabled}
                  />
                </Block>
                <Block grow={1} width="110px">
                  <CurrencyField
                    condensed
                    name={`rent.items[${activeRent}].fees.other`}
                    label="Other Fees"
                    disabled={disabled}
                  />
                </Block>
              </Flex>
            </Flex>
          )}
          <Button
            icon={<CheckIcon size="16px" />}
            text="Done"
            disabled={hasErrors()}
            onClick={() => setShowSidesheet(false)}
          />
        </Sidesheet>
      </Portal>
    </>
  );
}

export default RentFieldset;

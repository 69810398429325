import styled from 'styled-components';

export const Head = styled.header`
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 95%;
  }
`;

export const Navigation = styled.nav``;

export const NavLink = styled.a`
  padding: 0px 10px;
  button {
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.family.mono};
    color: ${({ theme }) => theme.palette.foreground};
    border-bottom: solid 1px;
    border-radius: 0px;
    border-color: transparent;
    &:hover {
      border-bottom: solid 1px;
      border-color: ${({ theme }) => theme.palette.primary};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const FeatureContainer = styled.div`
  padding: 120px 0px 260px 0px;
  width: 100%;
  position: relative;
  text-align: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0px 0px 40px 0px;
  }
`;

export const FeatureBox = styled.div`
  display: flex;
  gap: 80px;
  justify-content: space-evenly;
  width: 80%;
  margin: 0 auto;
  text-align: left;
  flex-wrap: nowrap;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: wrap;
    gap: 20px;
  }
`;

export const TimelineContainer = styled.div`
  display: flex;
  text-align: left;
  margin: 0 auto;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const DemoContainer = styled.div`
  display: flex;
  height: 180px;
  gap: 40px;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: wrap;
    text-align: center;
    height: auto;
  }
`;

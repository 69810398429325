import React from 'react';
import { Checkbox } from 'powder-ui';
import { useField } from 'formik';

export interface Props extends React.HTMLAttributes<HTMLInputElement> {
  name?: string;
  label: string;
  value: string;
  touched?: boolean;
  error?: string;
  help?: string;
  checked?: boolean;
  disabled?: boolean;
  condensed?: boolean;
  display?: 'normal' | 'card';
  asGroup?: boolean;
}

export function CheckboxField(props: Props) {
  const {
    name = '',
    value,
    asGroup = false,
    onBlur = () => {},
    onChange = () => {},
    checked = false,
    ...baseProps
  } = props;

  const [field] = useField<any>(name);

  const isChecked = (val: string) => {
    return field.value.indexOf(val) !== -1;
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(e);
    onBlur(e);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    onChange(e);
  };

  if (asGroup) {
    return (
      <Checkbox
        name={field.name}
        value={value}
        onBlur={onInputBlur}
        onChange={onInputChange}
        checked={isChecked(value)}
        asGroup
        {...baseProps}
      />
    );
  }

  return (
    <Checkbox
      name={field.name}
      value={value}
      onBlur={onInputBlur}
      onChange={onInputChange}
      checked={checked}
      {...baseProps}
    />
  );
}

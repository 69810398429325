import { keyframes } from 'styled-components';

const slideInTop = keyframes`

  0% { 
    transform: translateY(-800px);
  }

  80%, 100% {
    transform: translateY(0);
  }

`;

export default { slideInTop };

import React from 'react';
import { Outlet } from 'react-router-dom';
import { useGlobalContext } from '~/src/contexts/global/context';
import { Block, AlertController, useAlertManager } from 'powder-ui';
import { clearAlerts } from '~/src/contexts/global/actions';
import { useStacksAPI } from '~/src/hooks/useStacksAPI';
import { Shell } from '../../core/Shell';

interface Props {
  sidebar?: boolean;
}

function MainView({ sidebar = true }: Props): JSX.Element {
  const { state, dispatch } = useGlobalContext();
  const { alerts, addAlert, removeAlert } = useAlertManager();
  const { setAuthToken } = useStacksAPI();
  const [isLoaded, setIsLoaded] = React.useState(false);

  // Set Auth from state
  React.useEffect(() => {
    if (state.authToken) {
      setAuthToken(state.authToken);
    }
    setIsLoaded(true);
  }, [state, setAuthToken]);

  React.useEffect(() => {
    const alert = state.globalAlerts[state.globalAlerts.length - 1];
    if (alert) {
      addAlert(alert);
    }
  }, [state.globalAlerts, addAlert, dispatch]);

  React.useEffect(() => {
    if (alerts) clearAlerts(dispatch, true);
  }, [alerts, dispatch]);

  if (!isLoaded) return <div />;

  return (
    <Block>
      <AlertController alerts={alerts} removeAlert={removeAlert} yOffset="50px" />
      <Shell sidebar={sidebar}>
        <Outlet />
      </Shell>
    </Block>
  );
}

export default MainView;

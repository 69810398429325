import React from 'react';
import { Card, Flex, Badge, Block, Typography, Spacer, Skeleton } from 'powder-ui';
import { Theme, useTheme } from 'styled-components';

interface Props {
  label?: string;
  skeleton?: boolean;
  size?: 'large' | 'small';
  icon?: React.ReactNode;
  type?: 'accent' | 'primary';
  children: React.ReactNode;
}

function StatBlock({ skeleton, children, icon, label, type, size = 'large' }: Props): JSX.Element {
  const theme: Theme = useTheme();
  // Skeleton
  if (skeleton) {
    if (size === 'small')
      return (
        <Block height="60px" width="136px" shrink={0} grow={1}>
          <Skeleton type="box" height={60} fluid />
        </Block>
      );
    return (
      <Block grow={1} width="136px" height="136px">
        <Skeleton type="box" height={136} fluid />
      </Block>
    );
  }
  // Small
  if (size === 'small') {
    return (
      <Card
        width="136px"
        height="60px"
        p="4px 8px"
        shrink={0}
        grow={1}
        background={{ color: theme.palette.background }}
      >
        {children}
      </Card>
    );
  }

  if (!type) return <span />;

  // Default / Large
  return (
    <Card
      shrink={0}
      grow={1}
      height="136px"
      width="136px"
      background={{ color: theme.palette[type].shades[2] }}
    >
      <Flex column gap="12px">
        <Badge type={type} icon={icon} />
        <Spacer spacing={1} />
        <Block>
          <Typography.H5
            size="28px"
            weight={400}
            color={theme.palette.accent.contrastText}
            condensed
          >
            {children}
          </Typography.H5>
        </Block>
        <Block width="80px">
          <Typography.Body
            color={theme.palette[type].shades[4]}
            condensed
            size="12px"
            lineHeight="16px"
          >
            {label}
          </Typography.Body>
        </Block>
      </Flex>
    </Card>
  );
}

export default StatBlock;

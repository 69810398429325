import React from 'react';
import { Skeleton, Block, Flex, Scroll, Divider, Card, Spacer, Typography } from 'powder-ui';
import { Projection } from '~/src/hooks/useStacksAPI';
import { Statistic } from '~/src/components/core/Statistic';
import { Theme, useTheme } from 'styled-components';
import GrowthFieldset from '~/src/components/forms/fieldsets/ProjectionFieldsets/GrowthFieldset/GrowthFieldset';
import { YieldFuturesChart } from '~/src/components/core/Charts/YieldFuturesChart';
import { TotalGrowthChart } from '~/src/components/core/Charts/TotalGrowthChart';

export interface Props {
  projection: Projection;
  isLoading?: boolean;
  isInView?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function GrowthAnalysis({ projection, isLoading, isInView }: Props): JSX.Element {
  const theme: Theme = useTheme();

  const finalValue = React.useMemo(() => {
    if (projection.analysis?.growth?.length && projection.analysis?.growth?.length >= 0) {
      return projection.analysis?.growth[projection.analysis.growth.length - 1];
    }
    return null;
  }, [projection]);

  if (!isInView) {
    return <Block height="800px" />;
  }

  if (!finalValue) return <Skeleton type="box" height={300} fluid />;

  return (
    <Flex column>
      <Scroll height="80px" width="100%" direction="x">
        <Flex gap="16px" alignItems="center" wrap="nowrap" justifyContent="flex-start">
          <Statistic
            label="Duration"
            stat={projection.duration}
            skeleton={isLoading}
            precision={0}
            units="Years"
          />
          <Block grow={0}>
            <Divider type="vertical" height="50px" color={theme.palette.neutral[4]} />
          </Block>
          <Statistic
            label="Property Value"
            currency
            stat={finalValue.total.propertyValue}
            skeleton={isLoading}
            magnitude="M"
          />
          <Block grow={0}>
            <Divider type="vertical" height="50px" color={theme.palette.neutral[4]} />
          </Block>
          <Statistic
            label="Total Return"
            currency
            stat={finalValue.total.totalReturn}
            skeleton={isLoading}
            magnitude="M"
          />
          <Block grow={0}>
            <Divider type="vertical" height="50px" color={theme.palette.neutral[4]} />
          </Block>
          <Statistic
            label="Liquid Assets"
            currency
            stat={finalValue.total.liquidAssets}
            skeleton={isLoading}
            magnitude="M"
          />
        </Flex>
        <Flex />
      </Scroll>
      <GrowthFieldset disabled={isLoading} />
      <Flex gap="8px" wrap="wrap">
        <Card grow={1} height="200px" width="200px">
          <YieldFuturesChart projection={projection} metric="roi" name="ROI" skeleton={isLoading} />
        </Card>
        <Card grow={1} height="200px" width="200px">
          <YieldFuturesChart
            projection={projection}
            metric="capRate"
            name="CapRate"
            skeleton={isLoading}
          />
        </Card>
        <Card grow={1} height="200px" width="200px">
          <YieldFuturesChart
            projection={projection}
            metric="grossYield"
            name="Gross Yield"
            skeleton={isLoading}
          />
        </Card>
      </Flex>
      <Spacer spacing={4} />
      <Typography.H5>Total Growth</Typography.H5>
      <TotalGrowthChart projection={projection} skeleton={isLoading} />
      <Spacer spacing={8} />
    </Flex>
  );
}

import React from 'react';
import { Table, Flex, Block, Spacer, Button } from 'powder-ui';
import type { TableDataColumn } from 'powder-ui';
import { Unit } from '~/src/hooks/useStacksAPI';
import {
  RiAddFill as AddIcon,
  RiPencilLine as EditIcon,
  RiCloseLine as FalseIcon,
  RiCheckLine as TrueIcon
} from 'react-icons/ri';

import { columns as tableColumns } from './bin/config';

export interface Props {
  items: Unit[];
  onAddUnit: () => void;
  onEditUnit: (id: string | number) => void;
}

function UnitsTable({ items, onAddUnit, onEditUnit }: Props): JSX.Element {
  return (
    <Flex column gap="4px">
      {items.length > 0 && (
        <Table.Table rows={items} columns={tableColumns} spacing={4}>
          {({ rows, columns, sortColumn, sortDirection, onSortColumn }) => (
            <>
              <Table.Header>
                <Table.Row>
                  {columns.map((column: TableDataColumn) => (
                    <Table.HeaderCell
                      key={column.key}
                      column={column}
                      align={column.align}
                      sortColumn={sortColumn !== null ? sortColumn : ''}
                      sortDirection={sortDirection}
                      onClick={() => onSortColumn(column.key)}
                    />
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {rows.map((row) => (
                  <Table.Row key={row.id}>
                    <Table.BodyCell>{row.name}</Table.BodyCell>
                    <Table.BodyCell align="right">{row.bedrooms}</Table.BodyCell>
                    <Table.BodyCell align="right">{row.bathroom}</Table.BodyCell>
                    <Table.BodyCell align="right">{row.sqft}</Table.BodyCell>
                    <Table.BodyCell align="center">
                      {row.pets ? <TrueIcon /> : <FalseIcon />}
                    </Table.BodyCell>
                    <Table.BodyCell align="right">
                      <Flex width="100%" justifyContent="flex-end">
                        <Button
                          icon={<EditIcon size="16px" />}
                          variation="minimal"
                          onClick={() => onEditUnit(row.id)}
                        />
                      </Flex>
                    </Table.BodyCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </>
          )}
        </Table.Table>
      )}
      <Spacer spacing={2} />
      <Block>
        <Button
          type="button"
          variation="secondary"
          text="Add Units"
          icon={<AddIcon size="16px" />}
          onClick={() => onAddUnit()}
        />
      </Block>
    </Flex>
  );
}

export default UnitsTable;

import React from 'react';
import { Button, Block, Flex } from 'powder-ui';
import type { CSSTransitionAnimation } from 'powder-ui';
import { RiMenuLine as MenuIcon } from 'react-icons/ri';
import { useGlobalContext } from '~/src/contexts/global/context';
import { useTheme } from 'styled-components';
import { Container } from './bin';

import WhiteLogo from '../../../assets/logo-white.svg';
import DefaultLogo from '../../../assets/logo-default.svg';

export interface Props {
  showMenuButton?: boolean;
  transition?: CSSTransitionAnimation;
  onClick: () => void;
}

function Navbar({
  onClick,
  showMenuButton = true,
  transition = { duration: 500, delay: 0 }
}: Props): JSX.Element {
  const { state } = useGlobalContext();
  const theme = useTheme();
  const Logo = state.theme === 'dark' ? WhiteLogo : DefaultLogo;
  return (
    <Container transition={transition}>
      <Flex p="0px 20px">
        <img src={Logo} alt="Padstack" />
      </Flex>
      <Flex grow={1} />
      {showMenuButton && (
        <Flex id="menu" column width="60px" alignItems="center" justifyContent="center">
          <Block>
            <Button
              onClick={onClick}
              variation="minimal"
              iconSize="md"
              color={theme.palette.foreground}
              icon={<MenuIcon size="24px" />}
            />
          </Block>
        </Flex>
      )}
    </Container>
  );
}

// Export the component as the default export
export default Navbar;

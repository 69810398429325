/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */

export type Nullable<T> = T | null;

export type HTTPMethods = 'post' | 'get' | 'put' | 'delete' | 'patch';

export type Dictionary = { [key: string]: string };

export type ClassDefinition<C> = new (...args: any[]) => C;

export type AnonymousFunc = (...args: any[]) => any;

export type InstanceProperties<T> = {
  [K in keyof T]: T[K] extends Function ? never : T[K];
};

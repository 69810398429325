import { TableDataColumn } from 'powder-ui';

export const columns: TableDataColumn[] = [
  {
    key: 'title',
    header: 'Title',
    sortable: false,
    width: 10,
    align: 'left'
  },
  {
    key: 'propertyPrice',
    header: 'Purchase Price',
    sortable: true,
    width: 10,
    align: 'right'
  },
  {
    key: 'availableAssets',
    header: 'Assets',
    width: 10,
    sortable: true,
    align: 'right'
  },
  {
    key: 'interestRate',
    header: 'Interest',
    sortable: true,
    width: 8,
    align: 'right'
  },
  {
    key: 'percentDown',
    header: '% Down',
    sortable: true,
    width: 8,
    align: 'right'
  },
  {
    key: 'closingCosts',
    header: 'Closing Costs',
    sortable: true,
    width: 10,
    align: 'right'
  },
  {
    key: 'seedFunding',
    header: 'Seed Funding',
    sortable: true,
    width: 10,
    align: 'right'
  },
  {
    key: 'rent',
    header: 'Rent',
    sortable: true,
    width: 8,
    align: 'right'
  },
  {
    key: 'duration',
    header: 'Duration',
    sortable: true,
    width: 8,
    align: 'right'
  },
  {
    key: 'capitalGrowthRate',
    header: 'Growth',
    sortable: true,
    width: 8,
    align: 'right'
  },
  {
    key: 'actions',
    header: '',
    sortable: false,
    width: 8,
    align: 'right'
  }
];

import React from 'react';
import { v4 as uuid } from 'uuid';
import { Form, Formik } from 'formik';
import { Flex, Block, Button, Scale, Spacer } from 'powder-ui';
import { Header } from '~/src/components/core/Header';
import {
  ProjectionSchema,
  schema
} from '~/src/components/forms/fieldsets/ProjectionFieldsets/_schema';
import { useOnce } from '~/src/hooks/useOnce';
import { useLoadingTransition } from '~/src/hooks/useLoadingTransition/useLoadingTransition';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Investment, Projection, isSuccessPayload, useStacksAPI } from '~/src/hooks/useStacksAPI';
import { Nullable } from '~/src/types';
import { RiRefreshLine, RiSave2Fill } from 'react-icons/ri';
import { ErrorAlert, SuccessAlert } from '~/src/components/core/Alerts';
import { addAlert } from '~/src/contexts/global/actions';

import { useGlobalContext } from '~/src/contexts/global/context';
import { sleep } from '~/src/util/sleep';
import { Tabs } from '~/src/components/core/Tabs';
import { Overview, TabManager, ViewContainer, ProjectionListener } from './bin';

// Analyses
import { FinancingAnalysis } from './analyses/FinancingAnalysis';
import { CashflowAnalysis } from './analyses/CashflowAnalysis';
import { GrowthAnalysis } from './analyses/GrowthAnalysis';
import { AmortizationAnalysis } from './analyses/AmortizationAnalysis';

function ProjectionView(): JSX.Element {
  /** --------------- */
  const { dispatch } = useGlobalContext();
  const [urlParams] = useSearchParams();
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRunning, setIsRunning] = React.useState(true);
  const [projection, setProjection] = React.useState<Nullable<Projection>>(null);
  const [investment, setInvestment] = React.useState<Nullable<Investment>>(null);
  const [activeTab, setActiveTab] = React.useState(urlParams.get('view') || 'sale-finance');

  const { getProjection, runProjection, getInvestment, updateProjection } = useStacksAPI();
  const { isViewLoading } = useLoadingTransition(isLoading || isRunning, 800);

  const run = React.useCallback(
    async (p: ProjectionSchema) => {
      // eslint-disable-next-line no-console
      console.log('RUNNING PROJECTION', p);
      setIsRunning(true);
      const { payload } = await runProjection({ data: p });
      if (isSuccessPayload(payload)) {
        const { data } = payload;
        setProjection(data);
        setIsRunning(false);
      } else {
        const alert = ErrorAlert(uuid(), {
          title: 'Unexpected Error',
          message: payload.message
        });
        addAlert(dispatch, { alert });
      }
    },
    [setIsRunning, runProjection, dispatch]
  );

  const loadProjection = React.useCallback(async () => {
    const { payload } = await getProjection({ params: { id } });
    if (isSuccessPayload(payload)) {
      const { data } = payload;
      setProjection(data);
      run(data);
      return data;
    }
    navigate('/error', { state: payload });
    return false;
  }, [id, getProjection, navigate, run]);

  const loadInvestment = React.useCallback(
    async (investmentId: string) => {
      const { payload } = await getInvestment({ params: { id: investmentId } });
      if (isSuccessPayload(payload)) {
        const { data } = payload;
        setInvestment(data);
        return data;
      }
      navigate('/error', { state: payload });
      return false;
    },
    [getInvestment, navigate]
  );

  const loadView = React.useCallback(async () => {
    setIsLoading(true);
    const p = await loadProjection();
    if (p && typeof p.investment === 'string') {
      await loadInvestment(p.investment);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [loadProjection, loadInvestment]);

  useOnce(() => {
    loadView();
  }, [loadView]);

  const onFormSubmit = async (values: ProjectionSchema) => {
    // eslint-disable-next-line no-console
    console.log('SAVING PROJECTION', values);
    const { payload } = await updateProjection({
      data: { ...values, investment: investment?.id },
      params: { id }
    });
    if (isSuccessPayload(payload)) {
      const alert = SuccessAlert(payload.data.id, {
        title: 'Success',
        message: 'Projection saved successfully'
      });
      addAlert(dispatch, { alert });
      await sleep(1000);
    } else {
      const alert = ErrorAlert(uuid(), {
        title: 'Unexpected Error',
        message: payload.message
      });
      addAlert(dispatch, { alert });
      await sleep(1000);
    }
  };

  const onProjectionChange = async (
    values: ProjectionSchema,
    isValid: boolean,
    isDirty: boolean,
    resetForm: (s?: object) => void
  ) => {
    // Only run the projection if the form values have been modified
    if (!isDirty) return;
    // Run the form projection
    if (isValid) {
      setIsRunning(true);
      resetForm({ values });
      run(values);
    } else {
      setIsRunning(true);
    }
  };

  const onSelectTab = async (key: string) => {
    setActiveTab(key);
  };

  if (!projection || !investment) {
    return <span />;
  }

  return (
    <Formik
      validationSchema={schema.validation}
      initialValues={projection}
      enableReinitialize
      onSubmit={onFormSubmit}
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <Flex column gap="10px" width="100%">
          <Header
            text={projection.title}
            backText="View all"
            backTo={`/investment/${projection.investment}`}
          >
            <Flex gap="8px">
              <Block>
                <Button
                  skeleton={isSubmitting}
                  text="Save"
                  icon={<RiSave2Fill size="16px" />}
                  type="submit"
                  disabled={!isValid}
                  onClick={submitForm}
                />
              </Block>
              <Block>
                <Button
                  text="Reset"
                  variation="secondary"
                  icon={<RiRefreshLine size="16px" />}
                  onClick={() => loadView()}
                />
              </Block>
            </Flex>
          </Header>
          <Form>
            <ProjectionListener onFormChange={onProjectionChange} />
            <ViewContainer>
              <Overview isLoading={isViewLoading} investment={investment} projection={projection} />
              <Scale grow={1} xs="100%" sm="100%" md="100%" lg="600px" xl="600px">
                <Tabs
                  height="44px"
                  active={activeTab}
                  tabs={[
                    { key: 'sale-finance', label: 'Sale & Finance', width: '104px' },
                    { key: 'cashflow', label: 'Cashflow', width: '74px' },
                    { key: 'growth', label: 'Growth', width: '68px' },
                    { key: 'amortization', label: 'Amortization', width: '90px' }
                  ]}
                  onSelectTab={onSelectTab}
                />
                <Spacer spacing={4} />
                <Block>
                  <TabManager activeTab={activeTab} tabKey="sale-finance">
                    <FinancingAnalysis
                      projection={projection}
                      isLoading={isViewLoading}
                      isInView={activeTab === 'sale-finance'}
                    />
                  </TabManager>
                  <TabManager activeTab={activeTab} tabKey="cashflow">
                    <CashflowAnalysis
                      projection={projection}
                      isLoading={isViewLoading}
                      isInView={activeTab === 'cashflow'}
                    />
                  </TabManager>
                  <TabManager activeTab={activeTab} tabKey="growth">
                    <GrowthAnalysis
                      projection={projection}
                      isLoading={isViewLoading}
                      isInView={activeTab === 'growth'}
                    />
                  </TabManager>
                  <TabManager activeTab={activeTab} tabKey="amortization">
                    <AmortizationAnalysis
                      projection={projection}
                      isLoading={isViewLoading}
                      isInView={activeTab === 'amortization'}
                    />
                  </TabManager>
                </Block>
              </Scale>
            </ViewContainer>
          </Form>
        </Flex>
      )}
    </Formik>
  );
}

export default ProjectionView;

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Block, Skeleton, transparentize } from 'powder-ui';
import React from 'react';

import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';
import { Projection } from '~/src/hooks/useStacksAPI';
import { Theme, useTheme } from 'styled-components';
import { Numeric } from '../Numeric';

interface Props {
  projection: Projection;
  skeleton?: boolean;
}

export function ExpensePieChart({ projection, skeleton }: Props) {
  const theme: Theme = useTheme();
  const [key, setKey] = React.useState(Date.now());

  React.useEffect(() => {
    // Update the key when the data prop changes
    setKey(Date.now());
  }, [projection.analysis?.growth]);

  if (skeleton)
    return (
      <Block height="460px" width="100%">
        <Skeleton height={460} fluid type="box" />
      </Block>
    );

  const data = [
    { name: 'Vacancy', value: projection.analysis?.cashflow?.expenses.vacancy || 0 },
    { name: 'Maintenance', value: projection.analysis?.cashflow?.expenses.maintenance || 0 },
    { name: 'Management', value: projection.analysis?.cashflow?.expenses.management || 0 },
    { name: 'Taxes', value: projection.analysis?.cashflow?.expenses.taxes || 0 },
    { name: 'Utilities', value: projection.analysis?.cashflow?.expenses.utilities || 0 },
    { name: 'Fees', value: projection.analysis?.cashflow?.expenses.fees || 0 },
    { name: 'Principal', value: projection.analysis?.cashflow?.expenses.principal || 0 },
    { name: 'Interest', value: projection.analysis?.cashflow?.expenses.interest || 0 }
  ].sort((a, b) => a.value - b.value);

  const getColor = (index = 0) => {
    const opacity = 1 - (data.length - index) / 10;
    return transparentize(theme.palette.visualization[1], opacity);
  };

  const renderLabelLine = ({ cx, cy, midAngle, outerRadius, stroke, percent }: any) => {
    if (percent < 0.05) return <path />;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    return (
      <g>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={stroke} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={stroke} stroke="none" />
      </g>
    );
  };

  const renderLabel = ({ cx, cy, midAngle, outerRadius, value, fill, name, percent }: any) => {
    if (percent < 0.05) return <path />;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    let labelMargin = -5;
    let textAnchor = 'end';
    if (ex > cx) {
      labelMargin = 5;
      textAnchor = 'start';
    }
    return (
      <g>
        <text
          x={ex + labelMargin}
          y={ey}
          fill={fill || '#000'}
          textAnchor={textAnchor}
          dominantBaseline="central"
          fontFamily={theme.fonts.family.mono}
          fontSize="12px"
        >
          {name}
        </text>
        <text
          x={ex + labelMargin}
          y={ey - 4}
          dy={18}
          fill={fill || '#000'}
          textAnchor={textAnchor}
          dominantBaseline="central"
          fontFamily={theme.fonts.family.mono}
          fontSize="10px"
        >
          <Numeric currency number={value} />
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart key={key}>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={180}
          labelLine={renderLabelLine}
          label={renderLabel}
          stroke={theme.palette.background}
          strokeWidth={2}
        >
          {data.map((entry, i) => {
            return <Cell key={entry.name} fill={getColor(i)} />;
          })}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

import React from 'react';
import { Dialog, Typography, Flex, Divider } from 'powder-ui';
import { RiDeleteBin3Fill } from 'react-icons/ri';
import { Portal } from '~/src/components/core/Portal';
import { Investment } from '~/src/hooks/useStacksAPI/';
import { Nullable } from '~/src/types';

interface Props {
  showDialog: boolean;
  investment: Nullable<Investment>;
  setShowDialog: (s: boolean) => void;
  onConfirmDelete: (id: string) => void;
}

function DeleteInvestmentDialog({
  showDialog,
  investment,
  setShowDialog,
  onConfirmDelete
}: Props): JSX.Element {
  if (!investment) return <span />;
  return (
    <Portal>
      <Dialog
        footer
        intent="danger"
        show={showDialog}
        confirmIcon={<RiDeleteBin3Fill />}
        onCancel={() => setShowDialog(false)}
        onClose={() => setShowDialog(false)}
        onConfirm={() => onConfirmDelete(investment.id)}
      >
        <Flex column p="5px 20px" gap="8px">
          <Typography.H2>Cofirm Delete</Typography.H2>
          <Divider />
          <Typography.Body>
            Are you sure you want to permanently delete <strong>{investment?.address}</strong> and
            it&apos;s associated projections and analyses?
          </Typography.Body>
          <Divider />
        </Flex>
      </Dialog>
    </Portal>
  );
}

export default DeleteInvestmentDialog;
